import React, { Component } from 'react';
import './Footer.scss';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <section>
                    <div className="footer-section">
                        <h1>Get Updates!</h1>
                        <p>Find out about product updates and financial ideas to help you succeed.</p>
                        <form>
                            <input type="email" placeholder="Enter email" />
                            <input type="submit" value="Submit" />
                        </form>
                    </div>
                    <div className="footer-section">
                        <h2>Information</h2>
                        <ul>
                            <li><a href="https://www.moneyswell.com/about-us/" target="_blank" rel="noreferrer">About</a></li>
                            <li><a href="https://www.moneyswell.com/#faq" target="_blank" rel="noreferrer">FAQ</a></li>
                            <li><a href="https://www.moneyswell.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                            <li><a href="https://www.moneyswell.com/terms-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h2>Contact</h2>
                        <ul>
                            <li><a href="mailto:contact@moneyswell.com">contact@moneyswell.com</a></li>
                            <li><a href="https://www.moneyswell.com/feedback/" target="_blank" rel="noreferrer">Provide Feedback</a></li>
                        </ul>
                    </div>

                    <span className="copyright">&copy; MoneySwell LLC. {new Date().getFullYear()}</span>
                </section>
            </footer>
        );
    }
}

export default Footer;
