import MyInfo from 'images/retirement-planner/my-info.png';
import GoalsOutlook from 'images/retirement-planner/goals-progress.png';
import Summary from 'images/retirement-planner/summary.png';

export const STEPS = [
    {
        id: 'myInfo',
        name: 'My Info',
        shortDesc: 'Fill out basic information',
        description: 'Fill out some basic personal information.',
        image: MyInfo,
        imageAlt: 'My Info illustration, woman at laptop, UI elements floating',
    },
    {
        id: 'goalsOutlook',
        name: 'Goals & Outlook',
        shortDesc: 'Set goals and track progress',
        description: 'Set goals and track your progress against them.',
        image: GoalsOutlook,
        imageAlt: 'Goals & Outlook illustration, person with arms raised, big money bag, UI elements floating',
    },
    {
        id: 'summary',
        name: 'Summary',
        shortDesc: 'See your summary',
        description: 'Understand your Nest Egg needs and see your historical progress.',
        image: Summary,
        imageAlt: 'Summary illustration, woman sitting on big green piggy bank, big coins stacked',
    }
];

export const FIELD_MAP = {
    first_name: 'firstName',
    gross_annual_household_income: 'grossAnnualIncome',
    birthday: 'birthday',
    annual_household_expenses: 'annualExpenses',
    goal_retirement_age: 'goalRetirementAge',
    partner_first_name: 'partnerFirstName',
    partner_birthday: 'partnerBirthday',
    partner_goal_retirement_age: 'partnerGoalRetirementAge',
    year: 'year',
    minimum: 'minimum',
    target: 'target',
    stretch: 'stretch',
    ytd_income: 'ytdIncome',
    ytd_retirement: 'ytdRetirement',
    ytd_value: 'ytdValue',
    expected_rate_of_return: 'expectedROR',
    start_value: 'startValue',
    minimum_income_percentage: 'minimum',
    target_income_percentage: 'target',
    optimal_income_percentage: 'optimal',
};

export const MY_DETAILS_FIELDS = {
    firstName: '',
    grossAnnualIncome: '',
    birthday: '',
    annualExpenses: '',
    goalRetirementAge: '',
};

export const GOALS_OUTLOOK_FIELDS = {
    year: '',
    minimum: '',
    target: '',
    stretch: '',
    ytdIncome: '',
    ytdRetirement: '',
    ytdValue: '',
    expectedROR: 7,
    startValue: '',
};

export const DASHBOARD_RP_FIELDS = [
    'first_name',
    'gross_annual_household_income',
    'birthday',
    'annual_household_expenses',
    'goal_retirement_age',
    'partner_first_name',
    'partner_birthday',
    'partner_goal_retirement_age',
    'minimum',
    'target',
    'stretch',
    'ytd_income',
    'ytd_retirement',
];

export const INCOME_PERCENT_FIELDS = {
    minimum: 70,
    target: 85,
    optimal: 100,
};

export const EXCLUDE_FIELDS = ['year', 'expectedROR', 'ytdValue', 'startValue'];

export const PARTNER_FIELDS = {
    partnerFirstName: '',
    partnerBirthday: '',
    partnerGoalRetirementAge: '',
};

export const EMPTY_ROWS = {
    assets: { id: 0, account: '', amount: '' },
    income: { id: 0, source: '', amount: '' },
};

export const GOAL_TYPES = [
    { id: 'minimum', label: 'Minimum', legendLabel: 'Minimum Goal', dashboardLabel: 'Minimum', legendOnly: false, fill: '#F18D30' },
    { id: 'target', label: 'Target', legendLabel: 'Target Goal', dashboardLabel: 'Target', legendOnly: false, fill: '#B851C1' },
    { id: 'stretch', label: 'Stretch', legendLabel: 'Stretch Goal', dashboardLabel: 'Stretch', legendOnly: false, fill: '#6FB7FF' },
    { id: 'actual', label: 'Actual / Current', legendLabel: 'Actual / Current', dashboardLabel: 'Actual', legendOnly: true, fill: '#66D8DF' }
];

export const END_AGE = 85;

export const MAX_ITERATIONS = 100;

export const TOOLTIP_TEXT = {
    step1: {
        myDetails: {
            income: 'This is "Gross Income" - before taxes and other deductions are taken out - for you and your partner (if applicable).',
            expenses: 'To estimate, review your statements or look for year-end summaries from your bank and credit card companies.',
        },
        assetsIncome: {
            retirement: 'These are accounts where your savings are invested. Include any accounts intended for your long-term saving.',
            noAccounts: "If you don't have a retirement account yet, we suggest you add a placeholder account with a $0 value for the moment.",
            income: 'Examples include Social Security (get your estimate at www.ssa.gov), rental income, a retirement side job, etc.',
        },
    },
    step2: {
        goalsProgress: {
            goals: 'Financial experts often suggest saving 10-15% of your gross income. But if possible, you may want to save more.',
            year: "When you start, this is the current year. As years pass, you'll be able to view your previous years' accomplishments.",
            income: 'Update monthly or end of year. Track gross income - i.e. before taxes are taken out - from paystubs.',
            retirement: "Track from your retirement company's website. If you made withdrawals, subtract that from your contirbutions.",
        },
        achievementsGoals: {
            goals: 'As you update your numbers above, the graphs in this section will help you track your progress.',
        },
        futureOutlook: {
            nestEgg: 'This table will help you better understand if your savings goals are keeping you on track for your retirement goal.',
            startingValue: 'This is the value of your retirement accounts at the beginning of the current year, or the earliest data available from this year.',
            expectedROR: "Adjusted for inflation, common estimates range from 5 - 8% depending on how agressively your portfolio is invested.",
            needs: "Scroll to see when you might hit these numbers based on this year's three different savings goals. Learn more about your estimate in Step 3."
        },
    },
    step3: {
        nestEgg: {
            howBig: 'As your income and expenses change over time, your nest egg needs may fluctuate.',
            experimentation: 'This box starts with the values you entered in Step 1. But you can experiment with alternate values and then reset.',
            income: 'This is your Gross annual income, i.e. before taxes and other deductions are taken out.',
            expenses: 'A reduction in your expenses reduces the size your nest egg needs to be. Estimate expenses from bank statements.',
            monthly: 'Consider the sources of reliable income you may have outside of your nest egg down. Enter the monthly value.',
            reset: 'Reset the values in the fields above to numbers entered in Step 1. To set new default values, you can edit in Step 1.',
            percentage: 'Adjustments to these percentages affect your nest egg needs estimates anywhere they show up in Retirement Planner.',
        },
        historical: {
            values: 'The left axis shows your retirement account values at the end of that year. The right axis shows your contributions.',
            income: 'This is the gross amount of income you indicated you earned for a given year.',
            retirement: 'The amount (pre & post tax) you contributed in a given year. Net withdrawals are expressed as a negative number.',
            savings: 'This is the percentage of your gross income that you saved and invested for retirement.',
            growth: 'This is a rough estimate of investment growth by year. More precise esimates require more details than MoneySwell collects.',
            endValue: "This is the value of your retirement accounts on 12/31 of that year. For the current year, it's the latest number entered.",
        },
    },
};

export const DUMMY_HISTORICAL = [
    { id: 1, year: 2018, ytdIncome: "68000.00", ytdRetirement: "5000.00", savingsRate: 7.3, growthRate: 0, endValue: "15238.00", historical: true },
    { id: 2, year: 2019, ytdIncome: "72000.00", ytdRetirement: "5500.00", savingsRate: 7.6, growthRate: 0.296, endValue: "25243.00", historical: true },
    { id: 3, year: 2020, ytdIncome: "50238.00", ytdRetirement: "238.00", savingsRate: 0.5, growthRate: -0.208, endValue: "20238.00", historical: true },
    { id: 4, year: 2021, ytdIncome: "85238.00", ytdRetirement: "9238.00", savingsRate: 10.8, growthRate: 0.285, endValue: "35243.00", historical: true },
    { id: 5, year: 2022, ytdIncome: "92238.00", ytdRetirement: "12238.00", savingsRate: 12.3, growthRate: 0.223, endValue: "55238.00", historical: true },
    { id: 6, year: 2023, ytdIncome: "85238.00", ytdRetirement: "9238.00", savingsRate: 10.8, growthRate: 0.285, endValue: "35243.00", historical: true },
];
