export const TIER_COLORS = {
    foundations: '#F58B55',
    security: '#FBE281',
    growth: '#A1EDD7',
    independence: '#338897',
    abundance: '#0DB3C7'
};

export const DEFAULT_FINANCIAL_FACT = 'Money is any item or verifiable record that is generally accepted as payment for goods and services and repayment of debts, such as taxes, in a particular country or socio-economic context.';

export const STROKES = { white: '#FFFFFF', grey: '#CFCFCF', green: '#40B7B0', orange: '#FF9E2D', red: '#EB535C' };
