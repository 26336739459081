import {
    DOWNLOAD_TRANSACTIONS_ERROR,
    DOWNLOAD_TRANSACTIONS_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_LINK_TOKEN_ERROR,
    GET_LINK_TOKEN_SUCCESS,
    SYNC_ACCOUNTS_ERROR,
    SYNC_ACCOUNTS_SUCCESS,
} from '../constants/actionTypes';
import plaidService from '../services/plaidService';

export const getLinkToken = (user, accessToken) => dispatch => (
    plaidService.getLinkToken(user, accessToken)
        .then(res => {
            dispatch({
                type: GET_LINK_TOKEN_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: GET_LINK_TOKEN_ERROR,
                payload: e,
            });
        })
);

export const getAccessTokenAndCreateAccounts = (user, metadata) => dispatch => (
    plaidService.getAccessTokenAndCreateAccounts(user, metadata)
        .then(res => {
            dispatch({
                type: GET_ACCESS_TOKEN_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: GET_ACCESS_TOKEN_ERROR,
                payload: e,
            });
        })
);

export const syncLinkedAccounts = (user) => dispatch => (
    plaidService.syncLinkedAccounts(user)
        .then(res => {
            dispatch({
                type: SYNC_ACCOUNTS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: SYNC_ACCOUNTS_ERROR,
                payload: e,
            });
        })
);

export const downloadTransactions = (user, plan, accounts) => dispatch => (
    plaidService.downloadTransactions(user, plan, accounts)
        .then(res => {
            dispatch({
                type: DOWNLOAD_TRANSACTIONS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DOWNLOAD_TRANSACTIONS_ERROR,
                payload: e,
            });
        })
);
