
export default class Http {
    static defaultOptions = {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
    };

    static doFetch = async (url, options = {}) => {
        try {
            const res = await fetch(url, options);
            let body;
            try {
                body = await res.json();
            } catch (e) {
                body = {};
            }
            if (res.status >= 200 && res.status < 300) {
                return Promise.resolve({
                    status: res.status,
                    ok: res.ok,
                    body,
                });
            } else {
                return Promise.reject({
                    error: true,
                    status: res.status,
                    body,
                });
            }
        } catch (e) {
            return Promise.reject(e);
        }
    };

    static get = async (url, options = {}) => {
        const headers = { ...this.defaultOptions.headers, ...(options.headers || {}) };
        options = { ...this.defaultOptions, ...options, headers };
        return this.doFetch(url, options);
    };

    static post = async (url, params = {}, options = {}) => {
        const headers = { ...this.defaultOptions.headers, ...(options.headers || {}) };
        options = {
            method: 'POST',
            ...this.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        };
        return this.doFetch(url, options);
    };

    static patch = async (url, params = {}, options = {}) => {
        const headers = { ...this.defaultOptions.headers, ...(options.headers || {}) };
        options = {
            method: 'PATCH',
            ...this.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        };
        return this.doFetch(url, options);
    };

    static delete = async (url, params = {}, options = {}) => {
        const headers = { ...this.defaultOptions.headers, ...(options.headers || {}) };
        options = {
            method: 'DELETE',
            ...this.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        };
        return this.doFetch(url, options);
    };

    static put = async (url, params = {}, options = {}) => {
        const headers = { ...this.defaultOptions.headers, ...(options.headers || {}) };
        options = {
            method: 'put',
            ...this.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        };
        return this.doFetch(url, options);
    };
}
