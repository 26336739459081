import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPasswordStrength from 'react-password-strength';
import { forgotPasswordLookup, verifyResetCode, editPassword } from 'actions/accountActions';
import NavBar from 'components/NavBar';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { MIN_PASSWORD_LENGTH } from 'constants/config';
import './ForgotPassword.scss';

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    forgotPasswordLookup: (email) => {
        dispatch(forgotPasswordLookup(email));
    },
    verifyResetCode: (resetCode) => {
        dispatch(verifyResetCode(resetCode));
    },
    editPassword: (details) => {
        dispatch(editPassword(details));
    },
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: 'email',
            email: '',
            resetCode: '',
            user: null,
            password: {password: ''},
            passwordResult: null,
            confirmPassword: '',
            forgotPassword: {},
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = { forgotPassword: nextProps.account.forgotPassword };

        if (state.forgotPassword.success.code === 'lookup_success') {
            state.form = 'resetCode';
        }

        if (state.forgotPassword.success.user) {
            state.user = state.forgotPassword.success.user;
            state.form = 'resetPassword';
        }

        this.setState(state);
    }

    updateState = (e, key) => {
        let state = {};
        state[key] = e.target.value;
        this.setState(state);
    }

    changePassword = (state, result) => {
        this.setState({password: state, passwordResult: result});
    }

    submitEmail = (e) => {
        e.preventDefault();

        if (this.state.email === '') {
            alert('Email cannot be empty.');
            return false;
        }

        this.props.forgotPasswordLookup(this.state.email);

        return false;
    }

    submitResetCode = (e) => {
        e.preventDefault();

        if (this.state.resetCode === '') {
            alert('Reset Code cannot be empty.');
            return false;
        }

        this.props.verifyResetCode(this.state.resetCode);

        return false;
    }

    submitResetPassword = (e) => {
        e.preventDefault();

        const pwObj = this.state.password;
        const feedback = this.state.passwordResult?.feedback;

        if (pwObj.password === '') {
            alert('Password cannot be empty.');
            return false;
        } else if (pwObj.password.length < MIN_PASSWORD_LENGTH) {
            alert(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`);
            return false;
        } else if (!pwObj.isValid) {
            let msg = feedback.warning;
            if (feedback.suggestions && feedback.suggestions.length > 0) {
                msg += ` - ${feedback.suggestions[0]}`;
            }
            alert(msg);
            return false;
        } else if (this.state.confirmPassword === '') {
            alert('Confirm Password cannot be empty.');
            return false;
        } else if (pwObj.password !== this.state.confirmPassword) {
            alert('Passwords do not match.');
            return false;
        }

        this.props.editPassword({
            userId: this.state.user.id,
            resetCode: this.state.resetCode,
            newPassword: pwObj.password,
            confirmPassword: this.state.confirmPassword,
        });

        return false;
    }

    closeSnackbar = () => {
        this.setState({ forgotPassword: {} });
    }

    render() {
        const { form, email, resetCode, password, confirmPassword, forgotPassword } = this.state;

        // The contents of the snackbar on API calls //
        let alert = null;
        if (forgotPassword.success) {
            alert = { severity: 'success', msg: forgotPassword.success.msg };
        } else if (forgotPassword && forgotPassword.error) {
            alert = { severity: 'error', msg: forgotPassword.error.body.msg };
        }

        return (
            <div className="wrapper">
                <NavBar />

                <div className="main forgot-password">
                    {form === 'email' && <>
                        <h1>Find your account</h1>
                        <div className="form-wrapper">
                            <form name="forgot-password" onSubmit={this.submitEmail}>
                                <h2>Enter your email to look up your account. If it exists, we'll send you a reset code so you can reset your password.</h2>

                                <fieldset>
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email"
                                        id="email"
                                        autoFocus
                                        placeholder="Email Address"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => this.updateState(e, 'email')} />
                                </fieldset>

                                <input type="submit" className="primary" value="Verify" />
                            </form>
                        </div>
                    </>}

                    {form === 'resetCode' && <>
                        <h1>Check your email</h1>
                        <div className="form-wrapper">
                            <form name="reset-code" onSubmit={this.submitResetCode}>
                                <h2>If an account exists for <b>{email}</b>, you will receive a code to verify here in order to reset your password.</h2>

                                <fieldset>
                                    <label htmlFor="resetCode">Reset Code</label>
                                    <input type="text"
                                        id="resetCode"
                                        autoFocus
                                        placeholder="Reset Code"
                                        value={resetCode}
                                        onChange={(e) => this.updateState(e, 'resetCode')} />
                                </fieldset>

                                <input type="submit" className="primary" value="Verify" />
                            </form>
                        </div>
                    </>}

                    {form === 'resetPassword' && <>
                        <h1>Reset your password</h1>
                        <div className="form-wrapper">
                            <form name="reset-password" onSubmit={this.submitResetPassword}>
                                <h2>Make sure your password is at least 8 characters, and is sufficiently complex.</h2>

                                <fieldset>
                                    <label htmlFor="password">Password</label>
                                    <ReactPasswordStrength
                                        minLength={MIN_PASSWORD_LENGTH}
                                        minScore={2}
                                        scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Strongest']}
                                        tooShortWord='Too Short'
                                        changeCallback={this.changePassword}
                                        inputProps={{ id: 'password', placeholder: 'Password', autoComplete: 'off', value: password }}
                                    />
                                </fieldset>

                                <fieldset>
                                    <label htmlFor="confirm">Confirm Password</label>
                                    <input type="password"
                                        id="confirm"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => this.updateState(e, 'confirmPassword')} />
                                </fieldset>

                                <input type="submit" className="primary" value="Reset Password" />
                            </form>
                        </div>
                    </>}
                </div>

                {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                <Footer />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
