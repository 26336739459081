import moment from 'moment';

export const CATS = {
    'real_estate': 'Real Estate',
    'retirement': 'Retirement',
    'liquid': 'Liquid Assets',
    'debts_liabilities': 'Liabilities',
    'other': 'Other',
};

export const COLORS = {
    'Real Estate': '#6EB7FF',
    'Retirement': '#8878F7',
    'Liquid Assets': '#B851C1',
    'Liabilities': '#FFA34F',
    'Other': '#FADE7E',
};

export const ASSETS = [
    { value: '401a', label: '401(a) Retirement Account', parent: CATS.retirement, type: 'linked' },
    { value: '401k', label: '401(k) Retirement Account', parent: CATS.retirement, type: 'linked' },
    { value: '403B', label: '403(b) Retirement Account', parent: CATS.retirement, type: 'linked' },
    { value: '457b', label: '457(b) Retirement Account', parent: CATS.retirement, type: 'linked' },
    { value: '529', label: '529 College Savings', parent: CATS.other, type: 'linked' },
    { value: 'brokerage', label: 'Brokerage', parent: CATS.retirement, type: 'linked' },
    { value: 'cash management', label: 'Cash Management', parent: CATS.liquid, type: 'linked' },
    { value: 'cd', label: 'Certificate of Deposit', parent: CATS.other, type: 'linked' },
    { value: 'checking', label: 'Checking', parent: CATS.liquid, type: 'manual' },
    { value: 'crypto exchange', label: 'Cryptocurrency Exchange', parent: CATS.retirement, type: 'linked' },
    { value: 'ebt', label: 'EBT', parent: CATS.liquid, type: 'linked' },
    { value: 'education savings account', label: 'Education Savings Account', parent: CATS.other, type: 'linked' },
    { value: 'fixed annuity', label: 'Fixed Annuity', parent: CATS.retirement, type: 'linked' },
    { value: 'health reimbursement arrangement', label: 'Health Reimbursement Arrangement', parent: CATS.other, type: 'linked' },
    { value: 'hsa', label: 'Health Savings Account', parent: CATS.other, type: 'linked' },
    { value: 'ira', label: 'Traditional IRA', parent: CATS.retirement, type: 'linked' },
    { value: 'keogh', label: 'Keogh Retirement Plan', parent: CATS.retirement, type: 'linked' },
    { value: 'life insurance', label: 'Life Insurance', parent: CATS.retirement, type: 'linked' },
    { value: 'money market', label: 'Money Market', parent: CATS.liquid, type: 'linked' },
    { value: 'mutual fund', label: 'Mutual Fund', parent: CATS.liquid, type: 'linked' },
    { value: 'non-custodial wallet', label: 'Cryptocurrency Wallet', parent: CATS.liquid, type: 'linked' },
    { value: 'non-taxable brokerage account', label: 'Non-Taxable Brokerage Account', parent: CATS.retirement, type: 'linked' },
    { value: 'other', label: 'Other Investment', parent: CATS.other, type: 'manual' },
    { value: 'other annuity', label: 'Other Annuity', parent: CATS.other, type: 'linked' },
    { value: 'other insurance', label: 'Other Insurance', parent: CATS.other, type: 'linked' },
    { value: 'pension', label: 'Standard Pension Account', parent: CATS.retirement, type: 'linked' },
    { value: 'prepaid', label: 'Prepaid Debit Card', parent: CATS.liquid, type: 'linked' },
    { value: 'profit sharing plan', label: 'Profit Sharing Plan', parent: CATS.retirement, type: 'linked' },
    { value: 'qshr', label: 'Qualifying Share Account', parent: CATS.other, type: 'linked' },
    { value: 'real_estate', label: 'Real Estate', parent: CATS.real_estate, type: 'manual' },
    { value: 'retirement', label: 'Retirement', parent: CATS.retirement, type: 'manual' },
    { value: 'roth 401k', label: 'Roth 401(k)', parent: CATS.retirement, type: 'linked' },
    { value: 'roth', label: 'Roth IRA', parent: CATS.retirement, type: 'linked' },
    { value: 'sarsep', label: 'SARSEP Plan', parent: CATS.retirement, type: 'linked' },
    { value: 'savings', label: 'Savings', parent: CATS.liquid, type: 'manual' },
    { value: 'sep ira', label: 'Simplified Employee Pension IRA (SEP IRA)', parent: CATS.retirement, type: 'linked' },
    { value: 'simple ira', label: 'SIMPLE IRA', parent: CATS.retirement, type: 'linked' },
    { value: 'stock plan', label: 'Standard Stock Plan Account', parent: CATS.other, type: 'linked' },
    { value: 'trust', label: 'Trust', parent: CATS.other, type: 'linked' },
    { value: 'ugma', label: 'Uniform Gift to Minors', parent: CATS.other, type: 'linked' },
    { value: 'utma', label: 'Uniform Transfers to Minors', parent: CATS.other, type: 'linked' },
    { value: 'valuables', label: 'Valuables', parent: CATS.other, type: 'manual' },
    { value: 'variable annuity', label: 'Variable Annuity', parent: CATS.other, type: 'linked' },
];

export const LIABILITIES = [
    { value: 'auto', label: 'Auto Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'business', label: 'Business Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'commercial', label: 'Commercial Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'construction', label: 'Construction Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'consumer', label: 'Consumer Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'credit card', label: 'Credit Card', parent: CATS.debts_liabilities, type: 'manual' },
    { value: 'home equity', label: 'Home Equity Line of Credit', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'line of credit', label: 'Line of Credit', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'loan', label: 'General Loan', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'medical_debt', label: 'Medical Debt', parent: CATS.debts_liabilities, type: 'manual' },
    { value: 'mortgage', label: 'Mortgage', parent: CATS.debts_liabilities, type: 'manual' },
    { value: 'other_liability', label: 'Other Liability', parent: CATS.other, type: 'linked' },
    { value: 'overdraft', label: 'Overdraft', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'payday_loan', label: 'Payday Loan', parent: CATS.debts_liabilities, type: 'manual' },
    { value: 'paypal', label: 'PayPal', parent: CATS.debts_liabilities, type: 'linked' },
    { value: 'personal_loan', label: 'Personal Loan', parent: CATS.debts_liabilities, type: 'manual' },
    { value: 'student', label: 'Student Loan', parent: CATS.debts_liabilities, type: 'manual' },
];

export const BREAKDOWNS = {
    'daily': {breakdown: 'daily', label: 'Daily', menuLabel: 'Day', increment: 'days', format: 'YYYY-MM-DD', defaultStart: (date) => moment(date).subtract(30, 'days')},
    'monthly': {breakdown: 'monthly', label: 'Monthly', menuLabel: 'Month', increment: 'months', format: 'MMM YYYY', defaultStart: (date) => moment(date).subtract(23, 'months').startOf('month')},
    'yearly': {breakdown: 'yearly', label: 'Yearly', menuLabel: 'Year', increment: 'years', format: 'YYYY', defaultStart: (date) => moment(date).subtract(2, 'years').startOf('year')},
};

export const NET_WORTH = 'Net Worth';

export const DAY_FORMAT = 'YYYY-MM-DD';

export const WEEKDAYS = [
    { id: 0, single: 'S', two: 'Su', mixed: 'Su', three: 'Sun', full: 'Sunday' },
    { id: 1, single: 'M', two: 'Mo', mixed: 'M', three: 'Mon', full: 'Monday' },
    { id: 2, single: 'T', two: 'Tu', mixed: 'T', three: 'Tue', full: 'Tuesday' },
    { id: 3, single: 'W', two: 'We', mixed: 'W', three: 'Wed', full: 'Wednesday' },
    { id: 4, single: 'T', two: 'Th', mixed: 'Th', three: 'Thu', full: 'Thursday' },
    { id: 5, single: 'F', two: 'Fr', mixed: 'F', three: 'Fri', full: 'Friday' },
    { id: 6, single: 'S', two: 'Sa', mixed: 'Sa', three: 'Sat', full: 'Saturday' },
];

export const MAGIC_NUMERATOR = 88;
