import React, { Component } from 'react';
import CommentIconSVG from 'images/icons/comment.svg';
import NoCommentIcon from 'images/icons/no_comment.svg';

class CommentIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            on: this.props.on,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = {};
        state.on = nextProps.on;
        this.setState(state);
    }

    toggleOn = () => {
        this.setState({ on: true })
    }

    toggleOff = () => {
        this.setState({ on: this.props.on })
    }

    render() {
        const { on } = this.state;
        const { onClick } = this.props;
        const bg = (on) ? CommentIconSVG : NoCommentIcon;

        return (
            <div className="comment-icon" onClick={onClick} onMouseOver={this.toggleOn} onMouseOut={this.toggleOff} style={{backgroundImage: `url(${bg})`}} />
        );
    }
}

CommentIcon.defaultProps = {
    on: false,
    onClick: () => { return null; }
};

export default CommentIcon;
