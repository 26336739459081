import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactPasswordStrength from 'react-password-strength';
import { editAccount, editPassword, loadAccount, generateSecretAndQR, verifyAuthCode, disable2fa, impersonateUser } from 'actions/accountActions';
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { MIN_PASSWORD_LENGTH, TRIAL_STATUS, DATE_FORMAT } from 'constants/config';
import { STATUS_MAP, PLAN_COST_STATUSES } from 'constants/chargebee';
import LastPass from 'images/lastpass.png';
import Google from 'images/google.png';
import Authy from 'images/authy.png';
import './Account.scss';

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    generateSecretAndQR: () => {
        dispatch(generateSecretAndQR());
    },
    editAccount: account => {
        dispatch(editAccount(account));
    },
    editPassword: details => {
        dispatch(editPassword(details));
    },
    verifyAuthCode: (userId, code) => {
        dispatch(verifyAuthCode(userId, code));
    },
    disable2fa: (user) => {
        dispatch(disable2fa(user));
    },
    impersonateUser: (user, id, password) => {
        dispatch(impersonateUser(user, id, password));
    },
});

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            firstName: '',
            email: '',
            password: '',
            newPassword: {password: ''},
            newPasswordResult: null,
            confirmPassword: '',
            edit: {},
            totp_enabled: false,
            qrcode: null,
            authCode: '',
            impersonateId: '',
            impersonatePassword: '',
        };
    }

    componentDidMount() {
        this.props.loadAccount();
        this.props.generateSecretAndQR();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            userId: nextProps.account.user?.id,
            firstName: nextProps.account.user?.first_name,
            email: nextProps.account.user?.email,
            edit: nextProps.account.edit,
            password: '',
            newPassword: {password: ''},
            newPasswordResult: null,
            confirmPassword: '',
            totp_enabled: nextProps.account.user?.totp_enabled,
            qrcode: nextProps.account.qrcode,
            authCode: '',
            impersonateId: '',
            impersonatePassword: '',
        });
    }

    updateState = (e, key) => {
        let state = {};
        state[key] = e.target.value;
        this.setState(state);
    }

    changePassword = (state, result) => {
        this.setState({newPassword: state, newPasswordResult: result});
    }

    submitEdit = (e) => {
        e.preventDefault();

        if (this.state.email === '') {
            alert('Email cannot be empty.');
            return false;
        } else if (this.state.firstName === '') {
            alert('First Name cannot be empty.');
            return false;
        }

        this.props.editAccount({
            userId: this.state.userId,
            email: this.state.email,
            firstName: this.state.firstName,
        });

        return false;
    }

    submitEditPassword = (e) => {
        e.preventDefault();

        const pwObj = this.state.newPassword;
        const feedback = this.state.newPasswordResult?.feedback;

        if (this.state.password === '') {
            alert('Please enter your current password.');
            return false;
        } else if (pwObj.password === '') {
            alert('Please enter your new password.');
            return false;
        } else if (pwObj.password.length < MIN_PASSWORD_LENGTH) {
            alert(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`);
            return false;
        } else if (!pwObj.isValid) {
            let msg = feedback.warning;
            if (feedback.suggestions && feedback.suggestions.length > 0) {
                msg += ` - ${feedback.suggestions[0]}`;
            }
            alert(msg);
            return false;
        } else if (this.state.confirmPassword === '') {
            alert('Confirm Password cannot be empty.');
            return false;
        } else if (pwObj.password !== this.state.confirmPassword) {
            alert('Passwords don\'t match, please confirm your new password.');
            return false;
        }


        this.props.editPassword({
            userId: this.state.userId,
            password: this.state.password,
            newPassword: pwObj.password,
            confirmPassword: this.state.confirmPassword,
        });

        return false;
    }

    submitVerifyCode = (e) => {
        e.preventDefault();

        if (this.state.authCode === '') {
            alert('Please enter the 6-digit MoneySwell code from the authentication app in the text field');
            return false;
        }

        this.props.verifyAuthCode(
            this.state.userId,
            this.state.authCode,
        );

        return false;
    }

    disable2fa = (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to disable two-factor authentication for your account?')) {
            this.props.disable2fa(this.props.account.user);
        }

        return false;
    }

    signUpNow = (e) => {
        e.preventDefault();

        window.location.href = '/subscriptionSettings';

        return false;
    }

    submitImpersonate = (e) => {
        e.preventDefault();

        const id = this.state.impersonateId;
        const password = this.state.impersonatePassword;

        if (id === null || id === '' || id === 1) {
            alert('Please enter a user ID to impersonate');
            return;
        } else if (password === null || password === '') {
            alert('Please enter a password for impersonation');
            return;
        }

        this.props.impersonateUser(this.props.account.user, id, password);

        return false;
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    render() {
        const { firstName, email, password, newPassword, confirmPassword, edit, totp_enabled, qrcode, authCode, impersonateId, impersonatePassword } = this.state;
        const { account } = this.props;
        const trialUser = (account?.user?.currentSubscription?.status === TRIAL_STATUS && !account?.user?.card);
        const cta = (trialUser) ? 'Sign Up Now' : 'Edit Account';

        let alert = null;
        if (edit.success) {
            alert = { severity: 'success', msg: edit.success.msg };
        } else if (edit && edit.error) {
            alert = { severity: 'error', msg: edit.error.body.msg };
        }

        let content = (<Loading />);
        if (email !== '') {
            content = (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} />

                    <div className="main account">
                        <h1>My Account</h1>

                        <div className="form-wrapper">
                            <form name="email" onSubmit={this.submitEdit}>
                                <h2>Update user info</h2>

                                <fieldset>
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text"
                                        id="firstName"
                                        autoFocus
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => this.updateState(e, 'firstName')} />
                                </fieldset>

                                <fieldset>
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email"
                                        id="email"
                                        placeholder="Email Address"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => this.updateState(e, 'email')} />
                                </fieldset>

                                <input type="submit" className="primary" value="Save" />
                            </form>

                            <form name="password" onSubmit={this.submitEditPassword}>
                                <h2>Update password</h2>

                                <fieldset>
                                    <label htmlFor="password">Current Password</label>
                                    <input type="password"
                                        id="password"
                                        placeholder="Current Password"
                                        value={password}
                                        onChange={(e) => this.updateState(e, 'password')} />
                                </fieldset>

                                <fieldset>
                                    <label htmlFor="newPassword">New Password</label>
                                    <ReactPasswordStrength
                                        minLength={MIN_PASSWORD_LENGTH}
                                        minScore={2}
                                        scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Strongest']}
                                        tooShortWord='Too Short'
                                        changeCallback={this.changePassword}
                                        inputProps={{ id: 'newPassword', placeholder: 'New Password', autoComplete: 'off', value: newPassword }}
                                    />
                                </fieldset>

                                <fieldset>
                                    <label htmlFor="confirm">Confirm New Password</label>
                                    <input type="password"
                                        id="confirm"
                                        placeholder="Confirm New Password"
                                        value={confirmPassword}
                                        onChange={(e) => this.updateState(e, 'confirmPassword')} />
                                </fieldset>

                                <input type="submit" className="primary" value="Save" />
                            </form>

                            <form name="2fa" onSubmit={this.submitVerifyCode}>
                                <h2>Two-factor authentication</h2>

                                {totp_enabled && <>
                                    <p>Two-factor authentication has been enabled for this account.</p>
                                    <div className="form-element">
                                        <button className="primary" onClick={this.disable2fa}>Disable two-factor authentication</button>
                                    </div>
                                </>}

                                {!totp_enabled && <>
                                    <p>Scan this QR code into your authenticator app of choice:</p>
                                    <img src={qrcode} alt="2fa QR code" />

                                    <p>Popular two-factor authentication apps:</p>
                                    <div className="auth-apps">
                                        <a href="https://lastpass.com/auth/" target="_blank" rel="noreferrer"><img src={LastPass} alt="LastPass Authenticator" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noreferrer">
                                            <img src={Google} alt="Google Authenticator" />
                                        </a>
                                        <a href="https://authy.com/" target="_blank" rel="noreferrer"><img src={Authy} alt="Authy Authenticator" /></a>
                                    </div>

                                    <p>Enter the code from your authentication app for MoneySwell:</p>
                                    <fieldset>
                                        <label htmlFor="authCode">6-digit code</label>
                                        <input type="text"
                                            id="authCode"
                                            placeholder="123456"
                                            value={authCode}
                                            onChange={(e) => this.updateState(e, 'authCode')} />
                                    </fieldset>

                                    <input type="submit" className="primary" value="Verify Code" />
                                </>}
                            </form>

                            <form name="subscription" onSubmit={this.signUpNow}>
                                <h2>Subscription settings</h2>

                                {account?.user?.subscriptions?.map(sub => {
                                    let unitPrice = sub.subscription_items[0].unit_price / 100;
                                    if (sub?.coupon?.discount_type === 'fixed_amount') {
                                        unitPrice = unitPrice - (sub.coupon.discount_amount / 100);
                                    } else if (sub?.coupon?.discount_type === 'percentage') {
                                        unitPrice = unitPrice - (unitPrice * (sub.coupon.discount_percentage / 100));
                                    }
                                    unitPrice = Math.max(unitPrice, 0);

                                    const lastFour = account?.user?.card?.last4;

                                    let whatsNext = null;
                                    if (sub.cancelled_at) {
                                        whatsNext = (<li>This subscription will end on {moment(sub.cancelled_at * 1000).format(DATE_FORMAT)}.</li>);
                                    } else if (sub.status === TRIAL_STATUS) {
                                        const days = Math.abs(moment(sub.next_billing_at * 1000).diff(moment(), 'days')) + 1;
                                        whatsNext = (
                                            <>
                                                <li><b>{days}</b> days remain on your free trial, which ends on {moment(sub.next_billing_at * 1000).format(DATE_FORMAT)}.</li>
                                                {!account?.user?.card && <li>Without a paid plan, data will be deleted 30 days following account expiration.</li>}
                                                {!account?.user?.card && <li>When you sign up you can choose a <b>monthly</b> or <b>annual</b> subscription
                                                option with no commitment to renew.</li>}
                                                {account?.user?.card && <li>Your card ending in <b>{lastFour}</b> will be charged ${unitPrice.toFixed(2)}
                                                &nbsp;on {moment(sub.next_billing_at * 1000).format(DATE_FORMAT)}.</li>}
                                            </>
                                        );
                                    } else if (sub.next_billing_at && lastFour) {
                                        whatsNext = (
                                            <li>Your card ending in <b>{lastFour}</b> will be charged ${unitPrice.toFixed(2)}
                                            &nbsp;on {moment(sub.next_billing_at * 1000).format(DATE_FORMAT)}.</li>
                                        );
                                    }

                                    // Show bullet if:
                                    //      - Plan is active, non-renewing, or future
                                    //      - In trial and they've paid and it doesn't have a cancelled_at
                                    const showPlanAndCost = (PLAN_COST_STATUSES.includes(sub.status)) || (sub.status === TRIAL_STATUS && !!lastFour && !sub.cancelled_at);

                                    return (
                                        <ul key={sub.id}>
                                            {showPlanAndCost && <li>Plan: {sub.subscription_items[0].item_price_id.split('-USD')[0].replace(/-/g, ' ')}</li>}
                                            {showPlanAndCost && <li>Cost: ${unitPrice.toFixed(2)}</li>}
                                            <li>Status: <b>{STATUS_MAP[sub.status]}</b></li>
                                            {whatsNext}
                                        </ul>
                                    );
                                })}

                                <input type="submit" className="primary edit-account" value={cta} />
                            </form>

                            {account?.user?.role === 'admin' &&
                                <form name="impersonate" className="impersonate" onSubmit={this.submitImpersonate}>
                                    <h2>Impersonate</h2>

                                    <fieldset>
                                        <label htmlFor="impersonateId">User ID</label>
                                        <input type="number"
                                            id="impersonateId"
                                            placeholder="5"
                                            value={impersonateId}
                                            onChange={(e) => this.updateState(e, 'impersonateId')} />
                                    </fieldset>

                                    <fieldset>
                                        <label htmlFor="impersonatePassword">Password</label>
                                        <input type="password"
                                            id="impersonatePassword"
                                            placeholder="hunter2"
                                            value={impersonatePassword}
                                            onChange={(e) => this.updateState(e, 'impersonatePassword')} />
                                    </fieldset>

                                    <input type="submit" className="primary" value="Impersonate User" />
                                </form>
                            }
                        </div>
                    </div>

                    {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                    <Footer />
                </div>
            );
        }

        return content;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
