import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import accountReducer from './accountReducer';
import dataReducer from './dataReducer';
import budgetPlanReducer from './budgetPlanReducer';
import plaidReducer from './plaidReducer';
import chargebeeReducer from './chargebeeReducer';
import miscReducer from './miscReducer';

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        account: accountReducer,
        data: dataReducer,
        budgetPlan: budgetPlanReducer,
        plaid: plaidReducer,
        chargebee: chargebeeReducer,
        misc: miscReducer,
    });

export default reducers;
