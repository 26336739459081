import {
    CHANGE_PLAN_TYPE_ERROR,
    CHANGE_PLAN_TYPE_SUCCESS,
    DISCOUNT_CODE_ERROR,
    DISCOUNT_CODE_SUCCESS,
    PAYMENT_SUBMIT_ERROR,
    PAYMENT_SUBMIT_SUCCESS,
} from '../constants/actionTypes';
import chargebeeService from '../services/chargebeeService';

export const submitPayment = (payment) => dispatch => (
    chargebeeService.submitPayment(payment)
        .then(res => {
            dispatch({
                type: PAYMENT_SUBMIT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: PAYMENT_SUBMIT_ERROR,
                payload: e,
            });
        })
);

export const changePlanType = (user) => dispatch => (
    chargebeeService.changePlanType(user)
        .then(res => {
            dispatch({
                type: CHANGE_PLAN_TYPE_SUCCESS,
                payload: res,
            });
            setTimeout(() => {
                window.location.href = '/subscriptionSettings';
            }, 750);
        })
        .catch(e => {
            dispatch({
                type: CHANGE_PLAN_TYPE_ERROR,
                payload: e,
            });
        })
);

export const applyDiscountCode = (discountCode) => dispatch => (
    chargebeeService.applyDiscountCode(discountCode)
        .then(res => {
            dispatch({
                type: DISCOUNT_CODE_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DISCOUNT_CODE_ERROR,
                payload: e,
            });
        })
);
