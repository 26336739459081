import {
    FINANCIAL_FACT_LOAD_ERROR,
    FINANCIAL_FACT_LOAD_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    financialFact: null,
};

const miscReducer = (state = initialState, action) => {
    switch (action.type) {
        case FINANCIAL_FACT_LOAD_ERROR:
            return Object.assign({}, state, {
                financialFact: null,
            });
        case FINANCIAL_FACT_LOAD_SUCCESS:
            return Object.assign({}, state, {
                financialFact: action.payload,
            });
        default:
            return state;
    }
};

export default miscReducer;
