/**
 * Misc Service
 */

import http from '../utils/http';

class miscService {
    static async loadFinancialFact(user) {
        return http.get(`/api/financialFact?userId=${user.id}`).then(res => res.body);
    }

    static async emailError(user, error) {
        return http.post(`/api/error`, {user: user, error: error}).then(res => res.body);
    }
}

export default miscService;
