import React, { Component } from 'react';
import cn from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import { NUM_FORMAT, DATE_FORMAT_US } from 'constants/config';

class TransactionRow extends Component {
    render() {
        const { t, a, pn, options, editTransaction } = this.props;

        return (
            <div key={t.id} className={cn('row', t.ignore && 'ignore')}>
                {t.new && <span className="dot new-transaction" />}
                <div className={cn('field date', t.pending && 'pending')}>{moment(t.date).format(DATE_FORMAT_US)}</div>
                <div className={cn('field name', t.pending && 'pending')}>{t.name}</div>
                <div className={cn('field amount', t.pending && 'pending')}>{numeral(t.amount).format(NUM_FORMAT)}</div>
                <div className="field category">
                    {t.pending && <span className="pending">- Pending Transaction -</span>}
                    {!t.pending && t.ignore && <span className="ignore">- n/a -</span>}
                    {!t.pending && !t.ignore && t.category && t.record.type && <span className={cn('dot', t.record.type)} />}
                    {!t.pending && !t.ignore &&
                        <Autocomplete
                            className="default-category"
                            options={options}
                            groupBy={(option) => option.displayType}
                            getOptionLabel={(option) => option.source || option.category || ''}
                            onChange={(e, value) => editTransaction(e, value, t, a, pn, 'category')}
                            value={(t.category) ? t.record : ''}
                            renderOption={(option) => {
                                let text = option.source || option.category;
                                if (option.savings_withdrawal) {
                                    text += ' - Savings Withdrawal';
                                }
                                return (
                                    <span className={cn('option', option.type)}>{text}</span>
                                );
                            }}
                            renderInput={(params) => {
                                if (t.record && t.record.savings_withdrawal) {
                                    params.inputProps.value += ' - Savings Withdrawal';
                                }

                                return (
                                    <div ref={params.InputProps.ref}>
                                        <input type="text" {...params.inputProps} title={params.inputProps.value} placeholder="-- Uncategorized --" />
                                    </div>
                                );
                            }}
                        />
                    }
                </div>
                <div className="field ignore">
                    {!t.pending && <Checkbox id={`transaction-${t.id}`} checked={t.ignore} onClick={(e, value) => editTransaction(e, value, t, a, pn, 'ignore')} />}
                </div>
            </div>
        );
    }
}

export default TransactionRow;
