import moment from 'moment';
import { DATE_FORMAT } from 'constants/config';

export const savingsRatePieLabel = (options) => {
    const RADIAN = Math.PI / 180;
    const radius = options.outerRadius + 5;
    const x = options.cx + radius * Math.cos(-options.endAngle * RADIAN);
    const y = options.cy + radius * Math.sin(-options.endAngle * RADIAN);

    return (
        <text stroke="none" fill={options.fill} x={x} y={y} textAnchor={x > options.cx ? 'start' : 'end'}>
            {`${options.label}%`}
        </text>
    );
};

export const scale = (number, inMin, inMax, outMin, outMax) => {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
};

export const fieldNeedsUpdating = (key, val, lastMod, goalsOrLastModField) => {
    const checkAnnually = ['grossAnnualIncome', 'annualExpenses'];

    let lastModField = null;
    if (typeof(goalsOrLastModField) === 'string') {
        lastModField = goalsOrLastModField;
    } else if (['ytdIncome', 'ytd_income'].includes(key)) {
        lastModField = goalsOrLastModField?.ytd_income_modified;
    } else if (['ytdRetirement', 'ytd_retirement'].includes(key)) {
        lastModField = goalsOrLastModField?.ytd_retirement_modified;
    }

    return !val || !lastMod ||
        (checkAnnually.includes(key) && val && lastMod && Math.ceil(moment().diff(lastMod, 'days', true)) > 365) ||
        (lastModField && moment().startOf('month').format(DATE_FORMAT) !== moment(lastModField).startOf('month').format(DATE_FORMAT));
}
