import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Login from 'views/Login';
import ForgotPassword from 'views/ForgotPassword';
import Signup from 'views/Signup';
import SignupSuccess from 'views/SignupSuccess';
import CreateAccount from 'views/CreateAccount';
import Survey from 'views/Survey';
import LoggedOut from 'views/LoggedOut';
import Dashboard from 'views/Dashboard';
import Account from 'views/Account';
import AssetsLiabilities from 'views/AssetsLiabilities';
import CashflowGoals from 'views/CashflowGoals';
import BudgetPlanner from 'views/BudgetPlanner';
import FinancialPriorities from 'views/FinancialPriorities';
import Task from 'views/Task';
import EditHistorical from 'views/EditHistorical';
import RetirementPlanner from 'views/RetirementPlanner';
import OAuth from 'views/OAuth';
import VerifyAccount from 'views/VerifyAccount';
import SubscriptionSettings from 'views/SubscriptionSettings';

class App extends Component {
    render() {
        const { history } = this.props;

        return (
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path="/" render={route => <Login />} />
                    <Route exact path="/login" render={route => <Login />} />
                    <Route exact path="/forgotPassword" render={route => <ForgotPassword />} />
                    <Route exact path="/signup" render={route => <Signup />} />
                    <Route exact path="/benefit/signup" render={route => <Signup />} />
                    <Route exact path="/signupSuccess" render={route => <SignupSuccess />} />
                    <Route exact path="/createAccount" render={route => <CreateAccount />} />
                    <Route exact path="/survey" render={route => <Survey />} />
                    <Route exact path="/retirement" render={router => <RetirementPlanner />} />
                    <Route exact path="/loggedOut" render={route => <LoggedOut />} />
                    <Route exact path="/logout" render={route => <LoggedOut />} />
                    <Route exact path="/verify" render={route => <VerifyAccount />} />

                    <Route exact path="/dashboard" render={route => <Dashboard />} />
                    <Route exact path="/account" render={route => <Account />} />
                    <Route exact path="/assetsLiabilities" render={route => <AssetsLiabilities />} />
                    <Route exact path="/cashflowGoals" render={route => <CashflowGoals />} />
                    <Route exact path="/budgetPlanner" render={route => <BudgetPlanner />} />
                    <Route exact path="/plan" render={route => <FinancialPriorities />} />
                    <Route exact path="/task" render={route => <Task />} />
                    <Route exact path="/editHistorical" render={route => <EditHistorical />} />
                    <Route exact path="/oauth" render={route => <OAuth />} />
                    <Route exact path="/subscriptionSettings" render={route => <SubscriptionSettings />} />
                </Switch>
            </ConnectedRouter>
        );
    }
}

export default App;
