
export const DATE_FORMAT = 'MMMM Do, YYYY';

export const DATE_FORMAT_SHORT = 'MMM YYYY';

export const DATE_FORMAT_US = 'M/D/YYYY';

export const DATE_FORMAT_INTL = 'YYYY-MM-DD';

export const NUM_FORMAT = '$0,0.00';

export const NUM_FORMAT_SHORT = '$0,0';

export const NUM_FORMAT_MM = '($0.0a)';

export const NUM_FORMAT_MM_SHORT = '($0a)';

export const ZERO_NUM = '$0.00';

export const MONTHS = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
];

export const ALERT_TIMEOUTS = { 'success': 3000, 'error': 10000 };

export const ITEM_LOGIN_REQUIRED = 'ITEM_LOGIN_REQUIRED';

export const MIN_PASSWORD_LENGTH = 8;

export const DATAVIZ_COLORS = [
    '#66D8DF',
    '#6FB7FF',
    '#8878F7',
    '#B851C1',
    '#FF7CA3',
    '#FF9FCD',
    '#FFA34F',
    '#FADE7E',
];

export const THIS_YEAR = (new Date()).getFullYear();

export const TRIAL_STATUS = 'in_trial';

export const TRIAL_DAYS = 34;

export const MONTHLY_PLAN = 'MoneySwell-Monthly-Plan-USD-Monthly';

export const MONTHLY_AMOUNT = 9;

export const ANNUAL_PLAN = 'MoneySwell-Annual-Plan-USD-Yearly';

export const ANNUAL_AMOUNT = 95;

export const DELETED = 'deleted';
