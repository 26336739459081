import {
    DELETE_BUDGET_PLAN_ERROR,
    DELETE_BUDGET_PLAN_SUCCESS,
    EDIT_TRANSACTIONS_SUCCESS,
    LOAD_BUDGET_PLAN_ERROR,
    LOAD_BUDGET_PLAN_SUCCESS,
    LOAD_BUDGET_PLAN_MONTH_ERROR,
    LOAD_BUDGET_PLAN_MONTH_SUCCESS,
    LOAD_BUDGET_PLAN_REVIEW_ERROR,
    LOAD_BUDGET_PLAN_REVIEW_SUCCESS,
    SAVE_BUDGET_PLAN_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    plan: null,
    planMonth: null,
    transactions: null,
    manualTransactions: null,
    defaultCategories: null,
    budgetPlanReview: null,
    success: null,
    error: null,
};

const budgetPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_BUDGET_PLAN_ERROR:
        case LOAD_BUDGET_PLAN_ERROR:
        case LOAD_BUDGET_PLAN_MONTH_ERROR:
            return Object.assign({}, state, {
                success: null,
                error: action.payload,
            });
        case DELETE_BUDGET_PLAN_SUCCESS:
            return Object.assign({}, state, {
                success: action.payload,
                error: null,
            });
        case LOAD_BUDGET_PLAN_SUCCESS:
        case SAVE_BUDGET_PLAN_SUCCESS:
            return Object.assign({}, state, {
                plan: action.payload.plan,
                success: null,
                error: null,
            });
        case LOAD_BUDGET_PLAN_MONTH_SUCCESS:
            return Object.assign({}, state, {
                planMonth: action.payload.planMonth,
                transactions: [...action.payload.transactions],
                manualTransactions: action.payload.manual,
                defaultCategories: action.payload.defaultCategories,
                success: null,
                error: null,
            });
        case EDIT_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                manualTransactions: action.payload.manual,
                transactions: null,
                defaultCategories: null,
                success: null,
                error: null,
            });
        case LOAD_BUDGET_PLAN_REVIEW_ERROR:
            return Object.assign({}, state, {
                budgetPlanReview: null,
                error: action.payload,
                success: null,
            });
        case LOAD_BUDGET_PLAN_REVIEW_SUCCESS:
            return Object.assign({}, state, {
                budgetPlanReview: action.payload,
                error: null,
                success: null,
            });
        default:
            return state;
    }
};

export default budgetPlanReducer;
