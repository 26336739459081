import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import cn from 'classnames';
import numeral from 'numeral';
import moment from 'moment';
import { findIndex } from 'lodash';
import ReactPlayer from 'react-player/file'
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import { PieChart, Pie, Cell, BarChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ComposedChart, Line, Tooltip } from 'recharts';
import Loading from 'components/Loading';
import NavBar from 'components/NavBar';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import TaskProgress from 'components/TaskProgress';
import IconButton from 'components/IconButton';
import MinusIcon from 'components/MinusIcon';
import CashflowTable from 'components/CashflowTable';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, loadAssetsLiabilities, saveRetirementPlanner, loadRetirementPlanner } from 'actions/dataActions';
import { loadRPIncome, saveRPIncome, removeRPIncome, loadRPGoals, saveRPGoals, loadRPGoalYears, loadRPGoalsAll, deleteHP } from 'actions/dataActions';
import { savingsRatePieLabel, scale, fieldNeedsUpdating } from './utils';
import {
    STEPS, FIELD_MAP, MY_DETAILS_FIELDS, PARTNER_FIELDS, EMPTY_ROWS, GOALS_OUTLOOK_FIELDS, DUMMY_HISTORICAL,
    EXCLUDE_FIELDS, GOAL_TYPES, END_AGE, INCOME_PERCENT_FIELDS, TOOLTIP_TEXT, MAX_ITERATIONS } from 'constants/retirementPlanner';
import { ASSETS, CATS, MAGIC_NUMERATOR } from 'constants/assetsLiabilities';
import { NUM_FORMAT, NUM_FORMAT_SHORT, NUM_FORMAT_MM, NUM_FORMAT_MM_SHORT, DATE_FORMAT_INTL, THIS_YEAR } from 'constants/config';
import CloseIcon from 'images/icons/close.svg';
import PlayIcon from 'images/icons/play.svg';
import AddIcon from 'images/icons/add.svg';
import RemoveIcon from 'images/icons/remove.svg';
import ErrorIcon from 'images/icons/error.svg';
import ArrowIcon from 'images/icons/arrow.svg';
import ArrowNav from 'images/icons/arrow_nav.svg';
import ArrowNavDisabled from 'images/icons/arrow_nav_disabled.svg';
import LowestIcon from 'images/icons/lowest.svg';
import MediumIcon from 'images/icons/medium.svg';
import HighestIcon from 'images/icons/highest.svg';
import PlayCircleIcon from 'images/icons/play_circle.svg';
import LearnMore1 from 'images/retirement-planner/learn_more1.png';
import LearnMore2 from 'images/retirement-planner/learn_more2.png';
import LearnMore3 from 'images/retirement-planner/learn_more3.png';
import LearnMore4 from 'images/retirement-planner/learn_more4.png';
import LearnMore5 from 'images/retirement-planner/learn_more5.png';
import LearnMore6 from 'images/retirement-planner/learn_more6.png';
import LearnMore7 from 'images/retirement-planner/learn_more7.png';
import DialogStyles from 'styles/DialogStyles.js';
import RPOverviewVideo from 'videos/rp_overview.mp4';
import RPMyInfoVideo from 'videos/rp_myinfo.mp4';
import RPGoalsOutlookVideo from 'videos/rp_goalsoutlook.mp4';
import RPSummaryVideo from 'videos/rp_summary.mp4';
import './RetirementPlanner.scss';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    assets: state.data.assets,
    retirementPlanner: state.data.retirementPlanner,
    rpIncome: state.data.rpIncome,
    rpGoals: state.data.rpGoals,
    rpGoalYears: state.data.rpGoalYears,
    rpGoalsAll: state.data.rpGoalsAll,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadRetirementPlanner: (user) => {
        dispatch(loadRetirementPlanner(user));
    },
    saveRetirementPlanner: (user, fields) => {
        dispatch(saveRetirementPlanner(user, fields));
    },
    loadAssetsLiabilities: (user) => {
        dispatch(loadAssetsLiabilities(user));
    },
    loadRPIncome: (user, rpId) => {
        dispatch(loadRPIncome(user, rpId));
    },
    saveRPIncome: (user, income, rpId) => {
        dispatch(saveRPIncome(user, income, rpId));
    },
    removeRPIncome: (user, income) => {
        dispatch(removeRPIncome(user, income));
    },
    loadRPGoals: (user, rpId, year, accountIds) => {
        dispatch(loadRPGoals(user, rpId, year, accountIds));
    },
    saveRPGoals: (user, goals, rpId) => {
        dispatch(saveRPGoals(user, goals, rpId));
    },
    loadRPGoalYears: (user, rpId) => {
        dispatch(loadRPGoalYears(user, rpId));
    },
    loadRPGoalsAll: (user, rpId, accountIds) => {
        dispatch(loadRPGoalsAll(user, rpId, accountIds));
    },
    deleteHP: (user, hpId) => {
        dispatch(deleteHP(user, hpId));
    },
});

class RetirementPlanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            rpLoaded: false,
            savingRP: false,
            assetsLoaded: false,
            incomeLoaded: false,
            goalsLoaded: false,
            hpLoaded: false,
            rpId: null,
            lastMod: null,
            edit: { success: false, error: false },
            step: STEPS[0].id,
            partnerAdded: false,
            updatedIncomeOrExpenses: false,
            myDetailsCollapsed: false,
            assetsIncomeCollapsed: false,
            addPartnerCollapsed: false,
            goalsProgressCollapsed: false,
            achievementsGoalsCollapsed: false,
            futureOutlookCollapsed: false,
            nestEggNeedsCollapsed: false,
            historicalProgressCollapsed: false,
            myDetailsLMOpen: false,
            assetsIncomeLMOpen: false,
            goalsProgressLMOpen: false,
            achievementsGoalsLMOpen: false,
            futureOutlookLMOpen: false,
            nestEggNeedsLMOpen: false,
            historicalProgressLMOpen: false,
            introDialogOpen: false,
            alDialogOpen: false,
            gotoALRemember: false,
            editHistoricalDialogOpen: false,
            editHistoricalRemember: false,
            startValueDialogOpen: false,
            editGoalDialogOpen: false,
            myDetailsFields: MY_DETAILS_FIELDS,
            assets: [],
            selectedAccounts: [],
            income: [Object.assign({}, EMPTY_ROWS.income)],
            selectedIncome: [],
            needsUpdating: false,
            experimentationIncome: [],
            rpGoals: null,
            goalsOutlookFields: GOALS_OUTLOOK_FIELDS,
            lastModIncome: null,
            lastModRetirement: null,
            years: [],
            annualIncome: null,
            annualExpenses: null,
            desiredIncomePercent: INCOME_PERCENT_FIELDS,
            barHover: {},
            historicalProgress: [],
            editingHPRow: {},
            addingHPRow: {},
            mobile: window.innerWidth < 1440,
            expandMobile: null,
            overviewVideoOpen: false,
            myInfoVideoOpen: false,
            goalsOutlookVideoOpen: false,
            summaryVideoOpen: false,
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};
        let account = nextProps.account;

        // Load user account (if logged-in) and FP data for sidebar //
        if (!this.state.loaded && account.user) {
            state.loaded = true;

            if (!nextProps.financialPriorities) {
                this.props.loadFinancialPriorities(account.user);
            }

            if (!nextProps.assets && !nextProps.financialPriorities && !nextProps.retirementPlanner) {
                this.props.loadRetirementPlanner(account.user);
            }

            if (!nextProps.assets && !nextProps.financialPriorities && !nextProps.retirementPlanner) {
                this.props.loadAssetsLiabilities(account.user);
            }
        } else if (!this.state.loaded && !account.loaded && account.error.status === 403) {
            // Base retirement planner data from localStorage //
            state = Object.assign({}, this.state, JSON.parse(localStorage.getItem('msRetirementPlanner')));
            state.introDialogOpen = true;
            state.mobile = window.innerWidth < 1440;
            state.step = 'myInfo';
            state.loaded = true;
            state.rpLoaded = true;

            // Assets //
            state.assetsLoaded = true;
            state.selectedAccounts = state.assets.map(a => a.id);

            // Income //
            state.experimentationIncome = [];
            state.income.forEach(rpi => {
                state.experimentationIncome.push(Object.assign({}, rpi));
                state.selectedIncome.push(rpi.id);
            });
            state.incomeLoaded = true;

            // Goals //
            if (state.goalsOutlookFields.year === '') {
                state.goalsOutlookFields.year = THIS_YEAR;
            }
            state.goalsOutlookFields.ytdValue = state.assets.reduce((acc, curr) => acc + Number(curr.amount), 0);
            state.goalsOutlookFields.startValue = state.goalsOutlookFields.ytdValue;
            state.goalsLoaded = true;

            // Summary //
            state.hpLoaded = true;
            state.historicalProgressLMOpen = true;
            state.historicalProgress = DUMMY_HISTORICAL;
        }

        // Load retirement planner data if present //
        if (!this.state.rpLoaded && nextProps.retirementPlanner) {
            const rp = nextProps.retirementPlanner;

            if (Object.keys(rp).length > 0) {
                state.rpId = rp.id;
                state.lastMod = moment(rp.modified);
                state.myDetailsFields = Object.assign({}, MY_DETAILS_FIELDS);
                state.desiredIncomePercent = Object.assign({}, INCOME_PERCENT_FIELDS);

                Object.keys(rp).forEach(k => {
                    if (FIELD_MAP[k] in state.myDetailsFields) {
                        state.myDetailsFields[FIELD_MAP[k]] = rp[k];
                    }
                    if (FIELD_MAP[k] in PARTNER_FIELDS) {
                        if (rp[k]) {
                            state.partnerAdded = true;
                            state.partnerCollapsed = false;
                        }
                        state.myDetailsFields[FIELD_MAP[k]] = rp[k];
                    }
                    if (FIELD_MAP[k] in INCOME_PERCENT_FIELDS) {
                        state.desiredIncomePercent[FIELD_MAP[k]] = rp[k];
                    }

                });

                this.props.loadRPIncome(account.user, state.rpId);
                this.props.loadRPGoalYears(account.user, state.rpId);
                if (this.state.assetsLoaded && !this.state.goalsLoaded) {
                    this.props.loadRPGoals(account.user, state.rpId, THIS_YEAR, this.state.selectedAccounts);
                }
                if (this.state.assetsLoaded && !this.state.hpLoaded && this.state.selectedAccounts?.length) {
                    this.props.loadRPGoalsAll(account.user, state.rpId, this.state.selectedAccounts);
                }

                state.annualIncome = state.myDetailsFields.grossAnnualIncome;
                state.annualExpenses = state.myDetailsFields.annualExpenses;
                state.rpLoaded = true;
                state.savingRP = false;
            } else if (!this.state.savingRP) {
                state.savingRP = true;
                this.props.saveRetirementPlanner(this.props.account.user, {...this.state.myDetailsFields});
            }
        }

        // Load asset accounts for selection by user (if logged-in) //
        if (!this.state.assetsLoaded && nextProps.assets) {
            nextProps.assets.forEach(p => {
                const assetType = ASSETS.filter(a => a.value === p.type)[0];
                p.retirement = assetType.parent === CATS.retirement;
            })
            state.assets = nextProps.assets.filter(a => a.retirement);
            state.selectedAccounts = [];
            state.assets.forEach(a => {
                state.selectedAccounts.push(a.id);
            });

            if (this.state.rpLoaded && !this.state.goalsLoaded) {
                this.props.loadRPGoals(account.user, this.state.rpId, THIS_YEAR, state.selectedAccounts);
            }
            if (this.state.rpLoaded && !this.state.hpLoaded && state.selectedAccounts?.length) {
                this.props.loadRPGoalsAll(account.user, this.state.rpId, state.selectedAccounts);
            }

            state.assetsLoaded = true;
        }

        // Load additional income source data if present //
        if (!this.state.incomeLoaded && nextProps.rpIncome) {
            state.income = [];
            state.experimentationIncome = [];
            if (nextProps.rpIncome?.length) {
                nextProps.rpIncome.forEach(rpi => {
                    state.income.push(Object.assign({}, rpi));
                    state.experimentationIncome.push(Object.assign({}, rpi));
                });
                state.selectedIncome = nextProps.rpIncome.map(rpi => rpi.id);
            } else {
                state.income = [Object.assign({}, EMPTY_ROWS.income)];
            }
            state.incomeLoaded = true;
        }

        // Load goal years for dropdown if present //
        if (nextProps.rpGoalYears) {
            state.years = (nextProps.rpGoalYears?.length) ? nextProps.rpGoalYears : [{year: THIS_YEAR}];
        }

        // Load goals & outlook fields if present //
        if (!this.state.goalsLoaded && nextProps.rpGoals) {
            const rpg = nextProps.rpGoals;
            state.rpGoals = rpg;

            if (Object.keys(rpg).length > 0) {
                state.goalsOutlookFields = Object.assign({}, GOALS_OUTLOOK_FIELDS);

                Object.keys(rpg).forEach(k => {
                    if (FIELD_MAP[k] in state.goalsOutlookFields) {
                        state.goalsOutlookFields[FIELD_MAP[k]] = rpg[k];
                    }
                });
            }

            state.lastModIncome = rpg.ytd_income_modified;
            state.lastModRetirement = rpg.ytd_retirement_modified;
            state.goalsLoaded = true;

            // if (this.state.years.length === 0) {
            //     state.years.splice(0, 0, {year: rpg.year});
            // }
        }

        // Load historical progress if present (or reload if just added a row) //
        if (!this.state.hpLoaded && nextProps.rpGoalsAll) {
            state.historicalProgress = (nextProps.rpGoalsAll?.length) ? nextProps.rpGoalsAll : [];
            state.hpLoaded = true;
        }
        if (account.user && this.state.hpLoaded && typeof(nextProps.rpGoals) === 'string' && nextProps.rpGoals.includes('needs updating')) {
            state.hpLoaded = false;
            state.historicalProgress = [];
            this.props.loadRPGoalsAll(account.user, this.state.rpId, this.state.selectedAccounts);
        }

        state.edit = {
            success: nextProps.success,
            error: nextProps.error,
        };

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: window.innerWidth < 1440 });
    }

    saveStateToLocalStorage = () => {
        let state = Object.assign({}, this.state);

        state.selectedAccounts = state.assets.map(a => a.id);
        state.goalsOutlookFields.ytdValue = state.assets.reduce((acc, curr) => acc + Number(curr.amount), 0);
        state.goalsOutlookFields.startValue = state.goalsOutlookFields.ytdValue;
        state.experimentationIncome = [];
        state.income.forEach(rpi => {
            state.experimentationIncome.push(Object.assign({}, rpi));
        });
        state.selectedIncome = state.income.map(i => i.id);
        this.setState(state);
        localStorage.setItem('msRetirementPlanner', JSON.stringify(state));
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    setStep = (e, step, gotoAnchor) => {
        e.preventDefault();

        this.setState({ step: step, editGoalDialogOpen: false });

        if (gotoAnchor) {
            setTimeout(() => {
                document.getElementById(gotoAnchor).scrollIntoView();
            }, 0)
        } else {
            window.scrollTo(0,0);
        }

        return false;
    }

    previousStep = (step) => {
        if (step === 'myInfo') {
            return false;
        } else if (step === 'goalsOutlook') {
            this.setState({ step: 'myInfo' });
        } else if (step === 'summary') {
            this.setState({ step: 'goalsOutlook' });
        }
    }

    nextStep = (step) => {
        if (step === 'myInfo') {
            this.setState({ step: 'goalsOutlook' });
        } else if (step === 'goalsOutlook') {
            this.setState({ step: 'summary' });
        } else if (step === 'summary') {
            return false;
        }
    }

    toggleDialog = (e, id) => {
        e.preventDefault();
        let state = {};
        state[id] = !this.state[id];
        this.setState(state);
    }

    toggleIntroDialog = () => {
        this.setState({ introDialogOpen: !this.state.introDialogOpen });
    }

    toggleALDialog = (e) => {
        e.preventDefault();

        if (localStorage.getItem('gotoALRemember')) {
            window.location.href = '/assetsLiabilities';
        } else {
            this.setState({ alDialogOpen: !this.state.alDialogOpen });
        }
    }

    gotoAL = () => {
        if (this.state.gotoALRemember) {
            localStorage.setItem('gotoALRemember', true);
        }
        window.location.href = '/assetsLiabilities';
    }

    gotoALRemember = () => {
        this.setState({ gotoALRemember: !this.state.gotoALRemember });
    }

    toggleEditHistoricalDialog = (e) => {
        e.preventDefault();

        if (localStorage.getItem('editHistoricalRemember')) {
            window.location.href = `/editHistorical?date=${this.state.goalsOutlookFields.year}-12-31`;
        } else {
            this.setState({ editHistoricalDialogOpen: !this.state.editHistoricalDialogOpen });
        }
    }

    gotoEditHistorical = (e, date) => {
        if (this.state.editHistoricalRemember) {
            localStorage.setItem('editHistoricalRemember', true);
        }
        window.location.href = `/editHistorical?date=${date}`;
    }

    editHistoricalRemember = () => {
        this.setState({ editHistoricalRemember: !this.state.editHistoricalRemember });
    }

    toggleEditValueDialog = (e) => {
        e.preventDefault();
        this.setState({ startValueDialogOpen: !this.state.startValueDialogOpen });
    }

    toggleSection = (e, section) => {
        e.preventDefault()

        let key = `${section}Collapsed`;
        let state = {};
        state[key] = !this.state[key];
        this.setState(state);

        return false;
    }

    toggleLearnMore = (e, section) => {
        e.preventDefault()

        let state = {};
        state[section] = !this.state[section];
        this.setState(state);

        return false;
    }

    updateField = (e, fields, field) => {
        let state = {};

        if (field === 'ytdIncome' && e.floatValue !== Number(this.state.goalsOutlookFields.ytdIncome)) {
            state.lastModIncome = null;
        }
        if (field === 'ytdRetirement' && e.floatValue !== Number(this.state.goalsOutlookFields.ytdRetirement)) {
            state.lastModRetirement = null;
        }

        if (fields !== '') {
            state[fields] = this.state[fields];
            state[fields][field] = (e.target) ? e.target.value : e.value;
        } else {
            state[field] = (e.target) ? e.target.value : e.value;
        }

        if (['grossAnnualIncome', 'annualExpenses'].includes(field) && fields !== '') {
            state.updatedIncomeOrExpenses = true;
            if (field === 'grossAnnualIncome') state.annualIncome = state[fields][field];
            if (field === 'annualExpenses') state.annualExpenses = state[fields][field];
        }

        if (fields === 'desiredIncomePercent') {
            this.saveRetirementPlanner();
        }

        this.setState(state);
    }

    addPartner = (e) => {
        e.preventDefault()
        this.setState({
            myDetailsFields: {...this.state.myDetailsFields, ...PARTNER_FIELDS},
            partnerAdded: true,
        });
        return false;
    }

    removePartner = (e) => {
        e.preventDefault()
        const { partnerFirstName, partnerBirthday, partnerGoalRetirementAge, ...fields } = this.state.myDetailsFields;
        this.setState({ myDetailsFields: fields, partnerAdded: false });
        this.props.saveRetirementPlanner(
            this.props.account.user,
            {...fields, ...PARTNER_FIELDS, ...this.state.desiredIncomePercent},
        );
        return false;
    }

    removeRow = (type, index) => {
        const row = this.state[type][index];

        this.state[type].splice(index, 1);
        this.setState(this.state);

        if (type === 'income' && this.props.account.user !== null) {
            this.props.removeRPIncome(this.props.account.user, row);
        }

        if (this.props.account.user === null) {
            this.saveStateToLocalStorage();
        }
    }

    addRow = (e, type) => {
        e.stopPropagation();

        if (JSON.stringify(this.state[type][this.state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
            this.state[type].splice(this.state[type].length - 1, 1);
        }

        this.state[type].forEach(t => t.editing = false);

        this.state[type].push(Object.assign({editing: true}, EMPTY_ROWS[type]));
        let state = {};
        state[type] = this.state[type];
        state[type][state[type].length - 1].id = -(state[type].length - 1);

        this.setState(state);

        if (this.props.account.user === null) {
            this.saveStateToLocalStorage();
        }
    }

    editRow = (e, field, type, index) => {
        if (field === null) {
            let state = Object.assign({}, this.state);

            if (JSON.stringify(state[type][state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
                state[type].splice(state[type].length - 1, 1);
            }
            state[type].forEach(t => t.editing = false);
            state[type][index].editing = true;
            this.setState(state);

            return false;
        }

        let id = (field.value) ? field.value : field.id;
        let value = (e?.target?.value) ? e.target.value : e.value;
        let state = {};

        state[type] = [...this.state[type]];
        state[type][index][id] = value;
        state.needsUpdating = true;
        this.setState(state);

        if (this.props.account.user === null) {
            this.saveStateToLocalStorage();
        }
    }

    saveIncome = (e, index) => {
        if (this.state.needsUpdating) {
            if (this.props.account.user === null) {
                this.setState({ incomeLoaded: true, needsUpdating: false });
                this.saveStateToLocalStorage();
            } else {
                this.setState({ incomeLoaded: false, needsUpdating: false });
                this.props.saveRPIncome(this.props.account.user, this.state.income[index], this.state.rpId);
            }
        }
    }

    saveRetirementPlanner = () => {
        if (this.props.account.user === null) {
            this.saveStateToLocalStorage();
        } else {
            this.props.saveRetirementPlanner(
                this.props.account.user,
                {...this.state.myDetailsFields, ...this.state.desiredIncomePercent},
            );
        }
        this.setState({ lastMod: moment() });
    }

    loadGoalsForYear = (e) => {
        if (e.target.value !== '' && this.state.rpId) {
            this.props.loadRPGoals(this.props.account.user, this.state.rpId, e.target.value, this.state.selectedAccounts);
            this.setState({ goalsLoaded: false });
        } else {
            this.setState({ goalsOutlookFields: GOALS_OUTLOOK_FIELDS });
        }
    }

    saveRPGoals = (e) => {
        if (this.props.account.user === null) {
            this.saveStateToLocalStorage();
        } else {
            this.props.saveRPGoals(this.props.account.user, {...this.state.goalsOutlookFields}, this.state.rpId);

            if (['ytdIncome', 'ytdRetirement'].includes(e.target.name)) {
                setTimeout(() => {
                    this.setState({ hpLoaded: false });
                    this.props.loadRPGoalsAll(this.props.account.user, this.state.rpId, this.state.selectedAccounts);
                }, 500);
            }

        }
    }

    updateValues = (e) => {
        e.preventDefault();

        if (this.state.goalsOutlookFields.year === THIS_YEAR) {
            this.toggleALDialog(e);
        } else {
            this.toggleEditHistoricalDialog(e);
        }

        return false;
    }

    toggleSelectedIncome = (e, incomeId) => {
        e.preventDefault();

        let selected = this.state.selectedIncome;
        let state = {};

        // Removing an account from selected array //
        if (selected.includes(incomeId)) {
            selected.forEach((id, i) => {
                if (id === incomeId) {
                    selected.splice(i, 1);
                }
            });
        } else {
            // Adding an account to selected array //
            selected.push(incomeId);
        }

        state.selectedIncome = selected;
        this.setState(state);

        return false;
    }

    resetExperimentationBox = (e) => {
        e.preventDefault();

        this.setState({
            annualIncome: this.state.myDetailsFields.grossAnnualIncome,
            annualExpenses: this.state.myDetailsFields.annualExpenses,
            selectedIncome: this.state.experimentationIncome.map(i => i.id),
            experimentationIncome: this.state.income.map(i => Object.assign({}, i)),
        });

        return false;
    }

    calculateAge = (birthday, year) => {
        return moment().set('year', year).diff(moment(birthday), 'year');
    }

    futureValue = (rate, periods, amount, presentValue) => {
        return (Number(presentValue) * ((1 + Number(rate)/100) ** Number(periods))) + Number(amount);
    }

    formatFutureOutlookData = (state) => {
        let data = [], fields = [], index = 1;
        let doneFormattingData = false;
        let year = THIS_YEAR;
        let current = {};

        GOAL_TYPES.filter(gt => !gt.legendOnly).forEach(gt => {
            current[gt.id] = Number(state.goalsOutlookFields.startValue);
        });

        fields = [
            { id: 'year', label: 'Year' },
            { id: 'age', label: `${state.myDetailsFields.firstName}'s Age` },
            { id: 'minimum', label: 'Minimum Goal' },
            { id: 'target', label: 'Target Goal' },
            { id: 'stretch', label: 'Stretch Goal' },
            { id: 'description', label: 'Description' },
        ];

        if (state.partnerAdded) {
            fields.splice(2, 0, { id: 'partnerAge', label: `${state.myDetailsFields.partnerFirstName}'s Age` })
        }

        while (!doneFormattingData) {
            let datum = {
                year: year,
                age: this.calculateAge(state.myDetailsFields.birthday, year++),
                minimum: current.minimum,
                target: current.target,
                stretch: current.stretch,
                description: '',
            };

            if (state.partnerAdded) {
                datum.partnerAge = this.calculateAge(state.myDetailsFields.partnerBirthday, datum.year);
            }

            if (datum.year === THIS_YEAR) {
                datum.description = 'Starting value';
            }

            if (datum.age === Number(state.myDetailsFields.goalRetirementAge)) {
                datum.description = `${state.myDetailsFields.firstName}'s goal retirement year`;
            }

            if (state.myDetailsFields.partnerGoalRetirementAge && datum.partnerAge === Number(state.myDetailsFields.partnerGoalRetirementAge)) {
                datum.description = `${state.myDetailsFields.partnerFirstName}'s goal retirement year`;
            }

            if (datum.age === state.myDetailsFields.goalRetirementAge && state.myDetailsFields.partnerGoalRetirementAge && datum.partnerAge === state.myDetailsFields.partnerGoalRetirementAge) {
                datum.description = `${state.myDetailsFields.firstName}'s & ${state.myDetailsFields.partnerFirstName}'s goal retirement year`;
            }

            data.push(datum);

            GOAL_TYPES.filter(gt => !gt.legendOnly).forEach(gt => {
                current[gt.id] = this.futureValue(state.goalsOutlookFields.expectedROR, 1, state.goalsOutlookFields[gt.id], datum[gt.id]);
            });

            if (datum.age === END_AGE || datum.partnerAge === END_AGE || Number.isNaN(datum.age) || index >= MAX_ITERATIONS) {
                doneFormattingData = true;
            }

            index++;
        }

        return { data, fields };
    }

    calculateNestEggNeeds = (state) => {
        const { annualIncome, annualExpenses } = state;
        const { minimum, target, optimal } = state.desiredIncomePercent;
        const addlMonthlyIncome = state.experimentationIncome.filter(i => state.selectedIncome.includes(i.id)).reduce(
            (acc, curr) => acc + Number(curr.amount), 0);
        const multipliers = { low: 10, medium: 13.5, high: 17 };
        const expenses = { low: 25, medium: 27.5, high: 30 };
        const colors = { low: "#FFCA52", medium: "#8878F7", high: "#66D8DF" };

        let incomeMethod = {};
        Object.keys(multipliers).forEach(k =>
            incomeMethod[k] = (Number(annualIncome) - (addlMonthlyIncome * 12)) * multipliers[k]
        );

        let fourPercentMethod = {
            low: ((Number(annualIncome) - (addlMonthlyIncome * 12)) / 0.04) * (minimum / 100),
            medium: ((Number(annualIncome) - (addlMonthlyIncome * 12)) / 0.04) * (target / 100),
            high: ((Number(annualIncome) - (addlMonthlyIncome * 12)) / 0.04) * (optimal / 100),
        };

        let expensesMethod = {};
        Object.keys(expenses).forEach(k =>
            expensesMethod[k] = (annualExpenses - (addlMonthlyIncome * 12)) * expenses[k]
        );

        let nestEggNeeds = {};
        Object.keys(colors).forEach(k =>
            nestEggNeeds[k] = { id: k, fill: colors[k], value: Math.round((incomeMethod[k] + fourPercentMethod[k] + expensesMethod[k]) / 3) }
        );

        return nestEggNeeds;
    }

    formatYAxisTicks = (tick) => {
        return numeral(tick).format(NUM_FORMAT_MM);
    }

    formatYAxisTicksNestEgg = (tick) => {
        const format = (tick < 1000000) ? NUM_FORMAT_MM_SHORT : NUM_FORMAT_MM;
        return numeral(tick).format(format);
    }

    chartMouseOut = () => {
        this.setState({ barHover: {} });
    };

    chartMouseMove = (opts) => {
        const payload = (opts.activePayload) ? opts.activePayload[0].payload : {};
        const barHover = { ...payload, index: opts.activeTooltipIndex };

        if (barHover.index !== this.state.barHover.index) {
            this.setState({ barHover: barHover });
        }
    };

    toggleEditGoalDialog = (year) => {
        this.setState({ editGoalDialogOpen: (this.state.editGoalDialogOpen) ? false : year });
    }

    setEditHPRow = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        if (!item.historical) {
            this.toggleEditGoalDialog(item.year);
        } else {
            this.setState({ editingHPRow: item, expandMobile: null });
        }
    }

    updateHPField = (e, item, field) => {
        let hp = [...this.state.historicalProgress];

        hp.forEach(h => {
            if (h.id === item.id) {
                h[field] = (e.target) ? Number(e.target.value) : e.floatValue;
            }
        });

        this.setState({ historicalProgress: hp });
    }

    saveHPRow = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        if (item.endValue === '' || item.ytdIncome === '' || item.ytdRetirement === '') {
            alert('Please enter values for all 3 fields');
            return false;
        }

        this.props.saveRPGoals(this.props.account.user, item, this.state.rpId);

        this.setState({ editingHPRow: {}, addingHPRow: {}, expandMobile: null });
    }

    addHPRow = (e) => {
        e.preventDefault();

        let hp = [...this.state.historicalProgress];

        if (hp[0].id === -1) {
            return;
        }

        let newRow = { id: -1, year: '', ytdIncome: '', ytdRetirement: '', endValue: '', historical: true };
        hp.splice(0, 0, newRow);
        this.setState({ addingHPRow: newRow, historicalProgress: hp });
    }

    deleteHPRow = (e, item) => {
        this.state.historicalProgress.forEach((hp, i) => {
            if (hp.id === item.id) {
                this.state.historicalProgress.splice(i, 1);
            }
        });
        this.setState({ historicalProgress: this.state.historicalProgress, editingHPRow: {} });
        this.props.deleteHP(this.props.account.user, item.id);
    }

    expandMobile = (datum) => {
        if (Object.keys(this.state.editingHPRow).length === 0 && Object.keys(this.state.addingHPRow).length === 0) {
            this.setState({
                expandMobile: (this.state.expandMobile?.year === datum.year) ? null : datum,
                editingHPRow: {},
                addingHPRow: {},
            });
        }
    }

    render() {
        const { loaded, rpLoaded, assetsLoaded, incomeLoaded, goalsLoaded, hpLoaded, updatedIncomeOrExpenses, lastMod, lastModIncome, lastModRetirement, edit, step, mobile, expandMobile, overviewVideoOpen, myInfoVideoOpen, goalsOutlookVideoOpen, summaryVideoOpen } = this.state;
        const { myDetailsCollapsed, assetsIncomeCollapsed, addPartnerCollapsed, goalsProgressCollapsed, achievementsGoalsCollapsed, futureOutlookCollapsed, nestEggNeedsCollapsed, historicalProgressCollapsed } = this.state;
        const { myDetailsFields, partnerAdded, assets, income, experimentationIncome, goalsOutlookFields, years, annualIncome, annualExpenses, selectedIncome, desiredIncomePercent, historicalProgress } = this.state
        const { myDetailsLMOpen, assetsIncomeLMOpen, goalsProgressLMOpen, achievementsGoalsLMOpen, futureOutlookLMOpen, nestEggNeedsLMOpen, historicalProgressLMOpen, rpGoals } = this.state;
        const { introDialogOpen, alDialogOpen, editHistoricalDialogOpen, gotoALRemember, editHistoricalRemember, startValueDialogOpen, editGoalDialogOpen, barHover, editingHPRow, addingHPRow } = this.state;
        const { account, financialPriorities, classes } = this.props;

        if (!loaded || (loaded && account.loaded && !rpLoaded)) {
            return (<Loading />);
        } else {
            const currentStepIndex = findIndex(STEPS, { id: step });

            // Any API errors are populated here //
            let alert = null;
            if (edit.success) {
                alert = { severity: 'success', msg: edit.success.msg };
            } else if (edit && edit.error && edit.error.body) {
                alert = { severity: 'error', msg: edit.error.body.msg };
            }

            // My Info page vars //
            let fieldsToCheck = Object.assign({}, myDetailsFields);
            if (!partnerAdded) {
                delete fieldsToCheck.partnerFirstName;
                delete fieldsToCheck.partnerBirthday;
                delete fieldsToCheck.partnerGoalRetirementAge;
            }
            const fieldStatus = {
                'myInfo': {
                    complete: Object.keys(fieldsToCheck).filter(k => myDetailsFields[k]).length,
                    total: Object.keys(fieldsToCheck).length,
                },
                'goalsOutlook': {
                    complete: Object.keys(goalsOutlookFields).filter(k => !EXCLUDE_FIELDS.includes(k) && !!goalsOutlookFields[k]).length,
                    total: Object.keys(goalsOutlookFields).filter(k => !EXCLUDE_FIELDS.includes(k)).length,
                },
            };
            const currentYear = (goalsOutlookFields.year === THIS_YEAR);
            const PartnerIcon = addPartnerCollapsed ? ChevronRightIcon : ExpandMoreIcon;

            // Step badge vars //
            const badgeCount = {
                'myInfo': Object.keys(fieldsToCheck).reduce((acc, curr) => acc + (Number(fieldNeedsUpdating(curr, fieldsToCheck[curr], lastMod)) || 0), 0),
                'goalsOutlook': Object.keys(goalsOutlookFields).filter(k => !EXCLUDE_FIELDS.includes(k))
                    .reduce((acc, curr) => acc + (Number(fieldNeedsUpdating(curr, goalsOutlookFields[curr], lastMod, rpGoals)) || 0), 0),
            };

            // Goals & Outlook conditional text depending on if this year is selected or not //
            const conditionalText = {
                h3Goals: (currentYear) ? 'For the Year' : 'for the year',
                h4Goals: (currentYear) ?
                    'Set three goals (minimum, target, & stretch) based on what you think you’ll be able to save this year. You can adjust these later.' :
                    'Below are the goals you set for the selected year.',
                h3Track: (currentYear) ? 'Year-to-Date' : goalsOutlookFields.year,
                h4Track: (currentYear) ?
                    'Track your progress by entering your income & retirement contributions for the year-to-date. Update these once a month or as needed.' :
                    'Below are your income & retirement contributions for the selected year. Your retirement accounts’ value is from the last day of the selected year.',
                ytdLabel: (currentYear) ? 'YTD' : '',
                valueLabel: (currentYear) ? 'Current' : 'End of Year',
            };

            // Achievements vs. Goals vars //
            const savingsRates = {
                actual: (goalsOutlookFields?.ytdIncome > 0) ? (goalsOutlookFields.ytdRetirement / goalsOutlookFields.ytdIncome) * 100 : 0,
                minimum: (myDetailsFields?.grossAnnualIncome) ? Math.round((goalsOutlookFields.minimum / myDetailsFields.grossAnnualIncome) * 100) : 0,
                target: (myDetailsFields?.grossAnnualIncome) ? Math.round((goalsOutlookFields.target / myDetailsFields.grossAnnualIncome) * 100) : 0,
                stretch: (myDetailsFields?.grossAnnualIncome) ? Math.round((goalsOutlookFields.stretch / myDetailsFields.grossAnnualIncome) * 100) : 0,
            };
            let pieData = [{ name: 'Minimum', label: savingsRates.minimum, value: (savingsRates.stretch) ? (savingsRates.minimum / savingsRates.stretch) * 100 : 0}];
            pieData.push({ name: 'Target', label: savingsRates.target, value: (savingsRates.stretch) ? ((savingsRates.target / savingsRates.stretch) * 100) - pieData[0].value : 0 });
            pieData.push({ name: 'Stretch', label: savingsRates.stretch, value: (savingsRates.stretch) ? 100 - ((savingsRates.target / savingsRates.stretch) * 100) : 0 });
            const arrowRotate = Math.min(scale(savingsRates.actual, 0, savingsRates.stretch, 0, 180), 180);
            const arrowTransform = (mobile) ? `rotate(${arrowRotate}deg) scale(0.95)` : `rotate(${arrowRotate}deg)`;
            const arrowLeft = (mobile) ? window.innerWidth/2 - 10 : null;
            const barWidth = Math.max(1.1 * goalsOutlookFields.stretch, goalsOutlookFields.ytdRetirement) || 1;
            const progress = (goalsOutlookFields.ytdRetirement / barWidth) * 100;
            const goalWidths = {
                'minimum': (goalsOutlookFields.minimum / barWidth) * 100,
                'target': (goalsOutlookFields.target / barWidth) * 100,
                'stretch': (goalsOutlookFields.stretch / barWidth) * 100,
            };
            const pieMargin = (mobile) ? { top: 0, right: 20, bottom: 0, left: 0 } : { top: 0, right: 0, bottom: 0, left: 0 };

            // Future Outlook vars //
            const futureOutlook = this.formatFutureOutlookData(this.state);
            const nestEggNeeds = this.calculateNestEggNeeds(this.state);

            // Summary vars //
            let legendPayload = [];
            if (historicalProgress.length > 0) {
                const legendData = (Object.keys(barHover).length) ? barHover : historicalProgress[historicalProgress.length - 2];
                legendPayload.push({ id: 'value', label: 'Value of Retirement Accounts', value: legendData.endValue });
                legendPayload.push({ id: 'contributions', label: 'Contributions', value: legendData.ytdRetirement });
            }
            const hpChartData = historicalProgress.filter(hp => !hp.isAverage).map(hp => {
                return { year: hp.year, endValue: Number(hp.endValue), ytdRetirement: Number(hp.ytdRetirement) };
            });
            const hpChartMargin = (mobile) ? { top: 40, right: 0, bottom: 0, left: 0 } : { top: 40, right: 30, bottom: 0, left: 30 };
            const lastHP = historicalProgress[historicalProgress.length -1] || {};

            return (
                <div className="wrapper">
                    {(account.user) ?
                        <NavBarLoggedIn
                            account={account}
                            fp={financialPriorities}
                            rp={badgeCount.myInfo + badgeCount.goalsOutlook}
                            onRP={true} /> :
                        <NavBar />
                    }

                    <div className="main-bg" />

                    <div className="main retirement-planner">
                        <div className="rp-header">
                            <h1>Retirement Planner</h1>

                            <h2 className="sub step-nav">
                                {STEPS.map((s, i, arr) => {
                                    return (
                                        <span key={i}>
                                            <a href="noop" className={cn(step === s.id && 'selected')} onClick={(e) => this.setStep(e, s.id)}>{s.name}</a>
                                            {i !== arr.length-1 && <span> / </span>}
                                        </span>
                                    );
                                })}
                            </h2>

                            <h3>Watch the one minute <a href="noop" onClick={(e) => this.toggleDialog(e, 'overviewVideoOpen')}>Retirement Planner Overview video</a>.</h3>

                            <div className="help" onClick={(e) => this.toggleDialog(e, `${step}VideoOpen`)}>
                                <span>{STEPS[currentStepIndex].name + ' Help'}</span>
                                <img src={PlayCircleIcon} alt="Play video icon, right-pointing triangle in a circle, green" />
                            </div>
                        </div>

                        <div className="steps">
                            {STEPS.map((s, i, arr) => {
                                return (
                                    <div key={i} className={cn('step', step === s.id && 'selected')} onClick={(e) => this.setStep(e, s.id)}>
                                        {badgeCount[s.id] > 0 && <div className="badge">{badgeCount[s.id]}</div>}
                                        <div className="step-metadata">
                                            <label>{`Step ${i+1}`}</label>
                                            <h3>{s.name}</h3>
                                            <p>{s.description}</p>
                                            {i !== arr.length-1 && !mobile && <TaskProgress progress={Math.round((fieldStatus[s.id].complete / fieldStatus[s.id].total) * 100)} />}
                                            {i === arr.length-1 && updatedIncomeOrExpenses &&
                                                <div className="updated-income-or-expenses">
                                                    <span className="needs-updating" />
                                                    <label>Changes you made to your income or expenses have impacted your Nest Egg Needs. Take a look below.</label>
                                                </div>
                                            }
                                        </div>
                                        <img src={s.image} alt={s.imageAlt} />
                                        {i !== arr.length-1 && mobile &&
                                            <div className="mobile-progress">
                                                <TaskProgress progress={Math.round((fieldStatus[s.id].complete / fieldStatus[s.id].total) * 100)} />
                                            </div>
                                        }
                                        {i === arr.length-1 && mobile && updatedIncomeOrExpenses &&
                                            <div className="updated-income-or-expenses">
                                                <span className="needs-updating" />
                                                <label>Changes you made to your income or expenses have impacted your Nest Egg Needs. Take a look below.</label>
                                            </div>
                                        }
                                    </div>
                                );
                            })}
                        </div>

                        {mobile &&
                            <div className="arrows">
                                <img src={(step === 'myInfo') ? ArrowNavDisabled : ArrowNav} className="arrow-nav back" alt="Previous tier icon, left arrow in grey circle, translucent" onClick={() => this.previousStep(step)} />
                                <img src={(step === 'summary') ? ArrowNavDisabled : ArrowNav} className="arrow-nav" alt="Next tier icon, right arrow in grey circle, translucent" onClick={() => this.nextStep(step)} />
                            </div>
                        }

                        {step === 'myInfo' &&
                            <>
                                <section className="my-details">
                                    <header className={cn('border', myDetailsCollapsed && 'collapsed')}>
                                        <h3 className="header">
                                            My Details
                                            {myDetailsCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'myDetails')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'myDetails')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'myDetailsLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {myDetailsLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {myDetailsLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>Your "Gross Annual Household Income" is the amount you make each year before taxes
                                                or other deductions are taken out. If you're including a partner, combine your incomes together.</p>

                                                <p>If you're not sure what your gross income is, look at your most recent paystub. Multiply the
                                                Gross amount by the number of paychecks you get each year (usually 26 if you're paid every other
                                                week, or 24 if you're paid twice a month). If you're paid in cash or earn tips, factor those in
                                                too. <a href="https://www.moneyswell.com/moneyswell-blog/how-to-find-out-your-gross-annual-income/" target="_blank" rel="noreferrer">See some examples.</a></p>

                                                <p>"Annual Household Expenses" is everything you spend. An estimated amount is fine.
                                                <a href="https://www.moneyswell.com/moneyswell-blog/how-to-estimate-your-annual-expenses/" target="_blank" rel="noreferrer">
                                                    &nbsp;See some examples for easy ways to estimate your annual expenditures.
                                                </a></p>
                                            </div>

                                            <img src={LearnMore1} className="illustration" alt="Illustration, woman at laptop, headphones, text fields floating around her" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'myDetailsLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'myDetailsLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!myDetailsCollapsed &&
                                        <>
                                            <div className="subsection">
                                                <h4>Provide your current details. You can update these later.</h4>
                                                <form name="my-details">
                                                    <fieldset>
                                                        <label>First Name</label>
                                                        {fieldNeedsUpdating('firstName', myDetailsFields.firstName, lastMod) && <span className="needs-updating" />}
                                                        <input type="text" placeholder="First Name"
                                                            value={myDetailsFields.firstName}
                                                            onChange={(e) => this.updateField(e, 'myDetailsFields', 'firstName')}
                                                            onBlur={this.saveRetirementPlanner} />
                                                    </fieldset>
                                                    <fieldset>
                                                        <label>
                                                            Gross Annual Household Income
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step1.myDetails.income} />
                                                        </label>
                                                        {fieldNeedsUpdating('grossAnnualIncome', myDetailsFields.grossAnnualIncome, lastMod) && <span className="needs-updating" />}
                                                        <NumberFormat
                                                            className="number"
                                                            value={myDetailsFields.grossAnnualIncome}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            placeholder={'$0.00'}
                                                            onValueChange={(e) => this.updateField(e, 'myDetailsFields', 'grossAnnualIncome')}
                                                            onBlur={this.saveRetirementPlanner}
                                                        />
                                                    </fieldset>
                                                    <fieldset>
                                                        <label>Birthday (Month/Day/Year)</label>
                                                        {fieldNeedsUpdating('birthday', myDetailsFields.birthday, lastMod) && <span className="needs-updating" />}
                                                        <input type="date"
                                                            max={moment().format(DATE_FORMAT_INTL)}
                                                            min={moment().subtract(100, 'years').format(DATE_FORMAT_INTL)}
                                                            value={myDetailsFields.birthday}
                                                            onChange={(e) => this.updateField(e, 'myDetailsFields', 'birthday')}
                                                            onBlur={this.saveRetirementPlanner} />
                                                    </fieldset>
                                                    <fieldset>
                                                        <label>
                                                            Annual Household Expenses
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step1.myDetails.expenses} />
                                                        </label>
                                                        {fieldNeedsUpdating('annualExpenses', myDetailsFields.annualExpenses, lastMod) && <span className="needs-updating" />}
                                                        <NumberFormat
                                                            className="number"
                                                            value={myDetailsFields.annualExpenses}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            placeholder={'$0.00'}
                                                            onValueChange={(e) => this.updateField(e, 'myDetailsFields', 'annualExpenses')}
                                                            onBlur={this.saveRetirementPlanner}
                                                        />
                                                    </fieldset>
                                                    <fieldset>
                                                        <label>Goal Retirement Age</label>
                                                        {fieldNeedsUpdating('goalRetirementAge', myDetailsFields.goalRetirementAge, lastMod) && <span className="needs-updating" />}
                                                        <input type="number" placeholder="Goal Retirement Age" min="20" max="100"
                                                            value={myDetailsFields.goalRetirementAge}
                                                            onChange={(e) => this.updateField(e, 'myDetailsFields', 'goalRetirementAge')}
                                                            onBlur={this.saveRetirementPlanner} />
                                                    </fieldset>
                                                </form>
                                            </div>
                                            <div className="subsection alt">
                                                <h3>
                                                    Partner Details
                                                    {partnerAdded && <PartnerIcon onClick={(e) => this.toggleSection(e, 'addPartner')} />}
                                                </h3>
                                                {!partnerAdded &&
                                                    <a href="noop" className="lm-link add" onClick={(e) => this.addPartner(e)}>
                                                        Add
                                                        <img src={AddIcon} alt="Add icon, plus sign in circle, green" />
                                                    </a>
                                                }
                                                {partnerAdded && !addPartnerCollapsed &&
                                                    <>
                                                        <h4>Add your partner's details. Your partner's income should be included in the previous section under "Household Income."</h4>

                                                        <form name="partner-details" className="narrow">
                                                            <fieldset>
                                                                <label>First Name</label>
                                                                {fieldNeedsUpdating('partnerFirstName', myDetailsFields.partnerFirstName, lastMod) && <span className="needs-updating" />}
                                                                <input type="text" placeholder="First Name"
                                                                    value={myDetailsFields.partnerFirstName}
                                                                    onChange={(e) => this.updateField(e, 'myDetailsFields', 'partnerFirstName')}
                                                                    onBlur={this.saveRetirementPlanner} />
                                                            </fieldset>
                                                            <fieldset>
                                                                <label>Birthday (Month/Day/Year)</label>
                                                                {fieldNeedsUpdating('partnerBirthday', myDetailsFields.partnerBirthday, lastMod) && <span className="needs-updating" />}
                                                                <input type="date"
                                                                    max={moment().format(DATE_FORMAT_INTL)}
                                                                    min={moment().subtract(100, 'years').format(DATE_FORMAT_INTL)}
                                                                    value={moment(myDetailsFields.partnerBirthday).format(DATE_FORMAT_INTL)}
                                                                    onChange={(e) => this.updateField(e, 'myDetailsFields', 'partnerBirthday')}
                                                                    onBlur={this.saveRetirementPlanner} />
                                                            </fieldset>
                                                            <fieldset>
                                                                <label>Goal Retirement Age</label>
                                                                {fieldNeedsUpdating('partnerGoalRetirementAge', myDetailsFields.partnerGoalRetirementAge, lastMod) && <span className="needs-updating" />}
                                                                <input type="number" placeholder="Goal Retirement Age" min="20" max="100"
                                                                    value={myDetailsFields.partnerGoalRetirementAge}
                                                                    onChange={(e) => this.updateField(e, 'myDetailsFields', 'partnerGoalRetirementAge')}
                                                                    onBlur={this.saveRetirementPlanner} />
                                                            </fieldset>
                                                        </form>

                                                        <a href="noop" className="lm-link" onClick={(e) => this.removePartner(e)}>
                                                            Remove Partner
                                                            <img src={RemoveIcon} alt="Remove icon, minus sign in circle, green" />
                                                        </a>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </section>

                                <section className="assets-income">
                                    <header>
                                        <h3 className="header">
                                            Assets & Income
                                            {assetsIncomeCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'assetsIncome')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'assetsIncome')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'assetsIncomeLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {assetsIncomeLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {assetsIncomeLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>Retirement Accounts gives MoneySwell an understanding of how much you've already saved. This
                                                is used to help project when you may hit your retirement savings goal (also known as your "Nest Egg").</p>

                                                <p>If you're not logged in, manually add your retirement accounts. If you're logged in, accounts
                                                labelled "Retirement" from the Assets & Liabilities tool will show here.</p>

                                                <p>Additional Monthly Retirement Income estimates possible income that is separate from your
                                                Nest egg, and calculated with today's dollars (in other words, the income dollar amount you would
                                                receive if you were retiring today). Common examples might be Social Security, rental income etc.</p>
                                            </div>

                                            <img src={LearnMore2} className="illustration" alt="Illustration, woman with red hair looking at floating text fields and charts" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'assetsIncomeLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'assetsIncomeLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!assetsIncomeCollapsed &&
                                        <>
                                            <div className="subsection alt">
                                                <a id="retirementAccounts" href="#retirementAccounts"><h3>
                                                    Retirement Accounts
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step1.assetsIncome.retirement} />
                                                </h3></a>
                                                <h4>The accounts listed below have the account type "Retirement" from
                                                the&nbsp;<a href="/assetsLiabilities">Assets & Liabilities</a>&nbsp;tool.</h4>

                                                <h4>If an account isn't listed that should be, update its account label. Conversely,
                                                if an account IS listed and it shouldn't be, change the label to something other
                                                than Retirement account, for example "Other Investment."</h4>

                                                {!assetsLoaded && <Loading />}

                                                {assetsLoaded && this.props.account.user !== null &&
                                                    <>
                                                        {assets.length === 0 &&
                                                            <div className="no-assets">
                                                                <img src={ErrorIcon} alt="Error icon, red, exclamation mark" />
                                                                <span className="msg">You haven't added retirement accounts to your Assets & Liabilities tool.</span>
                                                                <a href="/assetsLiabilities">Add some now ></a>
                                                                <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step1.assetsIncome.noAccounts} />
                                                            </div>
                                                        }

                                                        {assets.length > 0 &&
                                                            <ul className="assets-list">
                                                                {assets.map((a, i) => {
                                                                    return (
                                                                        <li className="asset" key={i}>
                                                                            <label htmlFor={a.name}>{a.name}</label>
                                                                            <span className="value">{numeral(a.value).format(NUM_FORMAT)}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        }

                                                        <a href="/assetsLiabilities" className="lm-link">
                                                            Add Account
                                                            <img src={AddIcon} alt="Add icon, plus sign in circle, green" />
                                                        </a>
                                                    </>
                                                }

                                                {assetsLoaded && this.props.account.user === null &&
                                                    <CashflowTable
                                                        type="assets"
                                                        fields={[
                                                            { id: 'account', label: 'Account Name', placeholder: 'Account Name' },
                                                            { id: 'amount', label: 'Current Value', placeholder: '$0.00' },
                                                       ]}
                                                        rows={assets}
                                                        removeRow={this.removeRow}
                                                        addRow={this.addRow}
                                                        editRow={this.editRow}
                                                        mobile={mobile}
                                                    />
                                                }
                                            </div>

                                            <div className="subsection">
                                                <h3>
                                                    Additional Monthly Retirement Income
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step1.assetsIncome.income} />
                                                </h3>
                                                <h4>
                                                    Add retirement income that won’t come from your investment accounts. When
                                                    included, these reduce the size your nest egg needs to be.
                                                </h4>

                                                {!incomeLoaded && <Loading />}

                                                {incomeLoaded &&
                                                    <CashflowTable
                                                        type="income"
                                                        fields={[
                                                            { id: 'source', label: 'Income Source', placeholder: 'Income Source' },
                                                            { id: 'amount', label: 'Monthly Amount', placeholder: '$0.00 (monthly amount)' },
                                                       ]}
                                                        rows={income}
                                                        removeRow={this.removeRow}
                                                        addRow={this.addRow}
                                                        editRow={this.editRow}
                                                        onBlur={this.saveIncome}
                                                        mobile={mobile}
                                                    />
                                                }
                                            </div>
                                        </>
                                    }
                                </section>

                                <div className="nav-buttons">
                                    <button className="primary larger" onClick={(e) => this.setStep(e, 'goalsOutlook')}>{'To Goals & Outlook >'}</button>
                                </div>
                            </>
                        }

                        {step === 'goalsOutlook' && !goalsLoaded && <Loading />}

                        {step === 'goalsOutlook' && goalsLoaded &&
                            <>
                                <section className="goals-progress">
                                    <header>
                                        <h3 className="header">
                                            Goals & Progress
                                            {goalsProgressCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'goalsProgress')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'goalsProgress')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'goalsProgressLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {goalsProgressLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {goalsProgressLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>Set three retirement saving goals for the year. "Minimum" is the amount you are very confident
                                                you will hit this year. "Target" is the amount you expect to hit under normal circumstances. "Stretch"
                                                represents your best case scenario.</p>

                                                <p>Don't worry about whether the savings amounts are done with pre or post-tax dollars. For ballpark
                                                planning it's fine to just consider the dollar amount saved.</p>

                                                <p>For Income, use your gross income (before taxes & deductions are taken out). For Retirement
                                                Contributions, get this number from your investment company's website. The entry for "Value of
                                                Retirement Accounts" is pulled from details entered in Step 1.</p>
                                            </div>

                                            <img src={LearnMore3} className="illustration" alt="Illustration, man watering 3 plants with a watering can" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'goalsProgressLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'goalsProgressLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!goalsProgressCollapsed &&
                                        <>
                                            <div className="subsection alt">
                                                <a id="goalsAndProgress" href="#goalsAndProgress"><h3>
                                                    Set Your Retirement Contribution Goals {conditionalText.h3Goals}
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.goalsProgress.goals} />
                                                </h3></a>
                                                <h4>{conditionalText.h4Goals}</h4>

                                                <div className="goals-outlook-fields">
                                                    <div className="field">
                                                        <label>Year <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.goalsProgress.year} /></label>
                                                        <select id="goalYears" value={goalsOutlookFields.year} onChange={this.loadGoalsForYear}>
                                                            <option value="" disabled>Year</option>
                                                            {years.map((y, i) => {
                                                                return (<option value={y.year} key={i}>{y.year}</option>);
                                                            })}
                                                        </select>
                                                    </div>

                                                    {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                                        return (
                                                            <div className="field" key={i}>
                                                                <label className={gt.id}>{gt.label}</label>
                                                                <div className="field-extra">
                                                                    {fieldNeedsUpdating(gt.id, goalsOutlookFields[gt.id], lastMod) && <span className="needs-updating" />}
                                                                    <NumberFormat
                                                                        className="number"
                                                                        value={goalsOutlookFields[gt.id]}
                                                                        thousandSeparator={true}
                                                                        prefix={'$'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        placeholder={'$0.00'}
                                                                        onValueChange={(e) => this.updateField(e, 'goalsOutlookFields', gt.id)}
                                                                        onBlur={this.saveRPGoals}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>

                                            <div className="subsection">
                                                <h3>Track Your {conditionalText.h3Track} Numbers</h3>
                                                <h4>{conditionalText.h4Track}</h4>

                                                <div className="goals-outlook-fields">
                                                    <div className="field">
                                                        <label>
                                                            {conditionalText.ytdLabel} Household Income
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.goalsProgress.income} />
                                                        </label>
                                                        <div className="field-extra">
                                                            {fieldNeedsUpdating('ytdIncome', goalsOutlookFields.ytdIncome, lastMod, lastModIncome) && <span className="needs-updating" />}
                                                            <NumberFormat
                                                                className="number"
                                                                value={goalsOutlookFields.ytdIncome}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                placeholder={'$0.00'}
                                                                onValueChange={(e) => this.updateField(e, 'goalsOutlookFields', 'ytdIncome')}
                                                                onBlur={this.saveRPGoals}
                                                                name="ytdIncome"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <label>
                                                            {conditionalText.ytdLabel} Retirement Contribution
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.goalsProgress.retirement} />
                                                        </label>
                                                        <div className="field-extra">
                                                            {fieldNeedsUpdating('ytdRetirement', goalsOutlookFields.ytdRetirement, lastMod, lastModRetirement) && <span className="needs-updating" />}
                                                            <NumberFormat
                                                                className="number"
                                                                value={goalsOutlookFields.ytdRetirement}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                placeholder={'$0.00'}
                                                                onValueChange={(e) => this.updateField(e, 'goalsOutlookFields', 'ytdRetirement')}
                                                                onBlur={this.saveRPGoals}
                                                                name="ytdRetirement"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <label>{conditionalText.valueLabel} Value of Retirement Accounts</label>
                                                        <div className="field-extra value-retirement-accounts">
                                                            <NumberFormat
                                                                className="number disabled"
                                                                value={goalsOutlookFields.ytdValue}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                placeholder={'$0.00'}
                                                                disabled={true}
                                                            />
                                                            <a href="noop" onClick={this.updateValues}>Update Values</a> &nbsp; or &nbsp;
                                                            <a href="noop" onClick={(e) => this.setStep(e, 'myInfo', 'retirementAccounts')}>View Accounts</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </section>

                                <section className="achievements-goals">
                                <header className={cn('border', achievementsGoalsCollapsed && 'collapsed')}>
                                        <h3 className="header">
                                            {goalsOutlookFields.year}, Achievements vs. Goals
                                            {achievementsGoalsCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'achievementsGoals')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'achievementsGoals')} />
                                            }
                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.achievementsGoals.goals} />
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'achievementsGoalsLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {achievementsGoalsLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {achievementsGoalsLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>These graphs are calculated from the values entered above, or in Step 1. For
                                                example, your Savings Rate goals are based on your Minimum, Target, and Stretch
                                                amounts, divided by the annual household income entered in Step 1.</p>

                                                <p>Your actual savings rate (the arrow) is based on your actual retirement
                                                contributions divided by your actual income.</p>

                                                <p>Experts generally recommend saving (and investing!) between 10 - 15% of your
                                                gross income annually. But if you want to retire early, have a more affluent
                                                retirement, or are getting a late start, a higher savings rate is advised. If
                                                these targets are challenging, just remember that saving any amount can make a
                                                big difference in the long run.</p>
                                            </div>

                                            <img src={LearnMore4} className="illustration" alt="Illustration, woman climbing bar chart columns like steps" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'achievementsGoalsLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'achievementsGoalsLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!achievementsGoalsCollapsed &&
                                        <>
                                            <div className="subsection achievements-goals">
                                                <h4>
                                                    Below are your achievements for the year compared to the goals you set above.
                                                    <a href="noop" className="right" onClick={(e) => this.setStep(e, 'summary')}>View Historical Achievements ></a>
                                                </h4>

                                                <div className="charts">
                                                    <div className="savings-rate">
                                                        <h5><span className="actual">Savings Rate</span> vs. Goals</h5>

                                                        <PieChart width={(mobile) ? window.innerWidth : 350} height={250} margin={pieMargin}>
                                                            <Pie
                                                                dataKey="value"
                                                                startAngle={180}
                                                                endAngle={0}
                                                                data={pieData}
                                                                cx={'50%'}
                                                                cy={'65%'}
                                                                outerRadius={135}
                                                                innerRadius={75}
                                                                label={savingsRatePieLabel}
                                                                labelLine={false}
                                                                isAnimationActive={false}
                                                            >
                                                                {pieData.map((entry, i) => <Cell key={i} fill={GOAL_TYPES[i].fill} />)}
                                                            </Pie>
                                                        </PieChart>

                                                        <label className="actual">{`${savingsRates.actual.toFixed(1)}%`}</label>
                                                        <img src={ArrowIcon} className="arrow" alt="Green arrow" style={{ transform: arrowTransform, left: arrowLeft }} />
                                                    </div>

                                                    <div className="contributions">
                                                        <h5><span className="actual">Contributions</span> vs. Goals</h5>

                                                        <div className="bar">
                                                            <div className="pace" style={{ width: `${Math.min(Math.max(progress, 1), 100)}%` }} />
                                                        </div>
                                                        <div className="progress-label" style={{ width: `${progress}%` }}>
                                                            {numeral(goalsOutlookFields.ytdRetirement).format(NUM_FORMAT)}
                                                        </div>

                                                        {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                                            return (
                                                                <div className={cn('goal-line', gt.id, i % 2 === 0 && 'even')}
                                                                    key={i} style={{ width: `${100 - goalWidths[gt.id]}%`, left: `${goalWidths[gt.id]}%` }}>
                                                                    <span>{numeral(goalsOutlookFields[gt.id]).format(NUM_FORMAT)}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="subsection legend">
                                                {GOAL_TYPES.map((gt, i) => {
                                                    return (
                                                        <div className="legend-item" key={i}>
                                                            <div className={cn('circle', gt.id)} />
                                                            <label className={gt.id}>{gt.legendLabel}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    }
                                </section>

                                <section className="future-outlook">
                                    <header>
                                        <h3 className="header">
                                            Future Outlook
                                            {futureOutlookCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'futureOutlook')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'futureOutlook')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'futureOutlookLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {futureOutlookLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {futureOutlookLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>Scroll below to see when you might hit the lowest, middle, and highest estimated
                                                nest egg needs based on the three savings goals you set above.</p>

                                                <p>Remember: Your savings amounts will change over time. When you're 25, your savings
                                                goals will likely be lower than when you're 35. Similarly, since your nest egg needs
                                                estimates are affected by income and spending, these amounts will also change over
                                                time.</p>

                                                <p>But eventually, your objective is to get your yearly savings goals to a place where
                                                you're projected to achieve at least your Lowest Nest Egg Need number by your goal
                                                retirement age.&nbsp;
                                                <a href="https://www.moneyswell.com/help-articles/how-to-project-your-future-outlook-for-retirement-planning/" target="_blank" rel="noreferrer">
                                                    Learn more and see examples here.
                                                </a>
                                                &nbsp;Learn about&nbsp;
                                                <a href="https://www.moneyswell.com/action-plan/retire-by-your-goal-retirement-age/#toc_Notes_on_Estimated_Interest_Rate_Accounting_for_Inflation" target="_blank" rel="noreferrer">
                                                    Inflation & Rate of Return here.
                                                </a></p>
                                            </div>

                                            <img src={LearnMore5} className="illustration" alt="Illustration, woman looking at an iPad, fields and charts float behind her" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'futureOutlookLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'futureOutlookLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!futureOutlookCollapsed &&
                                        <>
                                            <div className="subsection alt">
                                                <h3>
                                                    How big could my nest egg be in the future?
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.futureOutlook.nestEgg} />
                                                </h3>
                                                <h4>See how much your investments would be worth if you consistently saved at this year's
                                                minimum, target, or stretch savings' goals and you earned a consistent rate of return.</h4>

                                                <div className="goals-outlook-fields">
                                                    <div className="field">
                                                        <label>
                                                            Starting Value
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.futureOutlook.startingValue} />
                                                        </label>
                                                        <div className="field-extra">
                                                            <NumberFormat
                                                                className="number disabled bold"
                                                                value={goalsOutlookFields.startValue}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                placeholder={'$0.00'}
                                                                disabled={true}
                                                            />
                                                            <a href="noop" onClick={this.toggleEditValueDialog}>Edit</a>
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <label>
                                                            Expected Rate of Return
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.futureOutlook.expectedROR} />
                                                        </label>
                                                        <select id="expectedROR"
                                                            value={goalsOutlookFields.expectedROR}
                                                            onChange={(e) => this.updateField(e, 'goalsOutlookFields', 'expectedROR')}
                                                            onBlur={this.saveRPGoals}>
                                                                <option value="">0%</option>
                                                                {[...Array(10).keys()].map((ror, i) => {
                                                                    return (<option value={ror+1} key={i}>{`${ror+1}%`}</option>);
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>

                                                {!mobile &&
                                                    <table className="retirement-planner-table savings-goals">
                                                        <thead>
                                                            <tr>
                                                                <td className="savings-goals bold"><label>Savings Goals</label></td>
                                                                {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                                                    return (<td key={i} className={gt.id}>{gt.label}</td>);
                                                                })}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td className="edit"><a href="#goalsAndProgress">Edit</a></td>
                                                                {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                                                    return (
                                                                        <td key={i} className={gt.id}>
                                                                            <NumberFormat
                                                                                className={cn('number disabled bold', gt.id)}
                                                                                value={goalsOutlookFields[gt.id]}
                                                                                thousandSeparator={true}
                                                                                prefix={'$'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                placeholder={'$0.00'}
                                                                                disabled={true}
                                                                            />
                                                                        </td>);
                                                                })}
                                                                <td className="dummy">&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }

                                                {mobile &&
                                                    <div className="savings-goals">
                                                        <label>Savings Goals</label>
                                                        <a href="#goalsAndProgress">Edit</a>

                                                        <div className="goal-types">
                                                            {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                                                return (
                                                                    <div key={i} className="goal-type">
                                                                        <label className={gt.id}>{gt.label}</label>
                                                                        <NumberFormat
                                                                            className={cn('number disabled bold', gt.id)}
                                                                            value={goalsOutlookFields[gt.id]}
                                                                            thousandSeparator={true}
                                                                            prefix={'$'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            placeholder={'$0.00'}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="subsection">
                                                {mobile &&
                                                    <>
                                                        <div className="goals-header">
                                                            <div className="goal low"><img src={LowestIcon} alt="Lowest icon" /> Lowest Nest Egg Need</div>
                                                            <div className="goal medium"><img src={MediumIcon} alt="Medium icon" /> Medium Nest Egg Need</div>
                                                            <div className="goal high"><img src={HighestIcon} alt="Highest   icon" /> Highest Nest Egg Need</div>
                                                        </div>

                                                        <div className="mobile-table">
                                                            <div className="header">
                                                                <div className="header-col" style={{width: '24%'}}>Year</div>
                                                                <div className="header-col" style={{width: '24%'}}>Minimum Goal</div>
                                                                <div className="header-col" style={{width: '24%'}}>Target Goal</div>
                                                                <div className="header-col" style={{width: '24%'}}>Stretch Goal</div>
                                                            </div>

                                                            <div className="body">
                                                                {futureOutlook.data.map((d, i) => {
                                                                    const fields = ['year', 'minimum', 'target', 'stretch'];
                                                                    const row = futureOutlook.fields.filter(f => fields.includes(f.id)).map((f, j) => {
                                                                        let value = d[f.id];

                                                                        if (GOAL_TYPES.filter(gt => !gt.legendOnly).map(gt => gt.id).includes(f.id)) {
                                                                            value = numeral(d[f.id]).format(NUM_FORMAT_SHORT)

                                                                            let keyMap = { minimum: 'low', target: 'medium', stretch: 'high' };
                                                                            let fieldClass = '';

                                                                            Object.values(keyMap).forEach(v => {
                                                                                if (d[f.id] >= nestEggNeeds[v].value) {
                                                                                    fieldClass = v;
                                                                                }
                                                                            })

                                                                            if (fieldClass !== '') {
                                                                                value = (
                                                                                    <div className={cn('nest-egg', fieldClass)}>{numeral(d[f.id]).format(NUM_FORMAT_SHORT)}</div>
                                                                                );
                                                                            }
                                                                        }

                                                                        return (<div key={j} className={cn('body-col', f.id)} style={{width: '24%'}}>{value}</div>);
                                                                    });

                                                                    let expanded = null;
                                                                    if (expandMobile?.year === d.year) {
                                                                        expanded = (
                                                                            <div className="expand-mobile">
                                                                                <div className="expand-row">
                                                                                    <label>{futureOutlook.fields.filter(f => f.id === 'age')[0].label}: </label>
                                                                                    <span>{d.age}</span>
                                                                                </div>
                                                                                {myDetailsFields.partnerGoalRetirementAge &&
                                                                                    <div className="expand-row">
                                                                                        <label>{futureOutlook.fields.filter(f => f.id === 'partnerAge')[0].label}: </label>
                                                                                        <span>{d.partnerAge}</span>
                                                                                    </div>
                                                                                }
                                                                                <div className="expand-row full">
                                                                                    <label>Description: </label>
                                                                                    <span>{d.description}</span>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <div className="row" key={i} onClick={() => this.expandMobile(d)}>
                                                                            {row}
                                                                            {expanded}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {!mobile &&
                                                    <table className="retirement-planner-table savings-goals">
                                                        <thead>
                                                            <tr>
                                                                <td className="savings-goals">&nbsp;</td>
                                                                <td className="low">Lowest Nest Egg Need</td>
                                                                <td className="medium">Medium Nest Egg Need</td>
                                                                <td className="high">Highest Nest Egg Need</td>
                                                                <td className="dummy">
                                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step2.futureOutlook.needs} />
                                                                </td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td className="savings-goals">&nbsp;</td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="number disabled bold low"
                                                                        value={nestEggNeeds.low.value}
                                                                        thousandSeparator={true}
                                                                        prefix={'$'}
                                                                        suffix={'+'}
                                                                        decimalScale={0}
                                                                        fixedDecimalScale={true}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="number disabled bold medium"
                                                                        value={nestEggNeeds.medium.value}
                                                                        thousandSeparator={true}
                                                                        prefix={'$'}
                                                                        suffix={'+'}
                                                                        decimalScale={0}
                                                                        fixedDecimalScale={true}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumberFormat
                                                                        className="number disabled bold high"
                                                                        value={nestEggNeeds.high.value}
                                                                        thousandSeparator={true}
                                                                        prefix={'$'}
                                                                        suffix={'+'}
                                                                        decimalScale={0}
                                                                        fixedDecimalScale={true}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }

                                                {!mobile &&
                                                    <table className="retirement-planner-table scroll">
                                                        <thead>
                                                            <tr>
                                                                {futureOutlook.fields.map((f,i) => {
                                                                    return (<th key={i} className={f.id}>{f.label}</th>);
                                                                })}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {futureOutlook.data.map((d, i) => {
                                                                const odd = i % 2 !== 0;

                                                                return (
                                                                    <tr key={i} className={cn(odd && 'odd')}>
                                                                        {futureOutlook.fields.map((f,j) => {
                                                                            let value = d[f.id];
                                                                            if (GOAL_TYPES.filter(gt => !gt.legendOnly).map(gt => gt.id).includes(f.id)) {
                                                                                value = numeral(d[f.id]).format(NUM_FORMAT_SHORT)

                                                                                let keyMap = { minimum: 'low', target: 'medium', stretch: 'high' };
                                                                                let fieldClass = '';

                                                                                Object.values(keyMap).forEach(v => {
                                                                                    if (d[f.id] >= nestEggNeeds[v].value) {
                                                                                        fieldClass = v;
                                                                                    }
                                                                                })

                                                                                if (fieldClass !== '') {
                                                                                    value = (
                                                                                        <div className={cn('faux-input disabled bold', fieldClass)}>
                                                                                            {`${numeral(d[f.id]).format(NUM_FORMAT_SHORT)}`}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            }

                                                                            const bold = (f.id === 'age' && myDetailsFields.goalRetirementAge === d.age) ||
                                                                                (f.id === 'partnerAge' && myDetailsFields.partnerGoalRetirementAge === d.partnerAge);
                                                                            const noPartner = !myDetailsFields.partnerGoalRetirementAge;
                                                                            const classes = cn(f.id, bold && 'bold', noPartner && 'no-partner');

                                                                            return (<td key={j} className={classes}>{value}</td>);
                                                                        })}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </>
                                    }
                                </section>

                                <div className="nav-buttons">
                                    <a href="noop" onClick={(e) => this.setStep(e, 'myInfo')}>{'< Back to My Info'}</a>
                                    <button className="primary larger" onClick={(e) => this.setStep(e, 'summary')}>{'To Summary >'}</button>
                                </div>
                            </>
                        }

                        {step === 'summary' &&
                            <>
                                <section className="nest-egg-needs">
                                <header className={cn('border', nestEggNeedsCollapsed && 'collapsed')}>
                                        <h3 className="header">
                                            Nest Egg Needs
                                            {nestEggNeedsCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'nestEggNeeds')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'nestEggNeeds')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'nestEggNeedsLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {nestEggNeedsLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {nestEggNeedsLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>Your "Nest Egg" is the savings bucket you draw from to meet your income needs in
                                                retirement. Retirement income from other sources reduces the size your nest egg needs
                                                to be.</p>

                                                <p>MoneySwell calculates a nest egg need range and breaks it into three estimates:
                                                Low, Medium, & High.
                                                &nbsp;<a href="https://www.moneyswell.com/help-articles/how-to-estimate-your-retirement-nest-egg-needs" target="_blank" rel="noreferrer">
                                                    Read more about how your estimates are calculated here.
                                                </a>&nbsp;</p>

                                                <p>Your nest egg needs numbers are in "today's dollars." This means if you were to retire
                                                today, this is how much you would need. If you track your income, savings, and spending
                                                numbers in MoneySwell, your nest egg needs calculation will change over time. But it will
                                                always show a clear picture of how much you need based on the value of today's dollars.</p>
                                            </div>

                                            <img src={LearnMore6} className="illustration" alt="Illustration, teal blue nest egg, leaning on a stack of coins" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'nestEggNeedsLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'nestEggNeedsLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!nestEggNeedsCollapsed &&
                                        <>
                                            <div className="subsection">
                                                <h3>
                                                    How big does my nest egg need to be?
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.howBig} />
                                                </h3>
                                                <h4 className="normal">
                                                    We estimate your retirement egg needs to be between <span className="low">{numeral(nestEggNeeds.low.value).format(NUM_FORMAT_MM)}</span>
                                                    &nbsp;and <span className="high">{numeral(nestEggNeeds.high.value).format(NUM_FORMAT_MM)}</span>. This may provide the equivalent - i.e.
                                                    in today's dollars - of <span className="low">{numeral(nestEggNeeds.low.value / 25).format(NUM_FORMAT_MM_SHORT)}</span> to
                                                    &nbsp;<span className="high">{numeral(nestEggNeeds.high.value / 25).format(NUM_FORMAT_MM_SHORT)}</span> of annual income from
                                                    your nest egg alone (you may have additional income sources).
                                                </h4>
                                            </div>

                                            <div className="subsection alt eb">
                                                <div className={cn('desired-income-percentages', !mobile && 'half')}>
                                                    <div className="field">
                                                        <label>
                                                            Percentage of Pre-Retired Income Desired
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.percentage} />
                                                        </label>
                                                        <h4 className="normal">For many, expenses go down in retirement. Depending on lifestyle,
                                                        experts typically estimate needs between 70 - 100%.</h4>
                                                        <div className="sub-fields">
                                                            {Object.keys(desiredIncomePercent).map((k, i) => {
                                                                let percentages = [...Array(21)], start = 50;
                                                                percentages.forEach((p, idx) => {
                                                                    percentages[idx] = start;
                                                                    start += 5;
                                                                });

                                                                return (
                                                                    <div className="sub-field third" key={i}>
                                                                        <label className={k}>{k[0].toUpperCase() + k.substring(1)}</label>
                                                                        <select name="income-percentages"
                                                                            value={desiredIncomePercent[k]}
                                                                            onChange={(e) => this.updateField(e, 'desiredIncomePercent', k)}>
                                                                            {percentages.map((p, j) => {
                                                                                return (<option key={j} value={p}>{`${p}%`}</option>);
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3>
                                                    Experimentation Box
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.experimentation} />
                                                </h3>
                                                <h4>See how alternate inputs change how big your nest egg might need to be.</h4>

                                                <div className={cn('goals-outlook-fields', !mobile && 'half')}>
                                                    <div className="field">
                                                        <label>
                                                            Annual Household Income
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.income} />
                                                        </label>
                                                        <NumberFormat
                                                            className="number"
                                                            value={annualIncome}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            placeholder={'$0.00'}
                                                            onValueChange={(e) => this.updateField(e, '', 'annualIncome')}
                                                        />
                                                    </div>
                                                    <div className="field">
                                                        <label>
                                                            Annual Expenses
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.expenses} />
                                                        </label>
                                                        <NumberFormat
                                                            className="number"
                                                            value={annualExpenses}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            placeholder={'$0.00'}
                                                            onValueChange={(e) => this.updateField(e, '', 'annualExpenses')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={cn('goals-outlook-fields', !mobile && 'half')}>
                                                    <div className="field">
                                                        <label>
                                                            Monthly Retirement Income
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.monthly} />
                                                        </label>
                                                        <div className="sub-fields">
                                                            {experimentationIncome.map((inc, i) => {
                                                                return (
                                                                    <div className="sub-field" key={i}>
                                                                        <label className="sub">{inc.source}</label>
                                                                        <div className="field-extra">
                                                                            <Checkbox
                                                                                id={inc.source}
                                                                                onClick={(e) => this.toggleSelectedIncome(e, inc.id)}
                                                                                checked={selectedIncome.includes(inc.id)} />
                                                                            <NumberFormat
                                                                                className="number"
                                                                                value={inc.amount}
                                                                                thousandSeparator={true}
                                                                                prefix={'$'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                placeholder={'$0.00'}
                                                                                onValueChange={(e) => this.editRow(e, {id: 'amount'}, 'experimentationIncome', i)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="reset">
                                                    <a href="noop" onClick={this.resetExperimentationBox}>Reset</a>
                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.nestEgg.reset} />
                                                </div>

                                                <div className="nest-egg-chart">
                                                    <div className="chart-header">
                                                        <div className="need">
                                                            <label className="low">Lowest Nest Egg Need</label>
                                                            <span className="low need-value">{numeral(nestEggNeeds.low.value).format(NUM_FORMAT_SHORT)}+</span>
                                                        </div>
                                                        <div className="need">
                                                            <label className="medium">Medium Nest Egg Need</label>
                                                            <span className="medium need-value">{numeral(nestEggNeeds.medium.value).format(NUM_FORMAT_SHORT)}+</span>
                                                        </div>
                                                        <div className="need">
                                                            <label className="high">Highest Nest Egg Need</label>
                                                            <span className="high need-value">{numeral(nestEggNeeds.high.value).format(NUM_FORMAT_SHORT)}+</span>
                                                        </div>
                                                    </div>

                                                    <div className="chart">
                                                        <BarChart width={(mobile) ? window.innerWidth : 525} height={385}
                                                            data={Object.keys(nestEggNeeds).map(k => nestEggNeeds[k])}
                                                            margin={{ top: 20, bottom: 20, right: 15, left: 15 }}>
                                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                                            <YAxis width={50} tickCount={8} tickLine={false} axisLine={false} tickFormatter={this.formatYAxisTicksNestEgg} />
                                                            <Bar dataKey="value" fill="#FFCA52" barSize={80}>
                                                                {Object.keys(nestEggNeeds).map((k, i) => <Cell key={i} fill={nestEggNeeds[k].fill} />)}
                                                            </Bar>
                                                        </BarChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </section>

                                <section className={cn('historical-progress', historicalProgressCollapsed && 'collapsed')}>
                                    <header className={cn('border', historicalProgressCollapsed && 'collapsed')}>
                                        <h3 className="header">
                                            Historical Progress
                                            {historicalProgressCollapsed ?
                                                <ChevronRightIcon onClick={(e) => this.toggleSection(e, 'historicalProgress')} /> :
                                                <ExpandMoreIcon onClick={(e) => this.toggleSection(e, 'historicalProgress')} />
                                            }
                                        </h3>
                                        <a href="noop" className="lm-link" onClick={(e) => this.toggleLearnMore(e, 'historicalProgressLMOpen')}>
                                            {!mobile && <>Learn More</>}
                                            {historicalProgressLMOpen ?
                                                <img src={RemoveIcon} alt="Remove icon, minus sign in circle" /> :
                                                <img src={AddIcon} alt="Add icon, plus sign in circle, green" />}
                                        </a>
                                    </header>

                                    {historicalProgressLMOpen &&
                                        <div className="learn-more-section">
                                            <div className="info">
                                                <p>This graph shows the value of your retirement accounts at the end of a given year and
                                                the dollar amount you saved and invested in a given year. The table below shows additional
                                                detail.</p>

                                                <p>Because MoneySwell doesn't track every investment transaction, "Investment Growth Rate" is
                                                &nbsp;<a href="https://www.moneyswell.com/uncategorized/how-to-estimate-your-annual-investment-growth-rate/" target="_blank" rel="noreferrer">
                                                    an estimate
                                                </a>. But it's a reasonable number to roughly compare your portfolio's performance with a
                                                broad market index in a given year.</p>

                                                <p>Remember: The market is volatile. Your retirement portfolio will go up, AND down. (
                                                <a href="https://www.moneyswell.com/action-plan/retire-by-your-goal-retirement-age/#toc_Historical_Perspectives_on_Rates_of_Return" target="_blank" rel="noreferrer">
                                                    Read more about historical performance.
                                                </a>) But no one year of poor performance or a low contribution amount will doom your
                                                portfolio. Control what you can, and stay the course.</p>
                                            </div>

                                            <img src={LearnMore7} className="illustration" alt="Illustration, teal wallet with a smiley face, coins and bills bulging out" />

                                            <a href="noop" className="lm-link close" onClick={(e) => this.toggleLearnMore(e, 'historicalProgressLMOpen')}>
                                                Close
                                                <MinusIcon stroke={'#FFFFFF'} onClick={(e) => this.toggleLearnMore(e, 'historicalProgressLMOpen')} />
                                            </a>
                                        </div>
                                    }

                                    {!historicalProgressCollapsed &&
                                        <>
                                            {!hpLoaded && <Loading />}

                                            {hpLoaded && historicalProgress.length === 0 &&
                                                <div className="subsection">
                                                    <p>No historical progress available. Please make sure you have at least one retirement account under Assets & Income in Step 1.</p>
                                                </div>
                                            }

                                            {hpLoaded && historicalProgress.length > 0 &&
                                                <div className="subsection">
                                                    {this.props.account.user === null &&
                                                        <div className="logged-out-lightbox">
                                                            <div class="faux-dialog">
                                                                <h3>This feature is only available to users with accounts.</h3>
                                                                <div className="buttons">
                                                                    <a href="/login">Login</a>
                                                                    <a href="/signup" className="primary">Try Free</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="historical-progress-chart">
                                                        <h3>
                                                            Values & Contributions by Year
                                                            <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.values} />
                                                        </h3>

                                                        <div className="chart">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <ComposedChart data={hpChartData} margin={hpChartMargin} onMouseMove={this.chartMouseMove} onMouseLeave={this.chartMouseOut}>
                                                                    <Tooltip contentStyle={{display: 'none'}} cursor={{ stroke: '#F4F6F8', strokeWidth: `${(MAGIC_NUMERATOR / hpChartData.length)}%` }} />
                                                                    <CartesianGrid stroke="#E6E6E6" vertical={false} />
                                                                    <XAxis dataKey="year" minTickGap={0} axisLine={false} tickLine={false} />
                                                                    <YAxis yAxisId="left" tickCount={6} tickLine={false} axisLine={false} tickFormatter={this.formatYAxisTicks} />
                                                                    <YAxis yAxisId="right" orientation="right" tickCount={6} tickLine={false} axisLine={false} tickFormatter={this.formatYAxisTicks} />
                                                                    <Bar yAxisId="left" dataKey="endValue" fill="#B9F1F4" />
                                                                    <Line yAxisId="right" type="monotone" dataKey="ytdRetirement" stroke={'#8878F7'} strokeWidth={2} dot={{fill: '#8878F7', stroke: '#8878F7', r: (mobile) ? 6 : 12}} />
                                                                </ComposedChart>
                                                            </ResponsiveContainer>
                                                        </div>

                                                        <div className="legend">
                                                            {legendPayload.map((l, i) => {
                                                                return (
                                                                    <div className={cn('legend-item', l.id)} key={i}>
                                                                        <div className="icon" />
                                                                        <label>{l.label}</label>
                                                                        <span>{numeral(l.value).format(NUM_FORMAT_SHORT)}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>

                                                    {mobile &&
                                                        <>
                                                            <div className="mobile-table">
                                                                <div className="header">
                                                                    <div className="header-col" style={{width: '32%'}}>Year</div>
                                                                    <div className="header-col" style={{width: '32%'}}>Retirement Contributions</div>
                                                                    <div className="header-col" style={{width: '32%'}}>Ending Value of Retirement Assets</div>
                                                                </div>

                                                                <div className="body">
                                                                    {historicalProgress.map((hp, i) => {
                                                                        if (hp.isAverage) {
                                                                            return false;
                                                                        }

                                                                        const editing = editingHPRow.id === hp.id;
                                                                        const adding = addingHPRow.id === hp.id;

                                                                        let row = null;
                                                                        if (!adding) {
                                                                            row = (
                                                                                <>
                                                                                    {editing && <MinusIcon stroke={'#EB535C'} onClick={(e) => this.deleteHPRow(e, hp)} />}
                                                                                    <div className="body-col year" style={{width: '32%'}}>{hp.year}</div>
                                                                                    <div className="body-col ytdRetirement" style={{width: '32%'}}>
                                                                                        {numeral(hp.ytdRetirement).format(NUM_FORMAT_SHORT)}
                                                                                    </div>
                                                                                    <div className="body-col endValue" style={{width: '32%'}}>
                                                                                        {numeral(hp.endValue).format(NUM_FORMAT_SHORT)}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }

                                                                        let expanded = null;
                                                                        if (expandMobile?.year === hp.year) {
                                                                            expanded = (
                                                                                <div className="expand-mobile">
                                                                                    <div className="expand-row full">
                                                                                        <label>Household Income: </label>
                                                                                        <span>{numeral(hp.ytdIncome).format(NUM_FORMAT_SHORT)}</span>
                                                                                    </div>
                                                                                    <div className="expand-row full">
                                                                                        <label>Savings Rate: </label>
                                                                                        <span className={cn('savings-rate', hp.savingsRate > 0 && 'green', hp.savingsRate <= 0 && 'red')}>
                                                                                            {`${(hp.savingsRate || 0).toFixed(1)}%`}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="expand-row full">
                                                                                        <label>Investment Growth Rate: </label>
                                                                                        <span className={cn('growth-rate', (hp.growthRate > 0 || i === 0) && 'green', hp.growthRate <= 0 && i !== 0 && 'red')}>
                                                                                            {`${(hp.growthRate*100 || 0).toFixed(1)}%`}
                                                                                        </span>
                                                                                    </div>

                                                                                    {!Object.keys(editingHPRow).length && <a href="noop" onClick={(e) => this.setEditHPRow(e, hp)}>Edit</a>}
                                                                                </div>
                                                                            );
                                                                        } else if (editing || adding) {
                                                                            let year = (<span className="year">{hp.year}</span>);
                                                                            if (adding) {
                                                                                let years = [];
                                                                                for (let y = THIS_YEAR; y > 1950; y--) {
                                                                                    if (!historicalProgress.filter(hp => hp.id > 0).map(hp => hp.year).includes(y)) {
                                                                                        years.push({year: y});
                                                                                    }
                                                                                }

                                                                                year = (
                                                                                    <select value={hp.year} onChange={(e) => this.updateHPField(e, hp, 'year')}>
                                                                                        <option value="">Year</option>
                                                                                        {years.map((y, j) => {
                                                                                            return (<option value={y.year} key={j}>{y.year}</option>);
                                                                                        })}
                                                                                    </select>
                                                                                );
                                                                            }

                                                                            expanded = (
                                                                                <div className="expand-mobile">
                                                                                    <div className="expand-row full">
                                                                                        <label className="bold">Year</label>
                                                                                        {year}
                                                                                    </div>
                                                                                    <div className="expand-row full">
                                                                                        <label className="bold">Household Income</label>
                                                                                        <NumberFormat
                                                                                            value={hp.ytdIncome}
                                                                                            thousandSeparator={true}
                                                                                            prefix={'$'}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                            placeholder={'$0.00'}
                                                                                            onValueChange={(e) => this.updateHPField(e, hp, 'ytdIncome')}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="expand-row full">
                                                                                        <label className="bold">Retirement Contributions</label>
                                                                                        <NumberFormat
                                                                                            value={hp.ytdRetirement}
                                                                                            thousandSeparator={true}
                                                                                            prefix={'$'}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                            placeholder={'$0.00'}
                                                                                            onValueChange={(e) => this.updateHPField(e, hp, 'ytdRetirement')}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="expand-row full">
                                                                                        <label className="bold">Ending Value of Retirement Assets</label>
                                                                                        <NumberFormat
                                                                                            value={hp.endValue}
                                                                                            thousandSeparator={true}
                                                                                            prefix={'$'}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                            placeholder={'$0.00'}
                                                                                            onValueChange={(e) => this.updateHPField(e, hp, 'endValue')}
                                                                                        />
                                                                                    </div>

                                                                                    <a href="noop" onClick={(e) => this.saveHPRow(e, hp)}>Save</a>
                                                                                </div>
                                                                            );
                                                                        }

                                                                        return (
                                                                            <div className="row" key={i} onClick={() => this.expandMobile(hp)}>
                                                                                {row}
                                                                                {expanded}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {!mobile &&
                                                        <table className="retirement-planner-table">
                                                            <thead>
                                                                <tr>
                                                                    <td className="year">Year</td>
                                                                    <td className="income">Household Income <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.income} /></td>
                                                                    <td className="contributions">Retirement Contributions <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.retirement} /></td>
                                                                    <td className="savings-rate">Savings Rate <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.savings} /></td>
                                                                    <td className="growth-rate">Investment Growth Rate* <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.growth} /></td>
                                                                    <td className="end-value bold"><label>Ending Value of Retirement Assets</label> <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.step3.historical.endValue} /></td>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {historicalProgress.map((hp, i) => {
                                                                    if (hp.isAverage) {
                                                                        return false;
                                                                    }

                                                                    const odd = i % 2 !== 0;
                                                                    const bold = hp.year === barHover.year;
                                                                    const editing = editingHPRow.id === hp.id;
                                                                    const adding = addingHPRow.id === hp.id;

                                                                    let year = hp.year;
                                                                    if (adding) {
                                                                        let years = [];
                                                                        for (let y = THIS_YEAR; y > 1950; y--) {
                                                                            if (!historicalProgress.filter(hp => hp.id > 0).map(hp => hp.year).includes(y)) {
                                                                                years.push({year: y});
                                                                            }
                                                                        }

                                                                        year = (
                                                                            <select value={hp.year} onChange={(e) => this.updateHPField(e, hp, 'year')}>
                                                                                <option value="">Year</option>
                                                                                {years.map((y, j) => {
                                                                                    return (<option value={y.year} key={j}>{y.year}</option>);
                                                                                })}
                                                                            </select>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <tr key={i} className={cn(odd && 'odd', editing && 'editing', adding && 'adding')}>
                                                                            <td className={cn('year', bold && 'bold')}>
                                                                                {editing && <MinusIcon stroke={'#EB535C'} onClick={(e) => this.deleteHPRow(e, hp)} />}
                                                                                {year}
                                                                            </td>
                                                                            <td className={cn('income', bold && 'bold')}>
                                                                                {(editing || adding) ?
                                                                                    <NumberFormat
                                                                                        value={hp.ytdIncome}
                                                                                        thousandSeparator={true}
                                                                                        prefix={'$'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        placeholder={'$0.00'}
                                                                                        onValueChange={(e) => this.updateHPField(e, hp, 'ytdIncome')}
                                                                                    /> :
                                                                                    numeral(hp.ytdIncome).format(NUM_FORMAT_SHORT)
                                                                                }
                                                                            </td>
                                                                            <td className={cn('contributions', bold && 'bold')}>
                                                                                {(editing || adding) ?
                                                                                    <NumberFormat
                                                                                        value={hp.ytdRetirement}
                                                                                        thousandSeparator={true}
                                                                                        prefix={'$'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        placeholder={'$0.00'}
                                                                                        onValueChange={(e) => this.updateHPField(e, hp, 'ytdRetirement')}
                                                                                    /> :
                                                                                    numeral(hp.ytdRetirement).format(NUM_FORMAT_SHORT)
                                                                                }
                                                                            </td>
                                                                            <td className={cn('savings-rate', bold && 'bold', hp.savingsRate > 0 && !editing && !adding && 'green', hp.savingsRate <= 0 && !editing && !adding && 'red')}>
                                                                                {(editing || adding) ? '--' : `${(hp.savingsRate || 0).toFixed(1)}%`}
                                                                            </td>
                                                                            <td className={cn('growth-rate', bold && 'bold', (hp.growthRate > 0 || i === 0) && !editing && !adding && 'green', hp.growthRate <= 0 && i !== 0 && !editing && !adding && 'red')}>
                                                                                {(i === 0 && !editing && !adding) ? 'n/a' : (editing || adding) ? '--' : `${(hp.growthRate*100 || 0).toFixed(1)}%`}
                                                                            </td>
                                                                            <td className={cn('end-value', bold && 'bold')}>
                                                                                <>
                                                                                    {(editing || adding) ?
                                                                                        <NumberFormat
                                                                                            value={hp.endValue}
                                                                                            thousandSeparator={true}
                                                                                            prefix={'$'}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                            placeholder={'$0.00'}
                                                                                            onValueChange={(e) => this.updateHPField(e, hp, 'endValue')}
                                                                                        /> :
                                                                                        numeral(hp.endValue).format(NUM_FORMAT_SHORT)
                                                                                    }
                                                                                    {!editing && !adding && !Object.keys(editingHPRow).length && <a href="noop" onClick={(e) => this.setEditHPRow(e, hp)}>Edit</a>}
                                                                                    {(editing || adding) && <a href="noop" onClick={(e) => this.saveHPRow(e, hp)}>Save</a>}
                                                                                </>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    }

                                                    {!mobile && Object.keys(lastHP).length > 0 &&
                                                        <div className="annualized-average">
                                                            <div className="year">Annualized Average</div>
                                                            <div className="income">{numeral(lastHP.averageIncome).format(NUM_FORMAT_SHORT)}</div>
                                                            <div className="contributions">{numeral(lastHP.averageContributions).format(NUM_FORMAT_SHORT)}</div>
                                                            <div className={cn('savings-rate', lastHP.averageSavingsRate > 0 && 'green', lastHP.averageSavingsRate <= 0 && 'red')}>
                                                                {`${(lastHP.averageSavingsRate || 0).toFixed(1)}%`}
                                                            </div>
                                                            <div className={cn('growth-rate', (lastHP.averageGrowthRate > 0) && 'green', lastHP.averageGrowthRate <= 0 && 'red')}>
                                                                {`${(lastHP.averageGrowthRate*100 || 0).toFixed(1)}%`}
                                                            </div>
                                                            <div className="end-value">n/a</div>
                                                        </div>
                                                    }

                                                    <div className="table-footer-actions">
                                                        <a href="noop" className="lm-link" onClick={this.addHPRow}>
                                                            Add Historical Year <img src={AddIcon} alt="Add icon, plus sign in circle, green" />
                                                        </a>

                                                        <span>* Estimated, <a href="https://www.moneyswell.com/moneyswell-blog/how-to-estimate-your-annual-investment-growth-rate/" target="_blank" rel="noreferrer">learn more</a></span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </section>

                                <div className="nav-buttons">
                                    <a href="noop" onClick={(e) => this.setStep(e, 'goalsOutlook')}>{'< Back to Goals & Outlook'}</a>
                                </div>
                            </>
                        }

                        <Dialog onClose={this.toggleIntroDialog} open={introDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleIntroDialog} />

                            <h1>Retirement Planner</h1>
                            <h2>Discover your needs and make a plan to get there!</h2>

                            <div className="rp-intro-dialog">
                                {STEPS.map((s, i, arr) => {
                                    return (
                                        <div className="step-wrapper" key={i}>
                                            <div className="step">
                                                <img src={s.image} alt={s.imageAlt} />
                                                <label>{`Step ${i+1}`}</label>
                                                <span>{s.shortDesc}</span>
                                            </div>
                                            {i !== arr.length-1 && <div className="next"><img src={PlayIcon} alt="Next icon, right-pointing triangle, green" /></div>}
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="retirement-dialog-actions">
                                <button className="primary" onClick={this.toggleIntroDialog}>Let's Go!</button>
                            </div>
                        </Dialog>

                        <Dialog onClose={this.toggleALDialog} open={alDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleALDialog} />

                            <h1>Retirement account values are set from Assets & Liabilities.</h1>
                            <div className="retirement-dialog-actions">
                                <button className="primary center" onClick={this.gotoAL}>Let's Go!</button>
                            </div>
                            <div className="remember">
                                <Checkbox id="rememberCheckbox" onChange={this.gotoALRemember} checked={gotoALRemember} />
                                <label htmlFor="rememberCheckbox">Don't ask this again</label>
                            </div>
                        </Dialog>

                        <Dialog onClose={this.toggleEditHistoricalDialog} open={editHistoricalDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleEditHistoricalDialog} />

                            <h1>You're about to edit your retirement account values for the last day of the selected year. <br /><br />Are you ready to edit your Historical Record?</h1>
                            <div className="retirement-dialog-actions">
                                <button className="primary center" onClick={(e) => this.gotoEditHistorical(e, `${goalsOutlookFields.year}-12-31`)}>Let's Go!</button>
                            </div>
                            <div className="remember">
                                <Checkbox id="rememberCheckbox" onChange={this.editHistoricalRemember} checked={editHistoricalRemember} />
                                <label htmlFor="rememberCheckbox">Don't ask this again</label>
                            </div>
                        </Dialog>

                        <Dialog onClose={this.toggleEditValueDialog} open={startValueDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleEditValueDialog} />

                            <h1>This value is pulled from selected accounts from January 1st (or the earliest date for which there is data) of
                            this year, continuing will take you to edit your historical record for 1/1/{goalsOutlookFields.year}.</h1>
                            <div className="retirement-dialog-actions">
                                <button className="primary center" onClick={(e) => this.gotoEditHistorical(e, `${goalsOutlookFields.year}-01-01`)}>Let's Go!</button>
                            </div>
                        </Dialog>

                        <Dialog onClose={this.toggleEditGoalDialog} open={!!editGoalDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleEditGoalDialog} />

                            <h1>{editGoalDialogOpen}'s data can be edited from the Goals & Progress section of Step 2 in your Retirement Planner.</h1>
                            <div className="retirement-dialog-actions">
                                <button className="primary center" onClick={(e) => this.setStep(e, 'goalsOutlook')}>Take me there</button>
                            </div>
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'overviewVideoOpen')} open={overviewVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'overviewVideoOpen')} />
                            <ReactPlayer url={RPOverviewVideo} playing={overviewVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'myInfoVideoOpen')} open={myInfoVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'myInfoVideoOpen')} />
                            <ReactPlayer url={RPMyInfoVideo} playing={myInfoVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'goalsOutlookVideoOpen')} open={goalsOutlookVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'goalsOutlookVideoOpen')} />
                            <ReactPlayer url={RPGoalsOutlookVideo} playing={goalsOutlookVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'summaryVideoOpen')} open={summaryVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'summaryVideoOpen')} />
                            <ReactPlayer url={RPSummaryVideo} playing={summaryVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>
                    </div>

                    {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(RetirementPlanner);
