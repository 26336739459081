import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, loadHistoricalDay, saveHistoricalDay } from 'actions/dataActions';
import { DATE_FORMAT, NUM_FORMAT, ZERO_NUM } from 'constants/config';
import { DAY_FORMAT } from 'constants/assetsLiabilities';
import "react-datepicker/dist/react-datepicker.css";
import '../AssetsLiabilities/AssetsLiabilities.scss';
import './EditHistorical.scss';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    historical: state.data.historical,
    success: state.data.success,
    error: state.data.error,
    lastUpdated: state.data.lastUpdated,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadHistoricalDay: (user, date) => {
        dispatch(loadHistoricalDay(user, date));
    },
    saveHistoricalDay: (user, accounts, date) => {
        dispatch(saveHistoricalDay(user, accounts, date));
    },
});

class EditHistorical extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            dataLoading: false,
            dataLoaded: false,
            editDate: null,
            assets: [],
            liabilities: [],
            totals: {assets: 0, liabilities: 0},
            edit: { success: false, error: false },
        };
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let account = nextProps.account, state = {};

        if (account.loaded && account.user) {
            state.loaded = true;

            if (!nextProps.financialPriorities && !nextProps.historical) {
                this.props.loadFinancialPriorities(account.user);
            }

            if (this.state.editDate === null && window.location.search.includes('?date=')) {
                const urlDate = window.location.search.split('?date=')[1];
                state.editDate = new Date(new Date(urlDate).setHours(24,0,0,0));
                state.dataLoaded = false;
                state.dataLoading = true;
                this.props.loadHistoricalDay(account.user, urlDate);
            }
        }

        if (this.state.editDate !== null && nextProps.historical) {
            nextProps.historical.forEach(h => {
                if (!h.date) {
                    h.date = moment(this.state.editDate).format(DAY_FORMAT);
                }
            });
            state.dataLoading = false;
            state.dataLoaded = true;
            state.assets = nextProps.historical.filter(h => h.category === 'assets');
            state.liabilities = nextProps.historical.filter(h => h.category === 'liabilities');
            state.totals = {
                assets: state.assets.reduce((total, a) => total += numeral(a.value).value(), 0),
                liabilities: state.liabilities.reduce((total, l) => total += numeral(l.value).value(), 0),
            };
        }

        state.edit = {
            success: nextProps.success,
            error: nextProps.error,
        };

        this.setState(state);
    }

    setEditDate = (date) => {
        this.setState({
            editDate: date,
            dataLoaded: false,
            dataLoading: true,
        });
        this.props.loadHistoricalDay(this.props.account.user, moment(date).format(DAY_FORMAT));
    }

    editAmount = (e, index, category) => {
        let state = Object.assign({}, this.state);

        state[category][index].value = numeral(e.target.value).value();
        state.totals[category] = state[category].reduce(function(acc, curr) {
            return acc + numeral(curr.value).value();
        }, 0);

        this.setState({
            assets: state.assets,
            liabilities: state.liabilities,
            totals: state.totals,
        });
    }

    cancelEdit = () => {
        window.location.href = '/assetsLiabilities';
    }

    saveEditedHistorical = () => {
        this.props.saveHistoricalDay(this.props.account.user, {
            assets: this.state.assets,
            liabilities: this.state.liabilities
        }, this.state.editDate);
    }

    render() {
        const { loaded, dataLoading, dataLoaded, editDate, assets, liabilities, totals, edit } = this.state;
        const { account, historical, lastUpdated, financialPriorities } = this.props;

        const editDateFormatted = moment(editDate).format(DATE_FORMAT);

        let alert = null;
        if (edit.success) {
            alert = { severity: 'success', msg: edit.success.msg };
        } else if (edit && edit.error) {
            alert = { severity: 'error', msg: edit.error.body.msg };
        }

        if (!loaded) {
            return (<Loading />);
        } else {
            return (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} fp={financialPriorities} />

                    <div className="main assets-liabilities edit-historical">
                        <h1>Edit Historical Record</h1>
                        {lastUpdated !== '' && <h2 className="sub light">Last Updated {moment(lastUpdated).format(DATE_FORMAT)}</h2>}

                        <div className="eh-content">
                            <div className="label-datepicker">
                                <label>Date to edit:</label>
                                <DatePicker className="datepicker" selected={editDate} onChange={this.setEditDate} />
                            </div>

                            {dataLoading && !dataLoaded && <Loading />}

                            {!dataLoading && dataLoaded && historical.length === 0 && <>
                                <p>We couldn't find any historical record for {editDateFormatted}.</p>
                            </>}

                            {!dataLoading && dataLoaded && historical.length > 0 && <div className="edit-accounts">
                                <div className="edit-section assets">
                                    <h2>Assets</h2>
                                    {assets.map((asset, i) => {
                                        return (
                                            <div className="account-row" key={i}>
                                                <label>{asset.name}</label>
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'}
                                                    value={asset.value}
                                                    placeholder={ZERO_NUM}
                                                    onValueChange={(values) => {
                                                        let e = { target: {value: values.value} };
                                                        this.editAmount(e, i, asset.category)
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="total assets">
                                        <label>Total Assets: </label>
                                        <span>{numeral(totals.assets).format(NUM_FORMAT)}</span>
                                    </div>
                                </div>

                                <div className="edit-section liabilities">
                                    <h2>Liabilities</h2>
                                    {liabilities.map((liability, i) => {
                                        return (
                                            <div className="account-row" key={i}>
                                                <label>{liability.name}</label>
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'}
                                                    value={liability.value}
                                                    placeholder={ZERO_NUM}
                                                    onValueChange={(values) => {
                                                        let e = { target: {value: values.value} };
                                                        this.editAmount(e, i, liability.category)
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="total liabilities">
                                        <label>Total Liabilities: </label>
                                        <span>{numeral(totals.liabilities).format(NUM_FORMAT)}</span>
                                    </div>
                                </div>
                            </div>}

                            {!dataLoading && dataLoaded && historical.length > 0 &&
                                <div className="save-accounts">
                                    <button className="primary" onClick={this.saveEditedHistorical}>{"Save"}</button>
                                    <button className="secondary" onClick={this.cancelEdit}>{"Cancel"}</button>
                                </div>
                            }
                        </div>
                    </div>

                    {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(EditHistorical);
