import React, { Component } from 'react';
import './BackLink.scss';

class BackLink extends Component {
    render() {
        const { onClick } = this.props;

        return (
            <a href="noop" className="back-link" onClick={onClick}>
                {'< Back'}
            </a>
        );
    }
}

BackLink.defaultProps = {
    onClick: (e) => { e.preventDefault(); window.history.back(); }
};

export default BackLink;
