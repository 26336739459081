import {
    ADD_ACCOUNT_ERROR,
    ADD_ACCOUNT_SUCCESS,
    ASSETS_LIABILITIES_LOAD_ERROR,
    ASSETS_LIABILITIES_LOAD_SUCCESS,
    CASHFLOW_ACTUAL_LOAD_ERROR,
    CASHFLOW_ACTUAL_LOAD_SUCCESS,
    CASHFLOW_GOALS_LOAD_ERROR,
    CASHFLOW_GOALS_LOAD_SUCCESS,
    CASHFLOW_REVIEW_LOAD_ERROR,
    CASHFLOW_REVIEW_LOAD_SUCCESS,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_CASHFLOW_PLAN_ERROR,
    DELETE_CASHFLOW_PLAN_SUCCESS,
    EDIT_ACCOUNT_ERROR,
    EDIT_ACCOUNT_SUCCESS,
    FINANCIAL_PRIORITIES_LOAD_ERROR,
    FINANCIAL_PRIORITIES_LOAD_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_LINK_TOKEN_SUCCESS,
    HISTORICAL_LOAD_ERROR,
    HISTORICAL_LOAD_SUCCESS,
    LOAD_TASK_ERROR,
    LOAD_TASK_SUCCESS,
    RETIREMENT_PLANNER_LOAD_ERROR,
    RETIREMENT_PLANNER_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOALS_LOAD_ERROR,
    RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR,
    RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS,
    RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR,
    RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS,
    RETIREMENT_PLANNER_INCOME_LOAD_ERROR,
    RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS,
    SAVE_HISTORICAL_RECORD_ERROR,
    SAVE_HISTORICAL_RECORD_SUCCESS,
    SAVE_CASHFLOW_GOALS_ERROR,
    SAVE_CASHFLOW_GOALS_SUCCESS,
    SAVE_RETIREMENT_PLANNER_ERROR,
    SAVE_RETIREMENT_PLANNER_SUCCESS,
    SAVE_RETIREMENT_PLANNER_GOALS_ERROR,
    SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS,
    SAVE_RETIREMENT_PLANNER_INCOME_ERROR,
    SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS,
    SYNC_ACCOUNTS_ERROR,
    SYNC_ACCOUNTS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    assets: null,
    liabilities: null,
    newAccount: null,
    editedAccount: null,
    historical: null,
    lastUpdated: '',
    cashflowGoals: null,
    cashflowActual: null,
    cashflowReview: null,
    financialPriorities: null,
    task: null,
    error: null,
    success: null,
    sync: null,
    preferences: null,
    retirementPlanner: null,
    rpIncome: null,
    rpGoals: null,
    rpGoalYears: null,
    rpGoalsAll: null,
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSETS_LIABILITIES_LOAD_ERROR:
        case GET_ACCESS_TOKEN_ERROR:
        case SYNC_ACCOUNTS_ERROR:
            return Object.assign({}, state, {
                assets: null,
                liabilities: null,
                lastUpdated: '',
                error: action.payload,
                success: null,
            });
        case ASSETS_LIABILITIES_LOAD_SUCCESS:
            action.payload.accounts.forEach(a => {
                a.customNum = a.custom_num; delete a.custom_num;
                a.customFreq = a.custom_frequency; delete a.custom_frequency;
                a.reminderType = a.reminder_type; delete a.reminder_type;
                a.weekdays = a.week_days?.split('|'); delete a.week_days;
                a.monthDay = a.month_day; delete a.month_day;
                a.reminderStartDate = a.start_date; delete a.start_date;
            });

            return Object.assign({}, state, {
                assets: action.payload.accounts.filter(a =>
                    a.category === 'assets'),
                liabilities: action.payload.accounts.filter(a =>
                    a.category === 'liabilities'),
                lastUpdated: action.payload.accounts.reduce((max, a) =>
                    (a.modified > max) ? a.modified : max, ''
                ),
                error: null,
                success: null,
            });
        case GET_ACCESS_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                assets: action.payload.accounts.filter(a =>
                    a.category === 'assets'),
                liabilities: action.payload.accounts.filter(a =>
                    a.category === 'liabilities'),
                lastUpdated: action.payload.accounts.reduce((max, a) =>
                    (a.modified > max) ? a.modified : max, ''
                ),
                error: null,
                success: action.payload,
                newAccount: null,
            });
        case SYNC_ACCOUNTS_SUCCESS:
            action.payload.accounts.forEach(a => {
                a.customNum = a.custom_num; delete a.custom_num;
                a.customFreq = a.custom_frequency; delete a.custom_frequency;
                a.reminderType = a.reminder_type; delete a.reminder_type;
                a.weekdays = a.week_days?.split('|'); delete a.week_days;
                a.monthDay = a.month_day; delete a.month_day;
                a.reminderStartDate = a.start_date; delete a.start_date;
            });

            return Object.assign({}, state, {
                assets: action.payload.accounts.filter(a =>
                    a.category === 'assets'),
                liabilities: action.payload.accounts.filter(a =>
                    a.category === 'liabilities'),
                lastUpdated: action.payload.accounts.reduce((max, a) =>
                    (a.modified > max) ? a.modified : max, ''
                ),
                error: null,
                success: action.payload,
                sync: action.payload.results,
                newAccount: null,
            });
        case GET_LINK_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                success: null,
                newAccount: null,
            });
        case ADD_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                newAccount: null,
                editedAccount: null,
                error: action.payload,
                success: null,
            });
        case ADD_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                newAccount: action.payload.account,
                editedAccount: null,
                lastUpdated: action.payload.account.modified,
                error: null,
                success: action.payload,
            });
        case EDIT_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                newAccount: null,
                editedAccount: null,
                error: action.payload,
                success: null,
            });
        case EDIT_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                newAccount: null,
                editedAccount: action.payload.account,
                lastUpdated: action.payload.account.modified,
                error: null,
                success: action.payload,
            });
        case DELETE_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                newAccount: null,
                editedAccount: null,
                error: action.payload,
                success: null,
            });
        case DELETE_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                newAccount: null,
                editedAccount: null,
                error: null,
                success: action.payload,
            });
        case SAVE_HISTORICAL_RECORD_ERROR:
            return Object.assign({}, state, {
                assets: null,
                liabilities: null,
                lastUpdated: '',
                error: action.payload,
                success: false,
                newAccount: null,
            });
        case SAVE_HISTORICAL_RECORD_SUCCESS:
            return Object.assign({}, state, {
                assets: action.payload.accounts.filter(a =>
                    a.category === 'assets'),
                liabilities: action.payload.accounts.filter(a =>
                    a.category === 'liabilities'),
                lastUpdated: action.payload.accounts.reduce((max, a) =>
                    (a.modified > max) ? a.modified : max, ''
                ),
                error: false,
                success: action.payload,
                newAccount: null,
                editedAccount: null,
            });
        case HISTORICAL_LOAD_ERROR:
            return Object.assign({}, state, {
                historical: null,
                error: action.payload,
                success: null,
                newAccount: null,
                preferences: {},
            });
        case HISTORICAL_LOAD_SUCCESS:
            return Object.assign({}, state, {
                historical: action.payload.historical,
                lastUpdated: action.payload.accounts?.reduce((max, a) =>
                    (a.modified > max) ? a.modified : max, ''
                ),
                newAccount: null,
                error: null,
                success: null,
                preferences: action.payload.preferences || {},
            });
        case SAVE_CASHFLOW_GOALS_ERROR:
            return Object.assign({}, state, {
                cashflowGoals: null,
                cashflowActual: null,
                error: action.payload,
                success: false,
            });
        case SAVE_CASHFLOW_GOALS_SUCCESS:
            return Object.assign({}, state, {
                cashflowGoals: action.payload.plan,
                cashflowActual: action.payload.plan,
                error: false,
                success: action.payload,
            });
        case CASHFLOW_GOALS_LOAD_ERROR:
            return Object.assign({}, state, {
                cashflowGoals: null,
                error: action.payload,
            });
        case CASHFLOW_GOALS_LOAD_SUCCESS:
            let records = action.payload.records;
            return Object.assign({}, state, {
                cashflowGoals: {
                    planId: records.plan_id,
                    startMonth: records.start_month,
                    endMonth: records.end_month,
                    income: records.income,
                    savings: records.savings,
                    spending: records.spending,
                },
                error: null,
            });
        case CASHFLOW_ACTUAL_LOAD_ERROR:
            return Object.assign({}, state, {
                cashflowActual: null,
                error: action.payload,
                success: null,
            });
        case CASHFLOW_ACTUAL_LOAD_SUCCESS:
            return Object.assign({}, state, {
                cashflowActual: action.payload,
                error: null,
                success: null,
            });
        case CASHFLOW_REVIEW_LOAD_ERROR:
            return Object.assign({}, state, {
                cashflowReview: null,
                error: action.payload,
                success: null,
            });
        case CASHFLOW_REVIEW_LOAD_SUCCESS:
            return Object.assign({}, state, {
                cashflowReview: action.payload,
                error: null,
                success: null,
            });
        case FINANCIAL_PRIORITIES_LOAD_ERROR:
            return Object.assign({}, state, {
                cashflowReview: null,
                error: action.payload,
            });
        case FINANCIAL_PRIORITIES_LOAD_SUCCESS:
            return Object.assign({}, state, {
                financialPriorities: action.payload.financialPriorities,
                error: null,
            });
        case LOAD_TASK_ERROR:
            return Object.assign({}, state, {
                task: null,
                error: action.payload,
            });
        case LOAD_TASK_SUCCESS:
            return Object.assign({}, state, {
                task: action.payload.task,
                error: null,
            });
        case DELETE_CASHFLOW_PLAN_ERROR:
            return Object.assign({}, state, {
                error: action.payload,
                success: false,
            });
        case DELETE_CASHFLOW_PLAN_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                success: action.payload,
            });
        case RETIREMENT_PLANNER_LOAD_ERROR:
        case SAVE_RETIREMENT_PLANNER_ERROR:
            return Object.assign({}, state, {
                retirementPlanner: null,
                error: action.payload,
                success: null,
            });
        case RETIREMENT_PLANNER_LOAD_SUCCESS:
        case SAVE_RETIREMENT_PLANNER_SUCCESS:
            return Object.assign({}, state, {
                retirementPlanner: action.payload.retirementPlanner,
                error: null,
            });
        case RETIREMENT_PLANNER_INCOME_LOAD_ERROR:
        case SAVE_RETIREMENT_PLANNER_INCOME_ERROR:
            return Object.assign({}, state, {
                rpIncome: null,
                error: action.payload,
                success: null,
            });
        case RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS:
        case SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS:
            return Object.assign({}, state, {
                rpIncome: action.payload.rpIncome,
                error: null,
            });
        case RETIREMENT_PLANNER_GOALS_LOAD_ERROR:
        case SAVE_RETIREMENT_PLANNER_GOALS_ERROR:
            return Object.assign({}, state, {
                rpGoals: null,
                error: action.payload,
                success: null,
            });
        case RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS:
        case SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS:
            return Object.assign({}, state, {
                rpGoals: action.payload.rpGoals,
                error: null,
            });
        case RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR:
            return Object.assign({}, state, {
                rpGoalYears: null,
                error: action.payload,
                success: null,
            });
        case RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS:
            return Object.assign({}, state, {
                rpGoalYears: action.payload.rpGoalYears,
                error: null,
            });
        case RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR:
            return Object.assign({}, state, {
                rpGoalsAll: null,
                error: action.payload,
                success: null,
            });
        case RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS:
            return Object.assign({}, state, {
                rpGoalsAll: action.payload.rpGoalsAll,
                error: null,
            });
        default:
            return state;
    }
};

export default dataReducer;
