import React, { Component } from 'react';
import cn from 'classnames';
import Logo from 'images/icons/logo.svg';
import HamburgerIcon from 'images/icons/hamburger.svg';
import CloseIcon from 'images/icons/close.svg';
import './NavBar.scss';

class NavBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mobileMenuOpen: false,
        };
    }

    toggleMobileMenu = () => {
        this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
    }

    render() {
        const { mobileMenuOpen } = this.state;
        const p = window.location.pathname.split('/')[1];
        const href = (p === 'login') ? 'https://moneyswell.com' : '/login';

        return (
            <nav className={cn('nav', mobileMenuOpen && 'mobile-menu-open')}>
                <section>
                    <div className="nav-header">
                        <a href={href}><img src={Logo} className="logo" alt="MoneySwell logo" /></a>
                        <h2>MoneySwell</h2>
                        {(mobileMenuOpen) ?
                            <img src={CloseIcon} className="menu-icon" alt="Close icon, X, grey" onClick={this.toggleMobileMenu} /> :
                            <img src={HamburgerIcon} className="menu-icon" alt="Hamburger icon, 3 bars, grey" onClick={this.toggleMobileMenu} />
                        }
                    </div>

                    <div className={cn('links-wrapper', mobileMenuOpen && 'mobile-menu-open')}>
                        <ul className="nav-links">
                            <li className={cn('nav-item', p === 'login' && 'selected')}><a href="/login">Login</a></li>
                            <li className={cn('nav-item', p === 'signup' && 'selected')}><a href="/signup">Signup</a></li>
                        </ul>
                    </div>
                </section>
            </nav>
        );
    }
}

export default NavBar;
