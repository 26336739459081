import numeral from 'numeral';
import moment from 'moment';
import { calculateAverage } from 'utils/num';
import { NUM_FORMAT } from 'constants/config';
import IncomeIcon from 'images/icons/spending.svg';
import IncomeColorIcon from 'images/icons/income_color.svg';
import SavingsIcon from 'images/icons/savings.svg';
import SavingsColorIcon from 'images/icons/savings_color.svg';
import SpendingIcon from 'images/icons/income.svg';
import SpendingColorIcon from 'images/icons/spending_color.svg';


export const calculateRowTotal = (row, actual) => {
    if (actual) {
        return (row.actual) ? Number(row.actual) : 0;
    } else if (row.frequency === '1.0') {
        return calculateAverage(row.low, row.high);
    } else if (row.amount && row.amount !== 'NaN' && !Number.isNaN(row.amount)) {
        return (row.frequency) ? Number(row.amount) * Number(row.frequency) : Number(row.amount);
    } else if ((row.low !== 'NaN' && !Number.isNaN(row.low)) || (row.high !== 'NaN' && !Number.isNaN(row.high))) {
        return calculateAverage(row.low, row.high);
    } else {
        return 0;
    }
};

export const calculateTotal = (records, actual = false) => {
    let total = 0;

    records.forEach(t => {
        total += calculateRowTotal(t, actual);
    });

    return total;
};

export const calculateTotalPlanMonths = (start, end) => {
    return end.diff(start, 'months') + 1;
};

export const balancePieLabel = (options, state) => {
    const hasData = (state.incomeTotal > 0 || state.savingsTotal > 0 || state.spendingTotal > 0);
    const anchor = (options.x > options.cx) ? 'start': 'end';
    const rectWidth = 95, rectHeight = 21;
    const rectX = (anchor === 'start') ? options.x : options.x - rectWidth;
    const rectY = options.y - 16;

    if (!hasData) {
        return '';
    }
    return (
        <g>
            <rect x={rectX} y={rectY} width={rectWidth} height={rectHeight} fill="white"></rect>
            <text stroke="none" fill={options.fill} x={options.x} y={options.y} alignmentBaseline="middle" className="recharts-text recharts-pie-label-text" textAnchor={anchor}>
                <tspan x={options.x} dy="0em">{numeral(options.value).format(NUM_FORMAT)}</tspan>
            </text>
        </g>
    );
};

export const calculateReviewPacing = (start, end, totalPlanMonths, reviewTotals, state) => {
    let startActual = start, endActual = end;

    const totalPlanDays = Math.ceil(endActual.diff(startActual, 'days', true));

    let startToToday = Math.ceil(moment().endOf('day').diff(startActual, 'days', true));
    startToToday = (startToToday < 0) ? 365 + startToToday : startToToday;
    const pace = startToToday / totalPlanDays;

    let remainingPlanMonths = endActual.diff(moment().startOf('month').subtract(1, 'days'), 'months');
    if (remainingPlanMonths > totalPlanMonths) {
        remainingPlanMonths = totalPlanMonths;
    }
    remainingPlanMonths = Math.max(remainingPlanMonths, 1);

    const elapsedMonthsSpending = Math.round(moment().endOf('month').diff(startActual, 'months', true));
    const elapsedMonthsIncome = elapsedMonthsSpending -1;

    let reviewPacing = [
        { id: 'income', name: 'Income', icon: IncomeIcon, colorIcon: IncomeColorIcon, alt: 'Income icon, teal, hand holding dollar bills', verb: 'earn', connector: 'as little as', suffix: 'to hit your goal' },
        { id: 'savings', name: 'Savings', icon: SavingsIcon, colorIcon: SavingsColorIcon, alt: 'Savings icon, purple, piggy bank with coin peeking out the top', verb: 'save', connector: 'as little as', suffix: 'to hit your goal' },
        { id: 'spending', name: 'Spending', icon: SpendingIcon, colorIcon: SpendingColorIcon, alt: 'Spending icon, orange, dollar bills in envelope', verb: 'spend', connector: 'up to', suffix: 'and not overshoot your max' },
    ];

    reviewPacing.forEach(t => {
        t.records = state[t.id];
        t.review = state[t.id+'Review'];

        if (state[t.id+'Review'] !== 'all') {
            t.selectedItem = state[t.id].filter(rec => rec.id === Number(state[t.id+'Review']))[0];

            const selectedTotal = reviewTotals[t.id].filter(rec => rec.id === Number(state[t.id+'Review']));
            t.selectedItem.total = (selectedTotal.length === 0) ? 0 : selectedTotal[0].total;
            if (selectedTotal.length > 1) {
                t.selectedItem.total = selectedTotal.reduce((a, c) => {
                    return Number(a) + Number(c.total);
                }, 0);
            }
            if (Number.isNaN(t.selectedItem.total)) {
                t.selectedItem.total = 0;
            }

            if (t.selectedItem.amount && !Number.isNaN(t.selectedItem.amount) && t.selectedItem.amount !== 'NaN') {
                t.denominator = Number(t.selectedItem.amount);
            } else if (t.selectedItem.low && !t.selectedItem.high) {
                t.denominator = Number(t.selectedItem.low);
            } else if (!t.selectedItem.low && t.selectedItem.high) {
                t.denominator = Number(t.selectedItem.high);
            } else {
                t.denominator = (Number(t.selectedItem.low) + Number(t.selectedItem.high)) / 2;
            }

            if (t.selectedItem.frequency) {
                t.denominator *= Number(t.selectedItem.frequency);
            }
        }

        t.total = (state[t.id+'Review'] === 'all') ? reviewTotals[t.id+'Total'] : t.selectedItem.total;
        t.swTotal = (t.id === 'spending') ? reviewTotals.savingsWithdrawalTotal : 0;

        t.barGoal = state[t.id+'Total'] * totalPlanMonths;
        if (state[t.id+'Review'] !== 'all') {
            t.barGoal = (t.selectedItem.savings_withdrawal) ? t.denominator : t.denominator * totalPlanMonths;
        }

        t.onTrack = (t.id === 'spending') ? t.total <= (state[t.id+'Total'] * elapsedMonthsSpending) : t.total > 0 && t.total >= (state[t.id+'Total'] * elapsedMonthsIncome);
        if (state[t.id+'Review'] !== 'all') {
            t.onTrack = (t.id === 'spending') ? t.total <= ((t.selectedItem.savings_withdrawal) ? t.denominator : t.denominator * elapsedMonthsSpending) : t.total > 0 && t.total >= (t.denominator * elapsedMonthsIncome);
        }

        t.pace = (t.total / t.barGoal) * 100;
        if (state[t.id+'Review'] !== 'all') {
            t.pace = (t.total / ((t.selectedItem.savings_withdrawal) ? t.denominator : t.denominator * totalPlanMonths)) * 100;
        }
        if (Number.isNaN(t.pace) || t.pace <= 0) {
            t.pace = 0;
        }

        t.nowNeedAmount = Math.max((t.barGoal - t.total) / remainingPlanMonths, 0);
    });

    return {
        reviewPacing,
        pace,
    };
};
