import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { emailError } from 'actions/miscActions';
import './ErrorFallback.scss';

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    emailError: (user, error) => {
        dispatch(emailError(user, error));
    },
});

class ErrorFallback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            errorEmailed: false,
        };
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;
        }

        this.setState(state);
    }

    emailError = (error) => {
        if (!this.state.errorEmailed) {
            this.props.emailError(
                this.props.account.user,
                {message: error.message, stack: error.stack}
            );
            this.setState({ errorEmailed: true });
        }
    }

    render() {
        const { loaded, errorEmailed } = this.state;
        const { account, error } = this.props;

        if (!loaded) {
            return (<Loading />);
        } else {
            if ((error?.message || error?.stack) && !errorEmailed) {
                this.emailError(error);
            }

            return (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} />

                    <div className="main-bg" />

                    <div className="main error-fallback">
                        <h1>Whoops, something's gone wrong</h1>
                        <p>An unexpected error has occurred. The error details have been emailed to the MoneySwell dev team, and they will attempt to fix the issue as soon as possible.</p>
                        <p>If the issue persists, please email <a href="mailto:support@moneyswell.com" target="_blank" rel="noreferrer">support@moneyswell.com</a> and we will get you sorted out.</p>
                    </div>

                    <Footer />
                </div>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorFallback);
