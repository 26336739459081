import React, { Component } from 'react';

class TaskProgress extends Component {
    render() {
        const { progress } = this.props;

        return (
            <div className="progress">
                <div className="progress-bar">
                    <div className="pace" style={{ width: progress + '%' }} />
                </div>
                <div className="progress-details">
                    <label><span>{progress + '%'}</span> of Tasks Complete</label>
                </div>
            </div>
        );
    }
}

TaskProgress.defaultProps = {
    progress: 0,
};

export default TaskProgress;
