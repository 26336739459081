import {
    ACCOUNT_DELETE_ERROR,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_EDIT_ERROR,
    ACCOUNT_EDIT_SUCCESS,
    ACCOUNT_LOAD_ERROR,
    ACCOUNT_LOAD_SUCCESS,
    DISABLE_TWOFA_ERROR,
    DISABLE_TWOFA_SUCCESS,
    FORGOT_PASSWORD_LOOKUP_ERROR,
    FORGOT_PASSWORD_LOOKUP_SUCCESS,
    FORGOT_PASSWORD_VERIFY_ERROR,
    FORGOT_PASSWORD_VERIFY_SUCCESS,
    GENERATE_SECRET_QR_ERROR,
    GENERATE_SECRET_QR_SUCCESS,
    IMPERSONATE_USER_ERROR,
    IMPERSONATE_USER_SUCCESS,
    INCREMENT_USER_LOGINS_ERROR,
    INCREMENT_USER_LOGINS_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    PASSWORD_EDIT_ERROR,
    PASSWORD_EDIT_SUCCESS,
    REACTIVATE_ACCOUNT_ERROR,
    REACTIVATE_ACCOUNT_SUCCESS,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    USER_PREFERENCES_UPDATE_ERROR,
    USER_PREFERENCES_UPDATE_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_SUCCESS,
    VERIFY_AUTH_CODE_ERROR,
    VERIFY_AUTH_CODE_SUCCESS
} from '../constants/actionTypes';
import accountService from '../services/accountService';

export const saveSignup = (signup) => dispatch => (
    accountService.saveSignup(signup)
        .then(res => {
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: res,
            });
            window.location.href = '/signupSuccess';
        })
        .catch(e => {
            dispatch({
                type: SIGNUP_ERROR,
                payload: e,
            });
        })
);

export const doLogin = (login) => dispatch => (
    accountService.doLogin(login)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res,
            });
            if (!res.totp_enabled) {
                window.location.href = '/dashboard';
            }
        })
        .catch(e => {
            dispatch({
                type: LOGIN_ERROR,
                payload: e,
            });
        })
);

export const loadAccount = () => dispatch => (
    accountService.loadAccount()
        .then(res => {
            // Redirect if free trial has ended //
            if (window.location.pathname !== '/subscriptionSettings') {
                const sub = res?.user?.subscription;
                if (sub?.due_invoices_count > 0 && sub?.due_since === sub?.trial_end) {
                    window.location.href = '/subscriptionSettings';
                    return;
                }
            }

            dispatch({
                type: ACCOUNT_LOAD_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: ACCOUNT_LOAD_ERROR,
                payload: e,
            });
        })
);

export const editAccount = (account) => dispatch => {
    accountService.editAccount(account)
        .then(res => {
            dispatch({
                type: ACCOUNT_EDIT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: ACCOUNT_EDIT_ERROR,
                payload: e,
            });
        })
};

export const editPassword = (details) => dispatch => {
    accountService.editPassword(details)
        .then(res => {
            dispatch({
                type: PASSWORD_EDIT_SUCCESS,
                payload: res,
            });
            if (typeof(details.resetCode) != 'undefined') {
                window.location.href = '/login';
            }
        })
        .catch(e => {
            dispatch({
                type: PASSWORD_EDIT_ERROR,
                payload: e,
            });
        })
};

export const deleteAccount = (user, deletionDate) => dispatch => {
    accountService.deleteAccount(user, deletionDate)
        .then(res => {
            dispatch({
                type: ACCOUNT_DELETE_SUCCESS,
                payload: res,
            });

            if (typeof(deletionDate) === 'undefined') {
                setTimeout(() => {
                    window.location.href = '/logout';
                }, 3000);
            } else {
                window.location.href = '/subscriptionSettings';
            }
        })
        .catch(e => {
            dispatch({
                type: ACCOUNT_DELETE_ERROR,
                payload: e,
            });
        })
};

export const reactivateAccount = (user) => dispatch => {
    accountService.reactivateAccount(user)
        .then(res => {
            dispatch({
                type: REACTIVATE_ACCOUNT_SUCCESS,
                payload: res,
            });
            setTimeout(() => {
                window.location.href = '/subscriptionSettings';
            }, 750);
        })
        .catch(e => {
            dispatch({
                type: REACTIVATE_ACCOUNT_ERROR,
                payload: e,
            });
        })
};

export const forgotPasswordLookup = (email) => dispatch => {
    accountService.forgotPasswordLookup(email)
        .then(res => {
            dispatch({
                type: FORGOT_PASSWORD_LOOKUP_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: FORGOT_PASSWORD_LOOKUP_ERROR,
                payload: e,
            });
        })
};

export const verifyResetCode = (resetCode) => dispatch => {
    accountService.verifyResetCode(resetCode)
        .then(res => {
            dispatch({
                type: FORGOT_PASSWORD_VERIFY_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: FORGOT_PASSWORD_VERIFY_ERROR,
                payload: e,
            });
        })
};

export const incrementUserLogins = (user) => dispatch => {
    accountService.incrementUserLogins(user)
        .then(res => {
            dispatch({
                type: INCREMENT_USER_LOGINS_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: INCREMENT_USER_LOGINS_ERROR,
                payload: e,
            });
        })
};

export const generateSecretAndQR = () => dispatch => (
    accountService.generateSecretAndQR()
        .then(res => {
            dispatch({
                type: GENERATE_SECRET_QR_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: GENERATE_SECRET_QR_ERROR,
                payload: e,
            });
        })
);

export const verifyAuthCode = (userId, code) => dispatch => (
    accountService.verifyAuthCode(userId, code)
        .then(res => {
            dispatch({
                type: VERIFY_AUTH_CODE_SUCCESS,
                payload: res,
            });
            if (res.login) {
                window.location.href = '/overview';
            }
        })
        .catch(e => {
            dispatch({
                type: VERIFY_AUTH_CODE_ERROR,
                payload: e,
            });
        })
);

export const disable2fa = (user) => dispatch => (
    accountService.disable2fa(user)
        .then(res => {
            dispatch({
                type: DISABLE_TWOFA_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: DISABLE_TWOFA_ERROR,
                payload: e,
            });
        })
);

export const impersonateUser = (user, id, password) => dispatch => (
    accountService.impersonateUser(user, id, password)
        .then(res => {
            dispatch({
                type: IMPERSONATE_USER_SUCCESS,
                payload: res,
            });
            window.location.href = '/dashboard';
        })
        .catch(e => {
            dispatch({
                type: IMPERSONATE_USER_ERROR,
                payload: e,
            });
        })
);

export const updateUserPreferences = (preferences) => dispatch => (
    accountService.updateUserPreferences(preferences)
        .then(res => {
            dispatch({
                type: USER_PREFERENCES_UPDATE_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: USER_PREFERENCES_UPDATE_ERROR,
                payload: e,
            });
        })
);

export const verifyAccount = (code) => dispatch => (
    accountService.verifyAccount(code)
        .then(res => {
            dispatch({
                type: VERIFY_ACCOUNT_SUCCESS,
                payload: res,
            });
        })
        .catch(e => {
            dispatch({
                type: VERIFY_ACCOUNT_ERROR,
                payload: e,
            });
        })
);
