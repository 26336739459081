export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_EDIT_ERROR = 'ACCOUNT_EDIT_ERROR';
export const ACCOUNT_EDIT_SUCCESS = 'ACCOUNT_EDIT_SUCCESS';
export const ACCOUNT_LOAD_ERROR = 'ACCOUNT_LOAD_ERROR';
export const ACCOUNT_LOAD_SUCCESS = 'ACCOUNT_LOAD_SUCCESS';
export const ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_TOPIC_ERROR = 'ADD_TOPIC_ERROR';
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS';
export const ASSETS_LIABILITIES_LOAD_ERROR = 'ASSETS_LIABILITIES_LOAD_ERROR';
export const ASSETS_LIABILITIES_LOAD_SUCCESS = 'ASSETS_LIABILITIES_LOAD_SUCCESS';
export const CASHFLOW_GOALS_LOAD_ERROR = 'CASHFLOW_GOALS_LOAD_ERROR';
export const CASHFLOW_GOALS_LOAD_SUCCESS = 'CASHFLOW_GOALS_LOAD_SUCCESS';
export const CASHFLOW_ACTUAL_LOAD_ERROR = 'CASHFLOW_ACTUAL_LOAD_ERROR';
export const CASHFLOW_ACTUAL_LOAD_SUCCESS = 'CASHFLOW_ACTUAL_LOAD_SUCCESS';
export const CASHFLOW_REVIEW_LOAD_ERROR = 'CASHFLOW_REVIEW_LOAD_ERROR';
export const CASHFLOW_REVIEW_LOAD_SUCCESS = 'CASHFLOW_REVIEW_LOAD_SUCCESS';
export const CHANGE_PLAN_TYPE_ERROR = 'CHANGE_PLAN_TYPE_ERROR';
export const CHANGE_PLAN_TYPE_SUCCESS = 'CHANGE_PLAN_TYPE_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_BUDGET_PLAN_ERROR = 'DELETE_BUDGET_PLAN_ERROR';
export const DELETE_BUDGET_PLAN_SUCCESS = 'DELETE_BUDGET_PLAN_SUCCESS';
export const DELETE_CASHFLOW_GOALS_ERROR = 'DELETE_CASHFLOW_RECORD_ERROR';
export const DELETE_CASHFLOW_GOALS_SUCCESS = 'DELETE_CASHFLOW_RECORD_SUCCESS';
export const DELETE_CASHFLOW_PLAN_ERROR = 'DELETE_CASHFLOW_PLAN_ERROR';
export const DELETE_CASHFLOW_PLAN_SUCCESS = 'DELETE_CASHFLOW_PLAN_SUCCESS';
export const DELETE_HISTORICAL_PROGRESS_ERROR = 'DELETE_HISTORICAL_PROGRESS_ERROR';
export const DELETE_HISTORICAL_PROGRESS_SUCCESS = 'DELETE_HISTORICAL_PROGRESS_SUCCESS';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DISABLE_TWOFA_ERROR = 'DISABLE_TWOFA_ERROR';
export const DISABLE_TWOFA_SUCCESS = 'DISABLE_TWOFA_SUCCESS';
export const DISCOUNT_CODE_ERROR = 'DISCOUNT_CODE_ERROR';
export const DISCOUNT_CODE_SUCCESS = 'DISCOUNT_CODE_SUCCESS';
export const DOWNLOAD_TRANSACTIONS_ERROR = 'DOWNLOAD_TRANSACTIONS_ERROR';
export const DOWNLOAD_TRANSACTIONS_SUCCESS = 'DOWNLOAD_TRANSACTIONS_SUCCESS';
export const EDIT_ACCOUNT_ERROR = 'EDIT_ACCOUNT_ERROR';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const EDIT_BUDGET_PLAN_ERROR = 'EDIT_BUDGET_PLAN_ERROR';
export const EDIT_BUDGET_PLAN_SUCCESS = 'EDIT_BUDGET_PLAN_SUCCESS';
export const EDIT_BUDGET_PLAN_MONTH_ERROR = 'EDIT_BUDGET_PLAN_MONTH_ERROR';
export const EDIT_BUDGET_PLAN_MONTH_SUCCESS = 'EDIT_BUDGET_PLAN_MONTH_SUCCESS';
export const EDIT_TRANSACTIONS_ERROR = 'EDIT_TRANSACTIONS_ERROR';
export const EDIT_TRANSACTIONS_SUCCESS = 'EDIT_TRANSACTIONS_SUCCESS';
export const FINANCIAL_FACT_LOAD_ERROR = 'FINANCIAL_FACT_LOAD_ERROR';
export const FINANCIAL_FACT_LOAD_SUCCESS = 'FINANCIAL_FACT_LOAD_SUCCESS';
export const FINANCIAL_PRIORITIES_LOAD_ERROR = 'FINANCIAL_PRIORITIES_LOAD_ERROR';
export const FINANCIAL_PRIORITIES_LOAD_SUCCESS = 'FINANCIAL_PRIORITIES_LOAD_SUCCESS';
export const FORGOT_PASSWORD_LOOKUP_ERROR = 'FORGOT_PASSWORD_LOOKUP_ERROR';
export const FORGOT_PASSWORD_LOOKUP_SUCCESS = 'FORGOT_PASSWORD_LOOKUP_SUCCESS';
export const FORGOT_PASSWORD_VERIFY_ERROR = 'FORGOT_PASSWORD_VERIFY_ERROR';
export const FORGOT_PASSWORD_VERIFY_SUCCESS = 'FORGOT_PASSWORD_VERIFY_SUCCESS';
export const GENERATE_SECRET_QR_ERROR = 'GENERATE_SECRET_QR_ERROR';
export const GENERATE_SECRET_QR_SUCCESS = 'GENERATE_SECRET_QR_SUCCESS';
export const GET_ACCESS_TOKEN_ERROR = 'GET_ACCESS_TOKEN_ERROR';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const GET_LINK_TOKEN_ERROR = 'GET_LINK_TOKEN_ERROR';
export const GET_LINK_TOKEN_SUCCESS = 'GET_LINK_TOKEN_SUCCESS';
export const HISTORICAL_LOAD_ERROR = 'HISTORICAL_LOAD_ERROR';
export const HISTORICAL_LOAD_SUCCESS = 'HISTORICAL_LOAD_SUCCESS';
export const IMPERSONATE_USER_ERROR = 'IMPERSONATE_USER_ERROR';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const INCREMENT_USER_LOGINS_ERROR = 'INCREMENT_USER_LOGINS_ERROR';
export const INCREMENT_USER_LOGINS_SUCCESS = 'INCREMENT_USER_LOGINS_SUCCESS';
export const LOAD_BUDGET_PLAN_ERROR = 'LOAD_BUDGET_PLAN_ERROR';
export const LOAD_BUDGET_PLAN_SUCCESS = 'LOAD_BUDGET_PLAN_SUCCESS';
export const LOAD_BUDGET_PLAN_MONTH_ERROR = 'LOAD_BUDGET_PLAN_MONTH_ERROR';
export const LOAD_BUDGET_PLAN_MONTH_SUCCESS = 'LOAD_BUDGET_PLAN_MONTH_SUCCESS';
export const LOAD_BUDGET_PLAN_REVIEW_ERROR = 'LOAD_BUDGET_PLAN_REVIEW_ERROR';
export const LOAD_BUDGET_PLAN_REVIEW_SUCCESS = 'LOAD_BUDGET_PLAN_REVIEW_SUCCESS';
export const LOAD_TASK_ERROR = 'LOAD_TASK_ERROR';
export const LOAD_TASK_SUCCESS = 'LOAD_TASK_SUCCESS';
export const LOAD_TASK_FROM_LINK_ERROR = 'LOAD_TASK_FROM_LINK_ERROR';
export const LOAD_TASK_FROM_LINK_SUCCESS = 'LOAD_TASK_FROM_LINK_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PASSWORD_EDIT_ERROR = 'PASSWORD_EDIT_ERROR';
export const PASSWORD_EDIT_SUCCESS = 'PASSWORD_EDIT_SUCCESS';
export const PAYMENT_SUBMIT_ERROR = 'PAYMENT_SUBMIT_ERROR';
export const PAYMENT_SUBMIT_SUCCESS = 'PAYMENT_SUBMIT_SUCCESS';
export const REACTIVATE_ACCOUNT_ERROR = 'REACTIVATE_ACCOUNT_ERROR';
export const REACTIVATE_ACCOUNT_SUCCESS = 'REACTIVATE_ACCOUNT_SUCCESS';
export const REMOVE_RETIREMENT_PLANNER_INCOME_ERROR = 'REMOVE_RETIREMENT_PLANNER_INCOME_ERROR';
export const REMOVE_RETIREMENT_PLANNER_INCOME_SUCCESS = 'REMOVE_RETIREMENT_PLANNER_INCOME_SUCCESS';
export const RETIREMENT_PLANNER_LOAD_ERROR = 'RETIREMENT_PLANNER_LOAD_ERROR';
export const RETIREMENT_PLANNER_LOAD_SUCCESS = 'RETIREMENT_PLANNER_LOAD_SUCCESS';
export const RETIREMENT_PLANNER_GOALS_LOAD_ERROR = 'RETIREMENT_PLANNER_GOALS_LOAD_ERROR';
export const RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS = 'RETIREMENT_PLANNER_GOALS_LOAD_SUCCESS';
export const RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR = 'RETIREMENT_PLANNER_GOALS_ALL_LOAD_ERROR';
export const RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS = 'RETIREMENT_PLANNER_GOALS_ALL_LOAD_SUCCESS';
export const RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR = 'RETIREMENT_PLANNER_GOAL_YEARS_LOAD_ERROR';
export const RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS = 'RETIREMENT_PLANNER_GOAL_YEARS_LOAD_SUCCESS';
export const RETIREMENT_PLANNER_INCOME_LOAD_ERROR = 'RETIREMENT_PLANNER_INCOME_LOAD_ERROR';
export const RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS = 'RETIREMENT_PLANNER_INCOME_LOAD_SUCCESS';
export const SAVE_BUDGET_PLAN_ERROR = 'SAVE_BUDGET_PLAN_ERROR';
export const SAVE_BUDGET_PLAN_SUCCESS = 'SAVE_BUDGET_PLAN_SUCCESS';
export const SAVE_CASHFLOW_GOALS_ERROR = 'SAVE_CASHFLOW_GOALS_ERROR';
export const SAVE_CASHFLOW_GOALS_SUCCESS = 'SAVE_CASHFLOW_GOALS_SUCCESS';
export const SAVE_HISTORICAL_RECORD_ERROR = 'SAVE_HISTORICAL_RECORD_ERROR';
export const SAVE_HISTORICAL_RECORD_SUCCESS = 'SAVE_HISTORICAL_RECORD_SUCCESS';
export const SAVE_RETIREMENT_PLANNER_ERROR = 'SAVE_RETIREMENT_PLANNER_ERROR';
export const SAVE_RETIREMENT_PLANNER_SUCCESS = 'SAVE_RETIREMENT_PLANNER_SUCCESS';
export const SAVE_RETIREMENT_PLANNER_GOALS_ERROR = 'SAVE_RETIREMENT_PLANNER_GOALS_ERROR';
export const SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS = 'SAVE_RETIREMENT_PLANNER_GOALS_SUCCESS';
export const SAVE_RETIREMENT_PLANNER_INCOME_ERROR = 'SAVE_RETIREMENT_PLANNER_INCOME_ERROR';
export const SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS = 'SAVE_RETIREMENT_PLANNER_INCOME_SUCCESS';
export const SAVE_SURVEY_ERROR = 'SAVE_SURVEY_ERROR';
export const SEND_ERROR_EMAIL_ERROR = 'SEND_ERROR_EMAIL_ERROR';
export const SEND_ERROR_EMAIL_SUCCESS = 'SEND_ERROR_EMAIL_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SYNC_ACCOUNTS_ERROR = 'SYNC_ACCOUNTS_ERROR';
export const SYNC_ACCOUNTS_SUCCESS = 'SYNC_ACCOUNTS_SUCCESS';
export const TOGGLE_TASK_ERROR = 'TOGGLE_TASK_ERROR';
export const TOGGLE_TASK_SUCCESS = 'TOGGLE_TASK_SUCCESS';
export const UPDATE_PLAN_MONTH_CATEGORY_ERROR = 'UPDATE_PLAN_MONTH_CATEGORY_ERROR';
export const UPDATE_PLAN_MONTH_CATEGORY_SUCCESS = 'UPDATE_PLAN_MONTH_CATEGORY_SUCCESS';
export const USER_PREFERENCES_UPDATE_ERROR = 'USER_PREFERENCES_UPDATE_ERROR';
export const USER_PREFERENCES_UPDATE_SUCCESS = 'USER_PREFERENCES_UPDATE_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_AUTH_CODE_ERROR = 'VERIFY_AUTH_CODE_ERROR';
export const VERIFY_AUTH_CODE_SUCCESS = 'VERIFY_AUTH_CODE_SUCCESS';
