import React, { Component } from 'react';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import './LoggedOut.scss';

class LoggedOut extends Component {
    render() {
        const search = window.location.search;

        return (
            <div className="wrapper">
                <NavBar />

                <div className="main logged-out">
                    <h1>Logged out</h1>
                    {search === '?timeout=1' && <p>You have been logged out due to inactivity.</p>}
                    <a href="/login">Log in again</a>
                </div>

                <Footer />
            </div>
        );
    }
}

export default LoggedOut;
