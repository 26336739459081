import React, { Component } from 'react';

class ArrowIcon extends Component {
    render() {
        const { stroke, onClick } = this.props;

        return (
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon" onClick={onClick}>
                <circle cx="13.3281" cy="13.1997" r="11.75" stroke={stroke} strokeWidth="1.5"/>
                <path d="M6.18555 12.9375L20.4713 12.9375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.7568 8.19971L20.4711 12.9616L14.7568 18.1997" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
}

ArrowIcon.defaultProps = {
    stroke: '#CFCFCF',
    onClick: () => { return null; }
};

export default ArrowIcon;
