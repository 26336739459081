/**
 * Plaid Service
 */

import http from '../utils/http';

class plaidService {
    static async getLinkToken(user, accessToken) {
        return http.post(`/api/plaid/link`, { user: user, accessToken: accessToken }).then(res => res.body);
    }

    static async getAccessTokenAndCreateAccounts(user, metadata) {
        return http.post(`/api/plaid/access`, { user: user, metadata: metadata }).then(res => res.body);
    }

    static async syncLinkedAccounts(user) {
        return http.post(`/api/plaid/sync`, { user: user }).then(res => res.body);
    }

    static async downloadTransactions(user, plan, accounts) {
        return http.post(`/api/plaid/transactions`, { user: user, plan: plan, accounts: accounts }).then(res => res.body);
    }
}

export default plaidService;
