/*global LOU */

import {
    ACCOUNT_DELETE_ERROR,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_EDIT_ERROR,
    ACCOUNT_EDIT_SUCCESS,
    ACCOUNT_LOAD_ERROR,
    ACCOUNT_LOAD_SUCCESS,
    DISABLE_TWOFA_ERROR,
    DISABLE_TWOFA_SUCCESS,
    FORGOT_PASSWORD_LOOKUP_ERROR,
    FORGOT_PASSWORD_LOOKUP_SUCCESS,
    FORGOT_PASSWORD_VERIFY_ERROR,
    FORGOT_PASSWORD_VERIFY_SUCCESS,
    GENERATE_SECRET_QR_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    PASSWORD_EDIT_ERROR,
    PASSWORD_EDIT_SUCCESS,
    REACTIVATE_ACCOUNT_ERROR,
    REACTIVATE_ACCOUNT_SUCCESS,
    SIGNUP_ERROR,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_SUCCESS,
    VERIFY_AUTH_CODE_ERROR,
    VERIFY_AUTH_CODE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
    user: null,
    edit: {},
    loaded: false,
    signup: {},
    login: {},
    forgotPassword: {},
    qrcode: null,
    verification: {},
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_DELETE_SUCCESS:
        case REACTIVATE_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                edit: {success: action.payload, error: null},
            });
        case ACCOUNT_DELETE_ERROR:
        case ACCOUNT_EDIT_ERROR:
        case REACTIVATE_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                edit: {success: false, error: action.payload},
            });
        case ACCOUNT_EDIT_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.user,
                edit: {success: action.payload, error: null},
            });
        case ACCOUNT_LOAD_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.user,
                error: {},
                loaded: true,
            });
        case ACCOUNT_LOAD_ERROR:
            return Object.assign({}, state, {
                user: null,
                error: action.payload,
                loaded: false,
            });
        case LOGIN_ERROR:
            return Object.assign({}, state, {
                login: action.payload,
            });
        case LOGIN_SUCCESS:
            const user = action.payload.user;
            if (user) {
                LOU.identify(user.id, { email: user.email, timezone: user.timezone, twofa: user.totp_enabled });
            }
            return Object.assign({}, state, {
                login: action.payload,
            });
        case PASSWORD_EDIT_ERROR:
            return Object.assign({}, state, {
                edit: {success: false, error: action.payload},
                forgotPassword: {success: false, error: action.payload},
            });
        case PASSWORD_EDIT_SUCCESS:
            return Object.assign({}, state, {
                edit: {success: action.payload, error: null},
                forgotPassword: {success: action.payload, error: null},
            });
        case SIGNUP_ERROR:
            return Object.assign({}, state, {
                signup: action.payload,
            });
        case FORGOT_PASSWORD_LOOKUP_ERROR:
        case FORGOT_PASSWORD_VERIFY_ERROR:
            return Object.assign({}, state, {
                forgotPassword: {success: false, error: action.payload},
            });
        case FORGOT_PASSWORD_LOOKUP_SUCCESS:
        case FORGOT_PASSWORD_VERIFY_SUCCESS:
            return Object.assign({}, state, {
                forgotPassword: {success: action.payload, error: null},
            });
        case GENERATE_SECRET_QR_SUCCESS:
            return Object.assign({}, state, {
                qrcode: action.payload.qrcode,
            });
        case VERIFY_AUTH_CODE_ERROR:
        case DISABLE_TWOFA_ERROR:
            return Object.assign({}, state, {
                edit: {success: false, error: action.payload},
                login: Object.assign({},
                    action.payload,
                    { totp_enabled: action.payload.body.totp_enabled }
                ),
            });
        case VERIFY_AUTH_CODE_SUCCESS:
        case DISABLE_TWOFA_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload.user,
                edit: {success: action.payload, error: null},
                login: {success: true, error: null, totp_enabled: true},
            });
        case VERIFY_ACCOUNT_ERROR:
            return Object.assign({}, state, {
                verification: {success: false, error: action.payload},
            });
        case VERIFY_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                verification: {success: action.payload, error: null},
            });
        default:
            return state;
    }
};

export default accountReducer;
