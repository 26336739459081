import React, { Component } from 'react';
import NavBar from 'components/NavBar';
import ComingSoonDialog from 'components/ComingSoonDialog';
import Footer from 'components/Footer';
import './CreateAccount.scss';

class CreateAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comingSoonDialogOpen: false,
        };
    }

    gotoSignup = () => {
        window.location.href = '/signup';
    }

    toggleComingSoonDialog = () => {
        this.setState({ comingSoonDialogOpen: !this.state.comingSoonDialogOpen });
    }

    render() {
        return (
            <div className="wrapper">
                <NavBar />

                <div className="main create-account">
                    <h1>What type of account would you like?</h1>

                    <div className="account-plans">
                        <div className="plan">
                            <button className="plan-button" onClick={this.gotoSignup}>
                                Monthly
                            </button>
                            <p>Charged once monthly after your free trial ends. No commitment.</p>
                        </div>

                        <div className="plan">
                            <button className="plan-button" onClick={this.toggleComingSoonDialog}>
                                Annual
                            </button>
                            <p>Get the equivalent of two months free compared to monthly plan.
                            Charged once annually after your free trial ends. No commitment.</p>
                        </div>
                    </div>
                </div>

                <ComingSoonDialog open={this.state.comingSoonDialogOpen} toggle={this.toggleComingSoonDialog} />

                <Footer />
            </div>
        );
    }
}

export default CreateAccount;
