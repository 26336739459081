import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from 'views/ErrorFallback';
import createRootReducer from './reducers/rootReducer';
import App from './views/App';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createBrowserHistory();
const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
        )
    )
);;

root.render(
    <Provider store={store}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App history={history} />
        </ErrorBoundary>
    </Provider>
);
