/**
 * Budget Plan Service
 */

import http from '../utils/http';

class budgetPlanService {
    static async loadBudgetPlan(user, createPlan) {
        return http.get(`/api/budgetPlan?userId=${user.id}&createPlan=${createPlan}`).then(res => res.body);
    }

    static async saveBudgetPlan(user, plan) {
        return http.post(`/api/budgetPlan`, {user: user, plan: plan}).then(res => res.body);
    }

    static async editBudgetPlan(user, rowId, type) {
        return http.put(`/api/budgetPlan`, {user: user, rowId: rowId, type: type}).then(res => res.body);
    }

    static async deleteBudgetPlan(user, plan) {
        return http.delete(`/api/budgetPlan?userId=${user.id}&planId=${plan.planId}`).then(res => res.body);
    }

    static async loadBudgetPlanMonth(user, planId, month) {
        return http.get(`/api/budgetPlanMonth?userId=${user.id}&planId=${planId}&month=${month}`).then(res => res.body);
    }

    static async updatePlanMonthCategory(user, category) {
        return http.put(`/api/budgetPlanMonthCategory`, {user: user, category: category}).then(res => res.body);
    }

    static async editTransactions(user, transactions, plan) {
        return http.put(`/api/transaction`, {user: user, transactions: transactions, plan: plan}).then(res => res.body);
    }

    static async deleteTransaction(user, transactionId) {
        return http.delete(`/api/transaction?userId=${user.id}&transactionId=${transactionId}`).then(res => res.body);
    }

    static async editBudgetPlanMonth(user, plan) {
        return http.put(`/api/budgetPlanMonth`, {user: user, plan: plan}).then(res => res.body);
    }

    static async loadBudgetPlanReview(user, planId) {
        return http.get(`/api/budgetPlanReview?userId=${user.id}&planId=${planId}`).then(res => res.body);
    }
}

export default budgetPlanService;
