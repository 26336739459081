import React from 'react';
import './Loading.scss';

const Loading = () => {
    const size = 26;

    const parentStyle = {
        width: size,
        height: Math.round(size * 0.75),
    };

    const childStyle = {
        width: Math.round((0.7 * size) / 3),
    };

    const nth2Margin = Math.floor((size - childStyle.width * 3) / 2);

    return (
        <div className="loading" style={{...parentStyle}}>
            <div style={childStyle} />
            <div style={{ ...childStyle, margin: `0 ${nth2Margin}px`}} />
            <div style={childStyle} />
        </div>
    );
};

export default Loading;
